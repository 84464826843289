import React from 'react'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import TableHeadMaterialUi from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'
import strings from '../../../helpers/I18NStrings/index'

class TableHead extends React.Component {
  render() {
    const { order, orderBy, rows } = this.props

    return (
      <TableHeadMaterialUi>
        <TableRow>
          {rows.map(row => {
            return (
              <TableCell
                key={row.id}
                style={{ fontSize: 20 }}
                numeric={row.numeric}
                sortDirection={orderBy === row.id ? order : false}
              >
                {row.sort ? (
                  <Tooltip
                    title={strings.TextSort}
                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={() => {
                        if (row.sort) {
                          if (row.id === orderBy) {
                            const newOrder = order === 'asc' ? 'desc' : 'asc'

                            this.props.sortOn(row.id, newOrder, row.sortType)
                          } else {
                            this.props.sortOn(row.id, order, row.sortType)
                          }
                        }
                      }}
                    >
                      {row.label}
                    </TableSortLabel>
                  </Tooltip>
                ) : (
                  row.label
                )}
              </TableCell>
            )
          }, this)}
        </TableRow>
      </TableHeadMaterialUi>
    )
  }
}

TableHead.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
}

export default TableHead
