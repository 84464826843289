import * as Types from './Types'
import * as API from './api'

export function setPage(page) {
  return dispatch => {
    dispatch({
      type: Types.SET_PAGE,
      payload: page
    })
  }
}

export function showBlurAlert(onSuccess) {
  return dispatch => {
    dispatch({
      type: Types.SHOW_BLUR_ALERT,
      payload: () => onSuccess()
    })
  }
}

export function hideBlurAlert() {
  return dispatch => {
    dispatch({
      type: Types.HIDE_BLUR_ALERT
    })
  }
}

export function showAlert(
  header,
  text,
  type,
  firstButton = { title: '', onPress: () => {} },
  secondButton = { title: '', onPress: () => {} },
  thirdButton = { title: '', onPress: () => {} }
) {
  return dispatch => {
    dispatch({
      type: Types.SHOW_ALERT,
      payload: {
        header,
        text,
        type,
        firstButton,
        secondButton,
        thirdButton
      }
    })
  }
}

export function hideAlert() {
  return dispatch => {
    dispatch({
      type: Types.HIDE_ALERT
    })
  }
}

export function tryGetTermsOfUse() {
  return dispatch => {
    dispatch({
      type: Types.GET_TERMS_OF_USE
    })

    API.getTermsOfUse()
      .then(response => {
        dispatch({
          type: Types.GET_TERMS_OF_USE_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: Types.GET_TERMS_OF_USE_FAILED
        })
      })
  }
}
