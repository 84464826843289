import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import product from '../../../helpers/Constants/product'
import strings from '../../../helpers/I18NStrings/index'

class ScrollDialog extends React.Component {
  render() {
    const { show, onClose, classes, header, children } = this.props

    return (
      <Dialog
        open={show}
        onClose={() => onClose()}
        scroll={'paper'}
        aria-labelledby={'scroll-dialog-title'}
      >
        <DialogTitle id={'scroll-dialog-title'}>{header}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => onClose()}
            classes={{ label: classes.successButtonText }}
          >
            {strings.ButtonOK}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const Styles = {
  successButtonText: {
    color: product.mainColor
  }
}

export default withStyles(Styles)(ScrollDialog)
