import Axios from 'axios'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8080/api/v1/'
    : 'https://portal.medotemic.com/api/v1/'

export function getTraining(token, userId) {
  const url = BASE_URL + 'patient/' + userId + '/trainings'

  return Axios.get(url, getConfig(token))
}

export function getGoals(token, userId) {
  const url = BASE_URL + 'patient/' + userId + '/goals'

  return Axios.get(url, getConfig(token))
}

function getConfig(token) {
  return { headers: { authentication: 'Bearer ' + token } }
}
