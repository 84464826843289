import * as Types from '../../actions/Types'

const INITIAL_STATE = {
  isUpdating: false,
  errorUpdatingStatus: null
}

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action

  switch (type) {
  case Types.UPDATE_SELF_CLINIC:
    return {
      ...state,
      isUpdating: true,
      errorUpdatingStatus: null
    }
  case Types.UPDATE_SELF_CLINIC_SUCCESS:
    return {
      ...state,
      isUpdating: false,
      errorUpdatingStatus: null
    }
  case Types.UPDATE_SELF_CLINIC_ERROR:
    return {
      ...state,
      isUpdating: false,
      errorUpdatingStatus: payload
    }
  case Types.SIGN_OUT_SUCCESS:
    return {
      ...state,
      ...INITIAL_STATE
    }
  default:
    return state
  }
}
