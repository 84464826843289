import React, { Component } from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import reducers from "../reducers";
import Highcharts from "highcharts";

import Router from "./Router";
import strings from "../helpers/I18NStrings/index";

const loggerMiddleware = createLogger();

class App extends Component {
  componentDidMount() {
    Highcharts.setOptions({
      credits: {
        enabled: false
      },
      lang: {
        shortMonths: [
          strings.TextJan,
          strings.TextFeb,
          strings.TextMar,
          strings.TextApr,
          strings.TextMay,
          strings.TextJun,
          strings.TextJul,
          strings.TextAug,
          strings.TextSep,
          strings.TextOct,
          strings.TextNov,
          strings.TextDec
        ],
        weekdays: [
          strings.TextMon,
          strings.TextTue,
          strings.TextWed,
          strings.TextThu,
          strings.TextFri,
          strings.TextSat,
          strings.TextSun
        ]
      }
    });
  }

  render() {
    const theme = createMuiTheme({
      primary: {
        light: "#53b183",
        main: "#289e64",
        dark: "#1c6e46",
        contrastText: "#fff"
      },
      secondary: {
        light: "#53b183",
        main: "#289e64",
        dark: "#1c6e46",
        contrastText: "#fff"
      },
      fontFamily: "Roboto",
      fontSize: 20,
      cssLabel: {
        "&$cssFocused": {
          color: "green"
        }
      },
      overrides: {
        MuiInput: {
          underline: {
            "&:after": {
              borderBottom: "2px solid #289e64"
            }
          }
        }
      }
    });

    return (
      <Provider
        store={createStore(
          reducers,
          applyMiddleware(thunkMiddleware, loggerMiddleware)
        )}
      >
        <MuiThemeProvider theme={theme}>
          <Router />
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default App;
