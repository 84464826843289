import * as Constants from '../Constants'
import strings from '../I18NStrings/index'

export function parsePatientType(type) {
  switch (type) {
    case 'arthritisPatient':
      return strings.TextOsteoarthritisPatient
    case 'arthritisSchool':
      return strings.TextOsteoarthritisSchool
    case 'strokePatient':
      return strings.TextStrokePatient
    case 'implantSurgery':
      return strings.TextImplantPatients
    case 'parcelDamaged':
      return strings.TextACLInjury
    case 'footFractures':
      return strings.TextFootFractures
    case 'secondaryPrevention':
      return strings.TextSecondaryPrevention
    case 'backProblems':
      return strings.TextBackpain
    case 'neurologicalDamage':
      return strings.TextNeurologicalDamage
    case 'prosthesis':
      return strings.TextProsthesis
    case 'cerebralPares':
      return strings.TextCerebralPalsy
    case 'pelvic':
      return strings.TextSPD
    case 'osteoporos':
      return strings.TextOsteoporosis
    case 'other':
      return strings.TextOtherType
    default:
      return strings.TextUnknownType
  }
}

export function getUTC(date) {
  const dateArray = date.split('-')

  return Date.UTC(dateArray[0], dateArray[1] - 1, dateArray[2])
}

export function getTimeString(seconds) {
  const min = Math.floor(seconds / 60)
  const sec = seconds - min * 60

  return min + ' min ' + sec + ' ' + strings.TextSeconds
}

export function parsePeriodToInt(period) {
  switch (period) {
    case Constants.DAY:
      return 1
    case Constants.WEEK:
      return 7
    case Constants.MONTH:
      return 30
    default:
      return 1
  }
}

export function parseLimpIndexGoal(limpIndexGoal) {
  if (limpIndexGoal === null) {
    return []
  }

  return [
    [getUTC(limpIndexGoal.startDate), limpIndexGoal.startIndex],
    [getUTC(limpIndexGoal.endDate), limpIndexGoal.endIndex]
  ]
}

export function parseMinutesGoal(minGoal, activeTime) {
  if (minGoal === null || activeTime.length === 0) {
    return []
  }

  const minuteGoalMean = parseInt(
    minGoal.minutes / parsePeriodToInt(minGoal.period),
    10
  )

  return [
    [activeTime[0][0], minuteGoalMean],
    [activeTime[activeTime.length - 1][0], minuteGoalMean]
  ]
}

export function parseNumberOfTrainingsGoal(numberOfTrainingGoal, numberOfTrainings) {
  if (numberOfTrainingGoal === null || numberOfTrainings.length === 0) {
    return []
  }

  return [
    [numberOfTrainings[0][0], numberOfTrainingGoal.numberOfTrainings],
    [numberOfTrainings[numberOfTrainings.length - 1][0], numberOfTrainingGoal.numberOfTrainings]
  ]
}

export function stringToDecimal(str) {
  const number = []

  for (let i = 0; i < str.length; i++)
    number.push(str.charCodeAt(i).toString(10))

  return number
}

export function parsePerMinute(limpArray, limit = 100) {
  let arrayWithAllValues = []
  for (let y = 0; y < limpArray.length; y++) {
    const intVal = parseInt(limpArray[y])
    if (intVal <= limit) {
      arrayWithAllValues.push(intVal)
    } else {
      for (
        let noWalkingFrames = 0;
        noWalkingFrames < intVal - limit;
        noWalkingFrames++
      ) {
        arrayWithAllValues.push(limit + 1)
      }
    }
  }

  const limpPerMin = []
  let minute = 1
  let currentLimp = 0
  let numbersOfValues = 0
  let s = ''
  for (let i = 0; i < arrayWithAllValues.length; i++) {
    const intVal = parseInt(arrayWithAllValues[i])

    if (intVal <= limit) {
      numbersOfValues++
      currentLimp += intVal
    }
    if ((i + 1) % 12 === 0) {
      if (numbersOfValues !== 0) {
        const mean = Math.round(currentLimp / numbersOfValues)

        limpPerMin.push([minute, mean])
      }

      minute++
      currentLimp = 0
      numbersOfValues = 0
    }
  }

  return smoothCurve(limpPerMin)
}

export function getActiveGoals(goals) {
  const minutesWalkGoal = goals.minutesWalkGoals ? goals.minutesWalkGoals.filter(g => !g.deletedAt) : []
  const limpIndexGoal = goals.limpIndexGoals ? goals.limpIndexGoals.filter(g => !g.deletedAt) : []
  const trainingsGoal = goals.numberOfTrainingGoal ? goals.numberOfTrainingGoal.filter(g => !g.deletedAt) : []

  console.log("trainingsGoal", trainingsGoal)

  return {
    minutesWalkGoal: minutesWalkGoal.length === 0 ? null : minutesWalkGoal[0],
    limpIndexGoal: limpIndexGoal.length === 0 ? null : limpIndexGoal[0],
    trainingsGoal: trainingsGoal.length === 0 ? null : trainingsGoal[0]
  }
}

export function validateLimpGoal(startIndex, endIndex, startDate, endDate) {
  if (
    startIndex > 100 ||
    startIndex < 0 ||
    endIndex > 100 ||
    endIndex < 0 ||
    endIndex > startIndex
  ) {
    return Constants.LIMP_INDEX_OUT_OF_BOUNCE
  } else if (new Date(endDate).getTime() - new Date(startDate).getTime() < 0) {
    return Constants.LIMP_INDEX_DATE_OUT_OF_BOUNCE
  }

  return Constants.GOAL_OK
}

export function getTimestamp() {
  return new Date()
    .toISOString()
    .slice(0, 19)
    .replace('T', ' ')
}

export function smoothCurve(limpPerMin) {
  const smooth = []

  limpPerMin.forEach((l, index) => {
    let numberOfValues = 2
    let newValue = 2 * l[1]

    if (index > 0) {
      newValue += limpPerMin[index - 1][1]
      numberOfValues++
    }

    if (index > 1) {
      newValue += limpPerMin[index - 2][1]
      numberOfValues++
    }

    if (index < limpPerMin.length - 1) {
      newValue += limpPerMin[index + 1][1]
      numberOfValues++
    }

    if (index < limpPerMin.length - 2) {
      newValue += limpPerMin[index + 2][1]
      numberOfValues++
    }

    smooth.push([l[0], Math.round(newValue / numberOfValues)])
  })

  return smooth
}

export function getGraphDataPerDayFromTrainings(trainings) {
  const data = {}
  const parsedData = {
    results: [],
    steps: [],
    kadens: [],
  }

  trainings.forEach(t => {
    const date = getUTC(t.time.split(' ')[0])

    if (data[date]) {
      if (t.walkingOn !== 'terrain' && t.walkingOn !== 'other') {
        if (!data[date].result) data[date].result = t.result
        else data[date].result = (data[date].result + t.result) / 2
      }
      data[date].steps = data[date].steps + t.steps
      data[date].kadens = (data[date].kadens + t.kadens) / 2
    } else {
      data[date] = {}
      if (t.walkingOn !== 'terrain' && t.walkingOn !== 'other')
        data[date].result = t.result
      
      data[date].steps = t.steps
      data[date].kadens = t.kadens
    }
  })

  Object.keys(data).forEach(key => {
    if (isFinite(data[key].result))
      parsedData.results.push([parseInt(key, 10), parseInt(data[key].result, 10)])
    parsedData.steps.push([parseInt(key, 10), parseInt(data[key].steps, 10)])
    parsedData.kadens.push([parseInt(key, 10), parseInt(data[key].kadens, 10)])
  })

  return parsedData
}

export function getGraphDataPerWeekFromTrainings(trainings) {
  const data = {}
  const parsedData = {
    activeTime: [],
    numberOfTrainings: [],
  }

  trainings.forEach(t => {
    const date = getUTC(getMonday(t.time.split(' ')[0]))

    if (data[date]) {
      data[date].activeTime = data[date].activeTime + t.activeTime
      data[date].numberOfTrainings = data[date].numberOfTrainings + 1
    } else {
      data[date] = {}
      data[date].activeTime = t.activeTime
      data[date].numberOfTrainings = 1
    }
  })

  Object.keys(data).forEach(key => {
    parsedData.activeTime.push([
      parseInt(key, 10),
      Math.round(data[key].activeTime / 60)
    ])
    parsedData.numberOfTrainings.push([
      parseInt(key, 10),
      data[key].numberOfTrainings
    ])
  })

  return parsedData
}

export function getDate(dateObject) {
  const date = dateObject.getDate().toString()
  const month = (1 + dateObject.getMonth()).toString()
  const year = dateObject.getFullYear().toString()

  const dateString = date.length === 1 ? '0' + date : date
  const monthString = month.length === 1 ? '0' + month : month

  return year + '-' + monthString + '-' + dateString
}

export function checkUsername(username, props) {
  if (!username && username === "") {
    props.showAlert(
      strings.InfoMissingHeader,
      strings.InfoMissing,
      Constants.ONE_BUTTON,
      { title: strings.ButtonOK, onPress: () => { } }
    )
    return false
  } else if (!username.match(Constants.REGEX_USERNAME_IS_CORRECT)) {
    props.showAlert(
      strings.UsernameContainSpecialCharHeader,
      strings.UsernameContainSpecialChar,
      Constants.ONE_BUTTON,
      { title: strings.ButtonOK, onPress: () => { } }
    )
    return false
  } else if (username.length < 6 || username.length > 20) {
    props.showAlert(
      strings.UsernameLengthRangeHeader,
      strings.UsernameLengthRange,
      Constants.ONE_BUTTON,
      { title: strings.ButtonOK, onPress: () => { } }
    )
    return false
  }

  return true
}

export function checkPassword(password, repeatPassword, props) {
  if (!password && !repeatPassword) {
    props.showAlert(
      strings.InfoMissingHeader,
      strings.InfoMissing,
      Constants.ONE_BUTTON,
      { title: strings.ButtonOK, onPress: () => { } }
    )
    return false
  } else if (password !== repeatPassword) {
    props.showAlert(
      strings.PasswordsNoMatchHeader,
      strings.PasswordsNoMatch,
      Constants.ONE_BUTTON,
      { title: strings.ButtonOK, onPress: () => { } }
    )
    return false
  }

  return true
}

export function convertDateToWeekNumber(date) {
  var day = date.getUTCDay() == 0 ? 7 : date.getUTCDay(),
    dayNumber;
  date.setDate(date.getUTCDate() + 4 - day);
  dayNumber = Math.floor((date.getTime() - new Date(date.getUTCFullYear(), 0, 1, -6)) / 86400000);
  return 1 + Math.floor(dayNumber / 7);
}

export function getMonday(date) {
  const d = new Date(date);
  var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
  const monday = new Date(d.setDate(diff))
  return getDate(monday);
}