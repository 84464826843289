import React, { Component } from 'react'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'
import product from '../../../helpers/Constants/product'

class RadioButton extends Component {
  render() {
    const { label, checked, classes, style, onChange } = this.props

    return (
      <FormControlLabel
        control={
          <Radio
            checked={checked}
            onChange={() => onChange()}
            classes={{ root: classes.root, checked: classes.checked }}
          />
        }
        label={label}
        style={style}
        classes={{ label: classes.label }}
      />
    )
  }
}

const Styles = {
  root: {
    color: '#2a2f40',
    '&$checked': {
      color: product.mainColor
    }
  },
  checked: {},
  label: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 20
  }
}

export default withStyles(Styles)(RadioButton)
