import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import CircularProgress from '@material-ui/core/CircularProgress'

import TableHead from '../../../common/TableHead'

import * as clinicsActions from '../../../../actions/medotemic/Clinics'
import * as configActions from '../../../../actions/Config'
import * as Styles from './styles'
import product from '../../../../helpers/Constants/product'
import Inputs from '../../../common/Inputs'
import strings from '../../../../helpers/I18NStrings/index'

const ROWS = [
  {
    id: 'id',
    label: strings.TextClinicId,
    numeric: false,
    sortType: 'string',
    sort: true
  },
  {
    id: 'name',
    label: strings.TextClinicName,
    numeric: false,
    sortType: 'string',
    sort: true
  },
  {
    id: 'maxPatients',
    label: strings.TextMaxPatients,
    numeric: false,
    sortType: 'string',
    sort: true
  },
  {
    id: 'price',
    label: strings.TextPrice,
    numeric: false,
    sortType: 'string',
    sort: true
  },
  {
    id: 'expires',
    label: strings.TextValidity,
    numeric: false,
    sortType: 'string',
    sort: true
  },
  {
    id: 'orgNumber',
    label: strings.TextCompanyNumber,
    numeric: false,
    sortType: 'string',
    sort: true
  },
  {
    id: 'email',
    label: strings.TextMail,
    numeric: false,
    sortType: 'string',
    sort: true
  },
  {
    id: 'phone',
    label: strings.TextNumber,
    numeric: false,
    sortType: 'string',
    sort: true
  },
  {
    id: 'address',
    label: strings.TextAddress,
    numeric: false,
    sortType: 'string',
    sort: true
  },
  {
    id: 'city',
    label: strings.TextCity,
    numeric: false,
    sortType: 'string',
    sort: true
  },
  {
    id: 'zipCode',
    label: strings.TextZipCode,
    numeric: false,
    sortType: 'string',
    sort: true
  }
]

class Clinics extends React.Component {
  state = {
    orderBy: 'id',
    order: 'desc',
    sortType: 'string',
    rowsPerPage: 10,
    page: 0,
    searchInput: ''
  }

  componentDidMount() {
    const { user, medotemicData } = this.props

    if (!medotemicData.clinics.fetched) {
      this.props.tryGetClinics(user.token, user.id)
    }
  }

  getClinics() {
    const { medotemicData } = this.props
    const clinics = []

    Object.keys(medotemicData.clinics.data).forEach(key => {
      clinics.push(medotemicData.clinics.data[key])
    })

    return clinics
  }

  desc(a, b, orderBy, sortType) {
    if (b[orderBy] < a[orderBy]) {
      return 1
    } else if (b[orderBy] > a[orderBy]) {
      return -1
    }

    return 0
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index])

    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0])

      if (order !== 0) {
        return order
      }

      return a[1] - b[1]
    })

    return stabilizedThis.map(el => el[0])
  }

  getSorting(order, orderBy, sortType) {
    return order === 'desc'
      ? (a, b) => this.desc(a, b, orderBy, sortType)
      : (a, b) => -this.desc(a, b, orderBy, sortType)
  }

  filterId(clinic) {
    const { searchInput } = this.state

    return (
      clinic.id
        .toString()
        .toLowerCase()
        .indexOf(searchInput.toLowerCase()) !== -1
    )
  }

  render() {
    const { classes, medotemicData, history } = this.props
    const {
      orderBy,
      order,
      rowsPerPage,
      page,
      searchInput,
      sortType
    } = this.state

    if (!medotemicData.clinics.fetched) {
      return (
        <main style={Styles.default.loaderContainer}>
          <CircularProgress
            style={{ color: product.mainColor }}
            thickness={2}
            size={82}
          />
        </main>
      )
    }

    const startIndex = page * rowsPerPage
    const clinics = this.getClinics().filter(p => this.filterId(p))

    return (
      <main className={classes.content}>
        <p className={classes.header}>
          {strings.HeaderPt1}
          <span className={classes.lastHeader}>{strings.HeaderPt2}</span>
        </p>
        <Paper className={classes.root}>
          <div>
            <div style={Styles.default.container}>
              <p class={'boxHeader'}>{strings.TextClinics}</p>
              <div style={Styles.default.innerContainer}>
                <Inputs
                  placeholder={strings.TextSearch}
                  style={{ marginLeft: 0 }}
                  onChange={event =>
                    this.setState({ searchInput: event.target.value })
                  }
                  value={searchInput}
                />
              </div>
            </div>
          </div>

          <div className={classes.innerRoot}>
            <p className={'smallText'}>
              {`${strings.TextPage} ${1 + page} ${strings.TextOf} ${Math.ceil(clinics.length / rowsPerPage)}`}
            </p>
            <Table className={classes.table} aria-labelledby={'tableTitle'}>
              <TableHead
                rows={ROWS}
                order={order}
                orderBy={orderBy}
                sortOn={(newOrderBy, newOrder, newSortType) =>
                  this.setState({
                    orderBy: newOrderBy,
                    order: newOrder,
                    sortType: newSortType
                  })
                }
              />

              <TableBody>
                {this.stableSort(
                  clinics,
                  this.getSorting(order, orderBy, sortType)
                )
                  .slice(startIndex, rowsPerPage + startIndex)
                  .map(c => {
                    return (
                      <TableRow
                        key={c.id}
                        className={`${classes.row} hover`}
                        onClick={() => history.push(`/clinics/${c.id}`)}
                      >
                        {ROWS.map(r => {
                          if (
                            r.id === 'maxPatients' ||
                            r.id === 'price' ||
                            r.id === 'expires'
                          ) {
                            if (c.license) {
                              c[r.id] = c.license[r.id]
                            } else {
                              c[r.id] = null
                            }
                          }

                          return (
                            <TableCell>
                              {c[r.id] ? (
                                <p style={{ fontSize: 16 }}>{c[r.id]}</p>
                              ) : (
                                <p style={{ fontSize: 16, color: '#f26363' }}>
                                  {strings.TextMissing}
                                </p>
                              )}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </div>

          <div className={classes.root}>
            <TablePagination
              component={'div'}
              count={clinics.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page'
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page'
              }}
              labelRowsPerPage={strings.TextRowsPerPage}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} ` + strings.TextOf + ` ${count}`
              }
              onChangePage={(event, page) => this.setState({ page })}
              onChangeRowsPerPage={event =>
                this.setState({ rowsPerPage: event.target.value })
              }
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
            />
          </div>
        </Paper>
      </main>
    )
  }
}

const Classes = theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
    paddingTop: 88
  },
  header: {
    fontWeight: 'bold',
    fontStyle: 'italic',
    fontSize: 87,
    margin: 0
  },
  lastHeader: {
    color: product.mainColor
  },
  table: {
    minWidth: 700
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fafafa'
    }
  },
  root: {
    width: '100%',
    marginTop: 24
  },
  innerRoot: {
    width: '100%',
    overflowX: 'auto'
  }
})

Clinics.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps({ config, user, medotemicData }) {
  return { config, user, medotemicData }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...configActions, ...clinicsActions }, dispatch)
}

export default compose(
  withStyles(Classes),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Clinics)
