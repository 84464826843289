import * as Constants from '../helpers/Constants'
import * as Storages from '../helpers/Storages'
import * as Types from './Types'
import * as API from './api/provider'
import * as configActions from './Config'

import product from '../helpers/Constants/product'

export function tryValidateToken() {
  return dispatch => {
    const token = Storages.getToken() ? JSON.parse(Storages.getToken()) : null
    const userId = Storages.getUserId()
      ? JSON.parse(Storages.getUserId())
      : null

    if ((token, userId)) {
      dispatch({
        type: Types.VALIDATE_TOKEN
      })

      API.validateToken(token, userId)
        .then(response => {
          const user = response.data
          user.token = token

          if (user.type === Constants.HEALTHCARE_PROVIDER) {
            dispatch({
              type: Types.VALIDATE_TOKEN_SUCCESS,
              payload: user
            })
          } else if (user.type === Constants.PATIENT) {
            dispatch({
              type: Types.VALIDATE_TOKEN_SUCCESS_PATIENT,
              payload: user
            })
          } else if (user.type === Constants.CLINIC) {
            dispatch({
              type: Types.VALIDATE_TOKEN_SUCCESS_CLINIC,
              payload: user
            })
          } else if (
            user.type === Constants.MEDOTEMIC
          ) {
            dispatch({
              type: Types.VALIDATE_TOKEN_SUCCESS_MEDOTEMIC,
              payload: user
            })
          } else {
            Storages.removeToken()
            Storages.removeUserId()

            dispatch({
              type: Types.VALIDATE_TOKEN_ERROR
            })
          }
        })
        .catch(error => {
          Storages.removeToken()
          Storages.removeUserId()

          dispatch({
            type: Types.VALIDATE_TOKEN_ERROR
          })
        })
    } else {
      Storages.removeToken()
      Storages.removeUserId()

      dispatch({
        type: Types.VALIDATE_TOKEN_ERROR
      })
    }
  }
}

export function trySignIn(username, data) {
  return dispatch => {
    dispatch({
      type: Types.SIGN_IN
    })

    API.signIn(username, data)
      .then(response => {
        const user = response.data

        Storages.setToken(JSON.stringify(user.token))
        Storages.setUserId(JSON.stringify(user.id))

        // Currently we only support healthcare providers to sign in.
        if (
          user.type === Constants.HEALTHCARE_PROVIDER
        ) {
          dispatch({
            type: Types.SIGN_IN_SUCCESS,
            payload: user
          })
        } else if (user.type === Constants.PATIENT) {
          dispatch({
            type: Types.SIGN_IN_SUCCESS_PATIENT,
            payload: user
          })
        } else if (user.type === Constants.CLINIC) {
          dispatch({
            type: Types.SIGN_IN_SUCCESS_CLINIC,
            payload: user
          })
        } else if (
          user.type === Constants.MEDOTEMIC
        ) {
          dispatch({
            type: Types.VALIDATE_TOKEN_SUCCESS_MEDOTEMIC,
            payload: user
          })
        } else {
          dispatch({
            type: Types.SIGN_IN_ERROR,
            payload: 401
          })
        }
      })
      .catch(error => {
        let status = 500
        // If the server can not respond response will be undefined which will cause a crash.
        if (error.response && error.response.status) {
          status = error.response.status
        }

        dispatch({
          type: Types.SIGN_IN_ERROR,
          payload: status
        })
      })
  }
}

export function signOut() {
  Storages.removeToken()
  Storages.removeUserId()

  return dispatch => {
    dispatch({
      type: Types.SIGN_OUT_SUCCESS
    })
  }
}
