import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Paper from '../../../common/Paper'
import Button from '../../../common/Button'
import * as Forms from '../../../common/Forms'

import * as clinicActions from '../../../../actions/clinic/Clinic'
import * as configActions from '../../../../actions/Config'
import * as Constants from '../../../../helpers/Constants'
import strings from '../../../../helpers/I18NStrings/index'

class Settings extends React.Component {
  state = {
    name: '',
    maxPatients: '',
    orgNumber: '',
    address: '',
    city: '',
    email: '',
    phone: '',
    zipCode: '',
    newPassword: '',
    repeatNewPassword: ''
  }

  componentDidMount() {
    const { user, clinicData } = this.props

    if (!clinicData.clinic.fetched) {
      this.props.tryGetClinic(user.token, user.id)
    } else {
      this.setState({ ...clinicData.clinic.data })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { clinicData, clinic } = this.props

    if (nextProps.clinicData.clinic.fetched && !clinicData.clinic.fetched) {
      this.setState({ ...nextProps.clinicData.clinic.data })
    }

    if (
      !nextProps.clinic.isUpdating &&
      clinic.isUpdating &&
      nextProps.clinic.errorUpdatingStatus === null
    ) {
      this.props.showAlert(
        strings.SettingsUpdatedHeader,
        strings.SettingsUpdated,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => {} }
      )

      this.setState({ newPassword: '', repeatNewPassword: '' })
    } else if (!nextProps.clinic.isUpdating && clinic.isUpdating) {
      this.props.showAlert(
        strings.UnexpectedErrorHeader,
        strings.UnexpectedError,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => {} }
      )
    }
  }

  tryUpdate() {
    const { user } = this.props
    const { email, phone, orgNumber, address, city, zipCode } = this.state
    const data = { email, phone, orgNumber, address, city, zipCode }

    this.props.tryUpdateClinic(user.token, user.id, data)
  }

  updatePassword() {
    const { user } = this.props
    const { newPassword, repeatNewPassword } = this.state

    if (!newPassword && !repeatNewPassword) {
      this.props.showAlert(
        strings.InfoMissingHeader,
        strings.InfoMissing,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => {} }
      )
    } else if (newPassword !== repeatNewPassword) {
      this.props.showAlert(
        strings.PasswordsNoMatchHeader,
        strings.PasswordsNoMatch,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => {} }
      )
    } else {
      const data = { password: newPassword }

      this.props.tryUpdateClinic(user.token, user.id, data)
    }
  }

  render() {
    const { classes, clinic } = this.props

    return (
      <main className={classes.content}>
        <p class={'header'}>{strings.TextClinicSettings}</p>

        <p class={'secondHeader'}>{strings.TextContacts}</p>
        <Paper style={{ alignItems: 'flex-start', marginTop: 24 }}>
          <Forms.Clinic
            classes={classes}
            edit
            inputs={this.state}
            onChange={(key, text) => this.setState({ [key]: text })}
            showMaxPatients={false}
            showName={false}
            lock
          />
        </Paper>

        <p class={'secondHeader'}>{strings.TextNewPassword}</p>
        <Paper style={{ alignItems: 'flex-start', marginTop: 24 }}>
          <Forms.Password
            classes={classes}
            inputs={this.state}
            onChange={(key, text) => this.setState({ [key]: text })}
          />
        </Paper>

        <div className={classes.buttonContainer}>
          <Button
            className={'success'}
            onClick={() => this.updatePassword()}
            loading={clinic.isUpdating}
            header={strings.TextUpdate}
          />
        </div>
      </main>
    )
  }
}

const Styles = theme => ({
  innerContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
    paddingTop: 88
  },
  providerIdContainer: {
    display: 'flex'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

Settings.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps({ config, user, clinicData, clinic }) {
  return { config, user, clinicData, clinic }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...configActions, ...clinicActions }, dispatch)
}

export default compose(
  withStyles(Styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Settings)
