import * as Types from '../Types'
import * as API from '../api/clinic'

export function tryGetProviders(token, userId) {
  return dispatch => {
    dispatch({
      type: Types.GET_PROVIDERS
    })

    API.getProviders(token, userId)
      .then(response => {
        dispatch({
          type: Types.GET_PROVIDERS_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: Types.GET_PROVIDERS_ERROR
        })
      })
  }
}

export function tryCreateProviders(token, userId, data) {
  return dispatch => {
    dispatch({
      type: Types.CREATE_PROVIDER
    })

    API.createProvider(token, userId, data)
      .then(response => {
        dispatch({
          type: Types.CREATE_PROVIDER_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        let status = 500

        if (error.response && error.response.status) {
          status = error.response.status
        }

        dispatch({
          type: Types.CREATE_PROVIDER_ERROR,
          payload: status
        })
      })
  }
}
