import * as Types from '../../actions/Types'

const INITIAL_STATE = {
    isChangingPassword: false,
    isChangingUsername: false,
    errorChangingLoginStatus: null
}

export default (state = INITIAL_STATE, action) => {
    const { payload, type } = action

    switch (type) {
        case Types.CHANGE_PROVIDER_PASSWORD:
            return {
                ...state,
                isChangingPassword: true,
                errorChangingLoginStatus: null
            }
        case Types.CHANGE_PROVIDER_PASSWORD_SUCCESS:
            return {
                ...state,
                isChangingPassword: false,
                errorChangingLoginStatus: null
            }
        case Types.CHANGE_PROVIDER_PASSWORD_ERROR:
            return {
                ...state,
                isChangingPassword: false,
                errorChangingLoginStatus: payload
            }
        case Types.CHANGE_PROVIDER_USERNAME:
            return {
                ...state,
                isChangingUsername: true,
                errorChangingLoginStatus: null
            }
        case Types.CHANGE_PROVIDER_USERNAME_SUCCESS:
            return {
                ...state,
                isChangingUsername: false,
                errorChangingLoginStatus: null
            }
        case Types.CHANGE_PROVIDER_USERNAME_ERROR:
            return {
                ...state,
                isChangingUsername: false,
                errorChangingLoginStatus: payload
            }
        default:
            return state
    }
}