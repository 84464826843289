import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MatButton from '@material-ui/core/Button'
import {
  faChevronDown,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons'
import CircularProgress from '@material-ui/core/CircularProgress'

import TrainingsTable from '../../../common/TrainingsTable'
import * as Graphs from '../../../common/Graphs'
import Paper from '../../../common/Paper'
import Checkbox from '../../../common/Checkbox'
import RadioButton from '../../../common/RadioButton'
import Button from '../../../common/Button'
import GoBack from '../../../common/GoBack'
import Modal from '../../../common/Modal'

import * as Styles from './styles'
import * as patientsActions from '../../../../actions/Patients'
import * as configActions from '../../../../actions/Config'
import * as userStatisticsPageActions from '../../../../actions/UserStatisticsPage'
import * as trainingsActions from '../../../../actions/Trainings'
import * as clinicPatientsActions from '../../../../actions/clinic/Patients'
import * as Functions from '../../../../helpers/Functions'
import * as Constants from '../../../../helpers/Constants'
import product from '../../../../helpers/Constants/product'
import strings from '../../../../helpers/I18NStrings/index'

class UserStatistics extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      width: window.innerWidth,
      showFilter: false,
      searchInput: '',
      changePatient: false
    }

    this.resizeHandler = this.resizeHandler.bind(this)
  }

  componentDidMount() {
    const { user, data, match } = this.props

    this.props.setCompareTrainings([])

    window.addEventListener('resize', this.resizeHandler)

    if (match.params.providerId) {
      this.props.tryGetProviderPatients(
        user.token,
        user.id,
        match.params.providerId
      )
    } else if (!data.patients.fetched) {
      this.props.tryGetPatients(user.token, user.id)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { trainings } = nextProps

    if (
      !trainings.isDeleting &&
      !trainings.errorDeletingStatus &&
      this.props.trainings.isDeleting
    ) {
      this.props.setCompareTrainings([])
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler)
  }

  resizeHandler() {
    this.setState({ width: window.innerWidth })
  }

  getQueryParamForRapport() {
    const { config } = this.props
    const today = Functions.getDate(new Date())
    const from = new Date()

    if (config.userStatisticsPage.filterTrainings === Constants.ALL_TRAININGS) {
      return ''
    } else if (
      config.userStatisticsPage.filterTrainings === Constants.DAYS_90
    ) {
      from.setDate(from.getDate() - 90)

      return `?fromDate=${Functions.getDate(from)}&toDate=${today}`
    } else if (
      config.userStatisticsPage.filterTrainings === Constants.DAYS_30
    ) {
      from.setDate(from.getDate() - 30)

      return `?fromDate=${Functions.getDate(from)}&toDate=${today}`
    } else if (config.userStatisticsPage.filterTrainings === Constants.DAYS_7) {
      from.setDate(from.getDate() - 7)

      return `?fromDate=${Functions.getDate(from)}&toDate=${today}`
    } else {
      return `?fromDate=${config.userStatisticsPage.filterFrom}&toDate=${
        config.userStatisticsPage.filterTo
        }`
    }
  }

  getTrainings() {
    const { data, config, patients, match } = this.props

    const trainings = data.patients.data[match.params.patientId].trainings
    const date = new Date()
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)

    if (config.userStatisticsPage.filterTrainings === Constants.ALL_TRAININGS) {
      return trainings
    } else if (
      config.userStatisticsPage.filterTrainings === Constants.DAYS_90
    ) {
      date.setDate(date.getDate() - 90)

      return trainings.filter(t => {
        return new Date(t.time).getTime() >= date.getTime()
      })
    } else if (
      config.userStatisticsPage.filterTrainings === Constants.DAYS_30
    ) {
      date.setDate(date.getDate() - 30)

      return trainings.filter(t => {
        return new Date(t.time).getTime() >= date.getTime()
      })
    } else if (config.userStatisticsPage.filterTrainings === Constants.DAYS_7) {
      date.setDate(date.getDate() - 7)

      return trainings.filter(t => {
        return new Date(t.time).getTime() >= date.getTime()
      })
    } else {
      const from = new Date(config.userStatisticsPage.filterFrom)
      from.setHours(0)
      from.setMinutes(0)
      from.setSeconds(0)

      const to = new Date(config.userStatisticsPage.filterTo)
      to.setHours(23)
      to.setMinutes(59)
      to.setSeconds(59)

      return trainings.filter(t => {
        const trainingTime = new Date(t.time).getTime()

        return trainingTime >= from.getTime() && trainingTime <= to.getTime()
      })
    }
  }

  getPatients() {
    const { data } = this.props
    const { searchInput } = this.state
    const patients = []

    Object.keys(data.patients.data).forEach(key => {
      patients.push(data.patients.data[key])
    })

    return patients.filter(
      p =>
        p.id
          .toString()
          .toLowerCase()
          .indexOf(searchInput.toLowerCase()) !== -1
    )
  }

  renderGraphs() {
    const { patients, data, config, match } = this.props
    const { width } = this.state

    const trainings = this.getTrainings()
      .filter(t => t.config >= 0)
      .sort((t1, t2) => {
        return new Date(t1.time).getTime() - new Date(t2.time).getTime()
      })

    const perDayParsedData = Functions.getGraphDataPerDayFromTrainings(trainings)
    const perWeekParsedData = Functions.getGraphDataPerWeekFromTrainings(trainings)

    const goals = Functions.getActiveGoals(
      data.patients.data[match.params.patientId].goals
    )

    const graphs = [
      {
        show: config.userStatisticsPage.showLimpGraphs,
        component: (
          <Graphs.LimpIndexPerDay
            data={perDayParsedData.results}
            goalData={Functions.parseLimpIndexGoal(
              goals.limpIndexGoal,
              perDayParsedData.results
            )}
          />
        )
      },
      {
        show: config.userStatisticsPage.showNumberOfTrainingsGraphs,
        component: (
          <Graphs.NumberOfTrainingsPerWeek
            data={perWeekParsedData.numberOfTrainings}
            goalData={Functions.parseNumberOfTrainingsGoal(
              goals.trainingsGoal,
              perWeekParsedData.numberOfTrainings
            )}
          />
        )
      },
      {
        show: config.userStatisticsPage.showStepGraphs,
        component: (
          <Graphs.StepsPerDay
            data={perDayParsedData.steps}
          />
        )
      },
      {
        show: config.userStatisticsPage.showActiveTimeGraphs,
        component: (
          <Graphs.ActiveTimePerDay
            data={perWeekParsedData.activeTime}
            goalData={Functions.parseMinutesGoal(
              goals.minutesWalkGoal,
              perWeekParsedData.activeTime
            )}
          />
        )
      },
      {
        show: config.userStatisticsPage.showKadensGraphs,
        component: (
          <Graphs.KadensPerDay
            data={perDayParsedData.kadens}
          />
        )
      },
    ]

    const filteredGraphs = graphs.filter(g => g.show)
    let graphComponents = []

    if (width > 1700) {
      for (let i = 0; i < filteredGraphs.length; i = i + 2) {
        if (i + 1 < filteredGraphs.length) {
          graphComponents.push(
            <div key={i} style={Styles.default.chartContainer}>
              <Paper style={{ marginRight: 12 }}>
                {filteredGraphs[i].component}
              </Paper>
              <Paper style={{ marginLeft: 12 }}>
                {filteredGraphs[i + 1].component}
              </Paper>
            </div>
          )
        } else {
          graphComponents.push(
            <div key={i} style={Styles.default.chartContainer}>
              <Paper style={{ marginRight: 12 }}>
                {filteredGraphs[i].component}
              </Paper>
              <div style={{ marginLeft: 12, width: '100%', padding: 24 }} />
            </div>
          )
        }
      }
    } else {
      graphComponents = filteredGraphs.map((g, index) => (
        <Paper style={{ marginTop: 24 }} key={index}>
          {g.component}
        </Paper>
      ))
    }

    return graphComponents
  }

  deleteTrainings() {
    const { config, user, patients, match } = this.props

    this.props.showAlert(
      strings.TextRemoveTrainings,
      strings.TextSureToRemoveTrainings,
      Constants.TWO_BUTTONS,
      {
        title: strings.ButtonNo,
        onPress: () => { }
      },
      {
        title: strings.ButtonYes,
        onPress: () =>
          this.props.tryDeleteTrainings(
            user.token,
            user.id,
            match.params.patientId,
            config.userStatisticsPage.compareTrainings
          )
      }
    )
  }

  renderContent() {
    const { showFilter, searchInput, changePatient } = this.state
    const { classes, patients, data, config, history, match, user } = this.props

    if (match.params.patientId) {
      const patient = data.patients.data[match.params.patientId]

      if (patient) {
        const trainings = this.getTrainings().filter(t => t.config >= 0)
        const compareLength = config.userStatisticsPage.compareTrainings.length

        return (
          <div>
            <GoBack
              history={history}
              show={match.params.providerId}
              title={strings.TextBackToClinicView}
            />
            <p
              className={'header'}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer'
              }}
              onClick={() => this.setState({ changePatient: true })}
            >
              {strings.TextPatient + ' '}
              <span style={{ color: product.mainColor, marginLeft: 12 }}>
                {match.params.patientId}
              </span>
              <FontAwesomeIcon
                icon={faChevronDown}
                style={{ marginLeft: 12, fontSize: 24 }}
              />
            </p>

            <Button
              className={'success'}
              onClick={() =>
                history.push(
                  user.type === Constants.CLINIC
                    ? `/providers/${match.params.providerId}/patients/${
                    match.params.patientId
                    }/settings`
                    : `/patients/${match.params.patientId}/settings`
                )
              }
              header={strings.TextProfile}
            />
            <Button
              style={{ marginLeft: 24 }}
              className={'success'}
              onClick={() =>
                user.type === Constants.CLINIC
                  ? history.push(
                    `/providers/${match.params.providerId}/patients/${
                    match.params.patientId
                    }/goals`
                  )
                  : history.push(`/patients/${match.params.patientId}/goals`)
              }
              header={strings.TextTargets}
            />

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <a onClick={() => this.setState({ showFilter: !showFilter })}>
                {showFilter
                  ? strings.TextHideFilterReports
                  : strings.TextShowFilterReports}
                <FontAwesomeIcon
                  icon={showFilter ? faChevronDown : faChevronRight}
                  style={{ marginLeft: 10 }}
                />
              </a>
            </div>

            {showFilter ? (
              <Paper style={Styles.default.filterContainer}>
                <p className={'boxHeader'}>{strings.TextFilt}</p>
                <div style={Styles.default.innerFilterContainer}>
                  <Checkbox
                    label={strings.TextTable}
                    style={{ width: 250 }}
                    checked={config.userStatisticsPage.showTables}
                    onChange={() =>
                      this.props.showTables(
                        !config.userStatisticsPage.showTables
                      )
                    }
                  />
                  <Checkbox
                    label={strings.TextLimpingIndex}
                    style={{ width: 250 }}
                    checked={config.userStatisticsPage.showLimpGraphs}
                    onChange={() =>
                      this.props.showLimpGraphs(
                        !config.userStatisticsPage.showLimpGraphs
                      )
                    }
                  />
                  <Checkbox
                    label={strings.NumberOfTrainings}
                    style={{ width: 250 }}
                    checked={config.userStatisticsPage.showNumberOfTrainingsGraphs}
                    onChange={() =>
                      this.props.showNumberOfTrainingsGraphs(
                        !config.userStatisticsPage.showNumberOfTrainingsGraphs
                      )
                    }
                  />
                  <Checkbox
                    label={strings.TextSteps}
                    style={{ width: 250 }}
                    checked={config.userStatisticsPage.showStepGraphs}
                    onChange={() =>
                      this.props.showStepGraphs(
                        !config.userStatisticsPage.showStepGraphs
                      )
                    }
                  />
                  <Checkbox
                    label={strings.TextActiveTime}
                    style={{ width: 250 }}
                    checked={config.userStatisticsPage.showActiveTimeGraphs}
                    onChange={() =>
                      this.props.showActiveTimeGraphs(
                        !config.userStatisticsPage.showActiveTimeGraphs
                      )
                    }
                  />
                  <Checkbox
                    label={strings.TextCadence}
                    style={{ width: 250 }}
                    checked={config.userStatisticsPage.showKadensGraphs}
                    onChange={() =>
                      this.props.showKadensGraphs(
                        !config.userStatisticsPage.showKadensGraphs
                      )
                    }
                  />
                </div>
                <p className={'boxHeader'}>
                  {strings.TextInterval}
                </p>
                <div style={Styles.default.innerFilterContainer}>
                  <RadioButton
                    label={strings.TextAllTrainings}
                    style={{ width: 250 }}
                    checked={
                      Constants.ALL_TRAININGS ===
                      config.userStatisticsPage.filterTrainings
                    }
                    onChange={() => {
                      this.props.setTrainingFilter(Constants.ALL_TRAININGS)
                      this.props.setCompareTrainings([])
                    }}
                  />
                  <RadioButton
                    label={strings.Text90days}
                    style={{ width: 250 }}
                    checked={
                      Constants.DAYS_90 ===
                      config.userStatisticsPage.filterTrainings
                    }
                    onChange={() => {
                      this.props.setTrainingFilter(Constants.DAYS_90)
                      this.props.setCompareTrainings([])
                    }}
                  />
                  <RadioButton
                    label={strings.Text30days}
                    style={{ width: 250 }}
                    checked={
                      Constants.DAYS_30 ===
                      config.userStatisticsPage.filterTrainings
                    }
                    onChange={() => {
                      this.props.setTrainingFilter(Constants.DAYS_30)
                      this.props.setCompareTrainings([])
                    }}
                  />
                  <RadioButton
                    label={strings.Text7days}
                    style={{ width: 250 }}
                    checked={
                      Constants.DAYS_7 ===
                      config.userStatisticsPage.filterTrainings
                    }
                    onChange={() => {
                      this.props.setTrainingFilter(Constants.DAYS_7)
                      this.props.setCompareTrainings([])
                    }}
                  />
                  <RadioButton
                    label={strings.TextOwnInterval}
                    style={{ width: 250 }}
                    checked={
                      Constants.SELECT_INTERVAL ===
                      config.userStatisticsPage.filterTrainings
                    }
                    onChange={() => {
                      this.props.setTrainingFilter(Constants.SELECT_INTERVAL)
                      this.props.setCompareTrainings([])
                    }}
                  />
                  {Constants.SELECT_INTERVAL ===
                    config.userStatisticsPage.filterTrainings ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: 24
                        }}
                      >
                        <input
                          type={'date'}
                          placeholder={strings.TextFrom}
                          style={{ marginRight: 24, marginTop: 0 }}
                          onChange={event =>
                            this.props.setFromFilter(event.target.value)
                          }
                          value={config.userStatisticsPage.filterFrom}
                        />
                        <p>-</p>
                        <input
                          type={'date'}
                          placeholder={strings.TextTo}
                          style={{ marginLeft: 24, marginTop: 0 }}
                          onChange={event =>
                            this.props.setToFilter(event.target.value)
                          }
                          value={config.userStatisticsPage.filterTo}
                        />
                      </div>
                    ) : null}
                </div>
                <Button
                  className={'success'}
                  onClick={() => {
                    // const BASE_URL =
                    //   process.env.NODE_ENV === 'development'
                    //     ? 'http://localhost:8080/api/v1/'
                    //     : 'https://api.medoclinic.se/api/v1/'

                    // const queryParam = this.getQueryParamForRapport()

                    // window.open(
                    //   `${BASE_URL}reports/users/${
                    //     patient.user.id
                    //   }/trainings${queryParam}`
                    // )
                    this.props.showAlert(
                      strings.UnderConstructionTitle,
                      strings.UnderConstructionText,
                      Constants.ONE_BUTTON,
                      { title: strings.ButtonOK, onPress: () => { } }
                    )
                  }}
                  header={strings.TextGenerateReport}
                />
              </Paper>
            ) : null}

            {trainings.length === 0 ? (
              <p className={'warningText'}>
                {strings.TextNoPatientInterval}
              </p>
            ) : null}

            {trainings.length === 0 ? null : (
              <div>
                {config.userStatisticsPage.showTables ? (
                  <div>
                    <TrainingsTable
                      columns={[
                        'selections',
                        'time',
                        'result',
                        'trend',
                        'selfLimp',
                        'pain',
                        'steps',
                        'activeTime',
                        'totTime',
                        'placement'
                      ]}
                      order={config.userStatisticsPage.order}
                      orderBy={config.userStatisticsPage.orderBy}
                      sortType={config.userStatisticsPage.sortType}
                      setSortOrder={(newOrderBy, newOrder, newSortType) =>
                        this.props.setSortOrder(
                          newOrderBy,
                          newOrder,
                          newSortType
                        )
                      }
                      trainings={trainings}
                      rowsPerPage={config.userStatisticsPage.rowsPerPage}
                      setPaginationRowsPerPage={row =>
                        this.props.setPaginationRowsPerPage(row)
                      }
                      page={config.userStatisticsPage.page}
                      setPaginationPage={page =>
                        this.props.setPaginationPage(page)
                      }
                      compareTrainings={
                        config.userStatisticsPage.compareTrainings
                      }
                      setCompareTrainings={t =>
                        this.props.setCompareTrainings(t)
                      }
                      onToggleAllCheckbox={() => {
                        if (
                          config.userStatisticsPage.compareTrainings.length ===
                          0
                        ) {
                          const ids = trainings.map(t => t.id)

                          this.props.setCompareTrainings(ids)
                        } else {
                          this.props.setCompareTrainings([])
                        }
                      }}
                      allCheckboxesValue={
                        trainings.length ===
                        config.userStatisticsPage.compareTrainings.length
                      }
                      allCheckboxesIndeterminate={
                        config.userStatisticsPage.compareTrainings.length !==
                        0 &&
                        trainings.length !==
                        config.userStatisticsPage.compareTrainings.length
                      }
                    />
                    {compareLength !== 0 ? (
                      <div className={classes.buttonContainer}>
                        <Button
                          style={{ marginRight: 24 }}
                          className={'warning'}
                          color={'warning'}
                          onClick={() => this.deleteTrainings()}
                          loading={this.props.trainings.isDeleting}
                          header={
                            compareLength === 1
                              ? strings.TextRemoveTraining
                              : strings.TextRemoveTrainings +
                              ' (' +
                              compareLength +
                              ')'
                          }
                        />
                        <Button
                          className={'success'}
                          onClick={() =>
                            user.type === Constants.CLINIC
                              ? history.push(
                                '/providers/' +
                                match.params.providerId +
                                '/patients/' +
                                match.params.patientId +
                                '/trainings'
                              )
                              : history.push(
                                '/patients/' +
                                match.params.patientId +
                                '/trainings'
                              )
                          }
                          header={
                            compareLength === 1
                              ? strings.TextViewTraining
                              : strings.TextCompareTraining +
                              ' (' +
                              compareLength +
                              ')'
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                ) : null}

                {this.renderGraphs()}
              </div>
            )}
          </div>
        )
      }

      return null
    }

    return null
  }

  render() {
    const { changePatient } = this.state
    const { classes, match, data, history, user } = this.props

    if (!data.patients.fetched) {
      return (
        <main style={Styles.default.loaderContainer}>
          <GoBack
            history={history}
            show={match.params.providerId}
            title={strings.TextBackToClinicView}
          />
          <CircularProgress
            style={{ color: product.mainColor }}
            thickness={2}
            size={82}
          />
        </main>
      )
    } else if (Object.keys(data.patients.data).length === 0) {
      return (
        <main style={Styles.default.loaderContainer}>
          <GoBack
            history={history}
            show={match.params.providerId}
            title={strings.TextBackToClinicView}
          />
          <p>{strings.TextNoPatientCreated}</p>
        </main>
      )
    }

    return (
      <main className={classes.content}>
        {this.renderContent()}
        <Modal
          show={
            !match.params.patientId ||
            !data.patients.data[match.params.patientId] ||
            changePatient
          }
          header={strings.TextChoosePatient}
          buttons={
            !match.params.patientId ? null : (
              <MatButton
                onClick={() => this.setState({ changePatient: false })}
                className={classes.successButtonText}
              >
                {strings.TextCancel}
              </MatButton>
            )
          }
        >
          {this.getPatients().map(p => {
            const selected = p.id == match.params.patientId

            let textStyles = {}

            if (selected) {
              textStyles = { color: product.mainColor, fontWeight: 'bold' }
            }

            return (
              <div
                key={p.id}
                style={{
                  paddingTop: 6,
                  paddingBottom: 6,
                  borderBottom: '1px solid #dddfe2',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  history.push(
                    user.type === Constants.CLINIC
                      ? `/providers/${match.params.providerId}/patients/${
                      p.id
                      }/statistics`
                      : `/patients/${p.id}/statistics`
                  )

                  this.setState({ changePatient: false })
                }}
              >
                <p style={textStyles}>{strings.TextPatient + ' ' + p.id}</p>
              </div>
            )
          })}
        </Modal>
      </main>
    )
  }
}

const Classes = theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
    paddingTop: 88
  },
  emptyContent: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  table: {
    minWidth: 700
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fafafa'
    }
  },
  root: {
    width: '100%',
    marginTop: 24,
    padding: 0
  },
  innerRoot: {
    width: '100%',
    overflowX: 'auto'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

UserStatistics.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps({ config, user, patients, data, trainings }) {
  return { config, user, patients, data, trainings }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...configActions,
      ...patientsActions,
      ...userStatisticsPageActions,
      ...trainingsActions,
      ...clinicPatientsActions
    },
    dispatch
  )
}

export default compose(
  withStyles(Classes),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserStatistics)
