import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { connect } from "react-redux";
import product from "../../../helpers/Constants/product";
import Paper from "../../common/Paper";
import Button from "../../common/Button";
import GoBack from "../../common/GoBack";
import strings from "../../../helpers/I18NStrings/index";
import PDFs from "./pdfs";
import * as Constants from "../../../helpers/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faMinus
} from "@fortawesome/free-solid-svg-icons";

class Information extends React.Component {
  render() {
    const { classes, match, history, user } = this.props;
    console.log("user", user.type);
    return (
      <main className={classes.content}>
        <GoBack
          history={history}
          show={match.params.providerId}
          title={strings.TextBackToClinicView}
        />
        <p className={classes.header}>
          {strings.HeaderPt1}
          <span className={classes.lastHeader}>{strings.HeaderPt2}</span>
        </p>
        <Paper
          className={classes.root}
          style={{
            marginTop: 24,
            justifyContent: "flex-start",
            alignItems: "flex-start"
          }}
        >
          <p className={"secondHeader"}>{strings.InfoHeader}</p>
          <p style={{ marginTop: 0 }}>
            {strings.TextInfoPt1}
            <br />
            <br />
            {strings.TextInfoPt2} <br />
            <br />
            {strings.TextInfoPt3}
          </p>
          <p className={"secondHeader"}>{strings.LimpInfoHeader}</p>
          <p style={{ marginTop: 0 }}>
            {strings.TextLimpInfoPt1}
            <br />
            {strings.TextLimpInfoPt2}
            <br />
            {strings.TextLimpInfoPt3}
            <br />
            {strings.TextLimpInfoPt4}
            <br />
            {strings.TextLimpInfoPt5}
          </p>
          <p className={"secondHeader"}>{strings.TrendInfo}</p>
          <p style={{ marginTop: 0 }}>{strings.TextTrendInfoPt1}</p>
          <p style={{marginTop:0}}>
            <div>
              <FontAwesomeIcon icon={faMinus} /> {strings.TextTrendInfoPt2}
              <br/>
              <FontAwesomeIcon icon={faArrowUp} /> {strings.TextTrendInfoPt4}
              <br/>
              <FontAwesomeIcon icon={faArrowDown} /> {strings.TextTrendInfoPt3}

            </div>
          </p>
          <p style={{marginTop: 0}}>{strings.TextTrendInfoPt5}</p>
          {(user.type === Constants.CLINIC ||
            user.type === Constants.HEALTHCARE_PROVIDER) && (
            <div>
              <p className={"secondHeader"}>{strings.TargetAchievement}</p>
              <p>{strings.TextTargetInfoPt1}</p>
              <p style={{ margin: 0 }}>{strings.TextTargetInfoPt2}</p>
              <li style={{ fontSize: 20 }}>{strings.TextTargetInfoPt21}</li>
              <li style={{ fontSize: 20 }}>{strings.TextTargetInfoPt22}</li>
              <li style={{ fontSize: 20 }}>{strings.TextTargetInfoPt23}</li>
              <p style={{ marginBottom: 0 }}>{strings.TextTargetInfoPt3}</p>
              <li style={{ fontSize: 20 }}>{strings.TextTargetInfoPt31}</li>
              <li style={{ fontSize: 20 }}>{strings.TextTargetInfoPt32}</li>
              <li style={{ fontSize: 20 }}>{strings.TextTargetInfoPt33}</li>
              <p style={{ marginBottom: 0 }}>{strings.TextTargetInfoPt4}</p>
              <li style={{ fontSize: 20 }}>{strings.TextTargetInfoPt41}</li>
              <li style={{ fontSize: 20 }}>{strings.TextTargetInfoPt42}</li>
            </div>
          )}
        </Paper>
        <div style={{ flexDirection: "row", display: "flex" }}>
          <div style={{ flexDirection: "column", display: "flex", paddingRight: 20 }}>
          <Button
            header={strings.TextManualApp}
            className={"success"}
            onClick={() => window.open(PDFs.App)}
          />
          <Button
            header={strings.TextManualWebPatient}
            className={"success"}
            onClick={() => window.open(product.ManualWeb)}
          />
          </div>
          <div style={{ flexDirection: "column", display: "flex", paddingRight: 20 }}>
          {(user.type === Constants.HEALTHCARE_PROVIDER ||
            user.type === Constants.CLINIC) && (
            <Button
              header={strings.TextManualWebCaregiver}
              className={"success"}
              onClick={() => window.open(PDFs.CaregiverWeb)}
            />
          )}
          <Button
            header={strings.TextFaq}
            className={"success"}
            onClick={() => window.open(PDFs.FAQ)}
          />
          </div>
        </div>
      </main>
    );
  }
}

const Classes = theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto",
    paddingTop: 88
  },
  header: {
    fontWeight: "bold",
    fontStyle: "italic",
    fontSize: 87,
    margin: 0
  },
  lastHeader: {
    color: product.mainColor
  },
  root: {
    width: "100%",
    marginTop: 24
  }
});

function mapStateToProps({ config, user, data }) {
  return { config, user, data };
}

export default compose(
  withStyles(Classes),
  connect(
    mapStateToProps,
    null
  )
)(Information);
