import * as Types from './Types'
import * as API from './api/provider'

export function tryCreateMinuteGoal(token, userId, patientId, data) {
  return dispatch => {
    dispatch({
      type: Types.CREATE_MINUTE_GOAL
    })

    API.createMinuteGoal(token, userId, patientId, data)
      .then(response => {
        dispatch({
          type: Types.CREATE_MINUTE_GOAL_SUCCESS,
          payload: { data: response.data, patientId }
        })
      })
      .catch(error => {
        dispatch({
          type: Types.CREATE_MINUTE_GOAL_ERROR
        })
      })
  }
}

export function tryDeleteMinuteGoal(token, userId, patientId, id) {
  return dispatch => {
    dispatch({
      type: Types.DELETE_MINUTE_GOAL
    })

    API.deleteMinuteGoal(token, userId, patientId, id)
      .then(response => {
        dispatch({
          type: Types.DELETE_MINUTE_GOAL_SUCCESS,
          payload: { data: response.data, patientId }
        })
      })
      .catch(error => {
        dispatch({
          type: Types.DELETE_MINUTE_GOAL_ERROR
        })
      })
  }
}

export function tryEditMinuteGoal(token, userId, patientId, id, data) {
  return dispatch => {
    dispatch({
      type: Types.EDIT_MINUTE_GOAL
    })

    API.editMinuteGoal(token, userId, patientId, id, data)
      .then(response => {
        dispatch({
          type: Types.EDIT_MINUTE_GOAL_SUCCESS,
          payload: { data: response.data, patientId, id }
        })
      })
      .catch(error => {
        dispatch({
          type: Types.EDIT_MINUTE_GOAL_ERROR
        })
      })
  }
}

export function tryCreateLimpGoal(token, userId, patientId, data) {
  return dispatch => {
    dispatch({
      type: Types.CREATE_LIMP_GOAL
    })

    API.createLimpGoal(token, userId, patientId, data)
      .then(response => {
        dispatch({
          type: Types.CREATE_LIMP_GOAL_SUCCESS,
          payload: { data: response.data, patientId }
        })
      })
      .catch(error => {
        dispatch({
          type: Types.CREATE_LIMP_GOAL_ERROR
        })
      })
  }
}

export function tryDeleteLimpGoal(token, userId, patientId, id) {
  return dispatch => {
    dispatch({
      type: Types.DELETE_LIMP_GOAL
    })

    API.deleteLimpGoal(token, userId, patientId, id)
      .then(response => {
        dispatch({
          type: Types.DELETE_LIMP_GOAL_SUCCESS,
          payload: { data: response.data, patientId }
        })
      })
      .catch(error => {
        dispatch({
          type: Types.DELETE_LIMP_GOAL_ERROR
        })
      })
  }
}

export function tryEditLimpGoal(token, userId, patientId, id, data) {
  return dispatch => {
    dispatch({
      type: Types.EDIT_LIMP_GOAL
    })

    API.editLimpGoal(token, userId, patientId, id, data)
      .then(response => {
        dispatch({
          type: Types.EDIT_LIMP_GOAL_SUCCESS,
          payload: { data: response.data, patientId, id }
        })
      })
      .catch(error => {
        dispatch({
          type: Types.EDIT_LIMP_GOAL_ERROR
        })
      })
  }
}

export function tryCreateTrainingsGoal(token, userId, patientId, data) {
  return dispatch => {
    dispatch({
      type: Types.CREATE_TRAININGS_GOAL
    })

    API.createTrainingsGoal(token, userId, patientId, data)
      .then(response => {
        dispatch({
          type: Types.CREATE_TRAININGS_GOAL_SUCCESS,
          payload: { data: response.data, patientId }
        })
      })
      .catch(error => {
        dispatch({
          type: Types.CREATE_TRAININGS_GOAL_ERROR
        })
      })
  }
}

export function tryDeleteTrainingsGoal(token, userId, patientId, id) {
  return dispatch => {
    dispatch({
      type: Types.DELETE_TRAININGS_GOAL
    })

    API.deleteTrainingsGoal(token, userId, patientId, id)
      .then(response => {
        dispatch({
          type: Types.DELETE_TRAININGS_GOAL_SUCCESS,
          payload: { data: response.data, patientId }
        })
      })
      .catch(error => {
        dispatch({
          type: Types.DELETE_TRAININGS_GOAL_ERROR
        })
      })
  }
}

export function tryEditTrainingsGoal(token, userId, patientId, id, data) {
  return dispatch => {
    dispatch({
      type: Types.EDIT_TRAININGS_GOAL
    })

    API.editTrainingsGoal(token, userId, patientId, id, data)
      .then(response => {
        dispatch({
          type: Types.EDIT_TRAININGS_GOAL_SUCCESS,
          payload: { data: response.data, patientId, id }
        })
      })
      .catch(error => {
        dispatch({
          type: Types.EDIT_TRAININGS_GOAL_ERROR
        })
      })
  }
}
