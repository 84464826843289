import * as Types from '../../actions/Types'

const INITIAL_STATE = {
  providers: {
    fetched: false,
    data: {}
  },
  clinic: {
    fetched: false,
    data: {}
  }
}

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action

  switch (type) {
  case Types.GET_CLINIC_SUCCESS:
    return {
      ...state,
      clinic: {
        fetched: true,
        data: payload
      }
    }
  case Types.UPDATE_SELF_CLINIC_SUCCESS:
    return {
      ...state,
      clinic: {
        ...state.clinic,
        data: payload
      }
    }
  case Types.GET_PROVIDERS_SUCCESS:
    return {
      ...state,
      providers: {
        fetched: true,
        data: prepareProviders(payload)
      }
    }
  case Types.CREATE_PROVIDER_SUCCESS:
    return {
      ...state,
      providers: {
        ...state.providers,
        data: {
          ...state.providers.data,
          [payload.id]: {
            ...payload,
            numberOfPatients: 0
          }
        }
      }
    }
  case Types.SIGN_OUT_SUCCESS:
    return {
      ...state,
      ...INITIAL_STATE
    }
  default:
    return state
  }
}

function prepareProviders(providers) {
  const data = {}

  providers.forEach(p => {
    data[p.provider.id] = {
      ...p.provider,
      numberOfPatients: p.numberOfPatients
    }
  })

  return data
}
