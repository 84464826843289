import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Paper from '../../../common/Paper'
import Button from '../../../common/Button'

import * as providersActions from '../../../../actions/clinic/Providers'
import * as configActions from '../../../../actions/Config'
import * as Constants from '../../../../helpers/Constants'
import Inputs from '../../../common/Inputs'
import strings from '../../../../helpers/I18NStrings/index'
import { checkPassword, checkUsername } from '../../../../helpers/Functions'

class NewProvider extends React.Component {
  state = {
    username: '',
    password: '',
    repeatPassword: ''
  }

  componentWillReceiveProps(nextProps) {
    const { providers } = this.props
    
    if (
      !nextProps.providers.isCreating &&
      providers.isCreating &&
      nextProps.providers.errorCreatingStatus === null
    ) {
      this.setState({
        username: '',
        password: '',
        repeatPassword: ''
      })

      this.props.showAlert(
        strings.CaregiverCreatedHeader,
        strings.CaregiverCreated,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => {} }
      )
    } else if (!nextProps.providers.isCreating && providers.isCreating) {
      if (nextProps.providers.errorCreatingStatus === 409) {
        this.props.showAlert(
          strings.UsernameInUseHeader,
          strings.UsernameInUse,
          Constants.ONE_BUTTON,
          { title: strings.ButtonOK, onPress: () => {} }
        )
      } else {
        this.props.showAlert(
          strings.UnexpectedErrorHeader,
          strings.UnexpectedError,
          Constants.ONE_BUTTON,
          { title: strings.ButtonOK, onPress: () => {} }
        )
      }
    }
  }

  tryCreate() {
    const { user } = this.props
    const { username, password, repeatPassword } = this.state

    if (!checkUsername(username, this.props)) return
    if (!checkPassword(password, repeatPassword, this.props)) return

    const data = { username, password }

    this.props.tryCreateProviders(user.token, user.id, data)
  }

  render() {
    const { username, password, repeatPassword } = this.state
    const { classes, providers } = this.props

    return (
      <main className={classes.content}>
        <p class={'header'}>{strings.TextNewCaregiver}</p>
        <div className={classes.providerIdContainer}>
          <p class={'secondHeader'}>{strings.TextID}</p>
          <p
            class={'secondHeader'}
            style={{ color: '#F26363', marginLeft: 25 }}
          >
            {strings.TextAutoGen}
          </p>
        </div>

        <Paper style={{ alignItems: 'flex-start' }}>
          <div className={classes.innerContainer}>
            <p style={{ width: 150 }}>
              {strings.TextUsername}:
              <span className={'mandatoryStar'}>*</span>
            </p>
            <Inputs
              placeholder={strings.TextUsername}
              className={'noMargin'}
              onChange={event =>
                this.setState({ username: event.target.value })
              }
              value={username}
            />
          </div>
          <div className={classes.innerContainer}>
            <p style={{ width: 150 }}>
              {strings.TextPassword}:
              <span className={'mandatoryStar'}>*</span>
            </p>
            <Inputs
              type={'password'}
              placeholder={strings.TextPassword}
              className={'noMargin'}
              onChange={event =>
                this.setState({ password: event.target.value })
              }
              value={password}
            />
          </div>
          <div className={classes.innerContainer}>
            <p style={{ width: 150 }}>
              {strings.TextRepeatPassword}:
              <span className={'mandatoryStar'}>*</span>
            </p>
            <Inputs
              type={'password'}
              placeholder={strings.TextRepeatPassword}
              className={'noMargin'}
              onChange={event =>
                this.setState({
                  repeatPassword: event.target.value
                })
              }
              value={repeatPassword}
            />
          </div>
        </Paper>

        <div className={classes.buttonContainer}>
          <Button
            className={'success'}
            onClick={() => this.tryCreate()}
            loading={providers.isCreating}
            header={strings.ButtonCreate}
          />
        </div>
      </main>
    )
  }
}

const Styles = theme => ({
  innerContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
    paddingTop: 88
  },
  providerIdContainer: {
    display: 'flex'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

NewProvider.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps({ config, user, providers }) {
  return { config, user, providers }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...configActions, ...providersActions }, dispatch)
}

export default compose(
  withStyles(Styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NewProvider)
