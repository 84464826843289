import React, { Component } from "react";
import ReactDOM from "react-dom";

import * as Styles from "./styles";
class Inputs extends Component {
  constructor(props) {
    super(props);
    this.state = { hasFocus: false };
  }
  onFocus() {
    this.setState({ hasFocus: true });
  }
  onBlur() {
    this.setState({ hasFocus: false });
  }
  render() {
    const { type, placeholder, onChange, value, className, min='0', max='-1', style } = this.props;

    const h = { ...
      this.state.hasFocus
      ? Styles.default.focus
      : Styles.default.inputs,
      ...style
  }
  

    return (
      <input
        type={type}
        className={className}
        style={h}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        onFocus={this.onFocus.bind(this)}
        onBlur={this.onBlur.bind(this)}
        min = {min}
        max = {max}
      />
    );
  }
}

export default Inputs;
