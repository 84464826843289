import * as Types from '../Types'
import * as API from '../api/provider'

export function trySetPassword(token, userId, data) {
  return dispatch => {
    dispatch({
      type: Types.SET_PASSWORD
    })

    API.setPassword(token, userId, data)
      .then(response => {
        dispatch({
          type: Types.SET_PASSWORD_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: Types.SET_PASSWORD_ERROR,
          payload: error.response ? error.response.status : 500
        })
      })
  }
}
