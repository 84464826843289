import LocalizedStrings from "react-localization";
import SvCaregiverWeb from "../../../assets/documents/Manual-MedoClinic-vårdgivar-webb.pdf";
import SvFAQ from "../../../assets/documents/Vanliga-frågor-MedoClinic.pdf";
import EnFAQ from "../../../assets/documents/Frequently-asked-questions-MedoClinic.pdf";
import SvApp from "../../../assets/documents/Användarmanual-ver-0.4.pdf";
import EnApp from "../../../assets/documents/IFUapp-ver-0.8.pdf";

let PDFs = new LocalizedStrings({
  en: {
    App: EnApp,
    CaregiverWeb: SvCaregiverWeb,
    FAQ: EnFAQ
  },
  sv: {
    App: SvApp,
    CaregiverWeb: SvCaregiverWeb,
    FAQ: SvFAQ
  }
});

export default PDFs;
