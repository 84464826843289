import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Paper from '../../../common/Paper'
import Checkbox from '../../../common/Checkbox'
import RadioButton from '../../../common/RadioButton'
import Button from '../../../common/Button'
import GoBack from '../../../common/GoBack'

import * as patientsActions from '../../../../actions/Patients'
import * as configActions from '../../../../actions/Config'
import * as Constants from '../../../../helpers/Constants'
import Inputs from '../../../common/Inputs'
import strings from '../../../../helpers/I18NStrings/index'

const INITIAL_STATE = {
  gender: null,
  length: '',
  patientTypes: [],
  side: null,
  lengthError: false
}

class AddUser extends React.Component {
  constructor(props) {
    super(props)

    const backgroundForTroubles = {}
    const reasonsForProblems = {}
    const problemPlacements = {}

    Constants.BACKGROUND_FOR_TROUBLES.forEach(t => {
      backgroundForTroubles[t.id] = false
    })

    Constants.REASONS_FOR_PROBLEMS.forEach(t => {
      reasonsForProblems[t.id] = false
    })

    Constants.PROBLEM_PLACEMENTS.forEach(t => {
      problemPlacements[t.id] = false
    })

    this.state = {
      backgroundForTroubles,
      reasonsForProblems,
      problemPlacements,
      ...INITIAL_STATE
    }
  }

  componentWillReceiveProps(nextProps) {
    const { patients, history, user, match } = this.props

    if (
      !nextProps.patients.isCreating &&
      patients.isCreating &&
      nextProps.patients.errorCreatingStatus === null
    ) {
      const backgroundForTroubles = {}
      const reasonsForProblems = {}
      const problemPlacements = {}

      Constants.BACKGROUND_FOR_TROUBLES.forEach(t => {
        backgroundForTroubles[t.id] = false
      })

      Constants.REASONS_FOR_PROBLEMS.forEach(t => {
        reasonsForProblems[t.id] = false
      })

      Constants.PROBLEM_PLACEMENTS.forEach(t => {
        problemPlacements[t.id] = false
      })

      this.setState({
        backgroundForTroubles,
        reasonsForProblems,
        ...INITIAL_STATE
      })

      this.props.showAlert(
        strings.PatientCreatedHeader,
        strings.PatientCreated,
        Constants.TWO_BUTTONS_SUCCESS,
        {
          title: strings.TextPatientView,
          onPress: () =>
            history.push(
              user.type === Constants.CLINIC
                ? `/providers/${match.params.providerId}/patients/${
                nextProps.patients.createdPatient
                }/statistics`
                : `/patients/${nextProps.patients.createdPatient}/statistics`
            )
        },
        {
          title: strings.TextAdditional,
          onPress: () => { }
        }
      )
    } else if (!nextProps.patients.isCreating && patients.isCreating) {
      if (nextProps.patients.errorCreatingStatus === 415) {
        this.props.showAlert(
          strings.MaxPatientsHeader,
          strings.MaxPatients,
          Constants.ONE_BUTTON,
          { title: strings.ButtonOK, onPress: () => {} }
        )
      } else {
        this.props.showAlert(
          strings.UnexpectedErrorHeader,
          strings.UnexpectedError,
          Constants.ONE_BUTTON,
          { title: strings.ButtonOK, onPress: () => { } }
        )
      }
    }
  }

  hasTrue(object) {
    let has = false

    Object.keys(object).forEach(key => {
      if (object[key]) {
        has = true
      }
    })

    return has
  }

  tryCreate() {
    const {
      gender,
      length,
      patientTypes,
      problemPlacements,
      reasonsForProblems,
      backgroundForTroubles
    } = this.state

    if (
      gender === null ||
      length === '' ||
      patientTypes.length === 0 ||
      !this.hasTrue(backgroundForTroubles) ||
      !this.hasTrue(reasonsForProblems) ||
      !this.hasTrue(problemPlacements)
    ) {
      this.props.showAlert(
        strings.InfoMissingHeader,
        strings.InfoMissing,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => { } }
      )
    } else if (length < 63 || length > 251) {
      this.setState({ lengthError: true })

      this.props.showAlert(
        strings.NotValidLenghtHeader,
        strings.NotValidLenght,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => { } }
      )
    } else {
      this.create()
    }
  }

  create() {
    const { user, match, clinicData } = this.props
    const {
      gender,
      patientTypes,
      length,
      backgroundForTroubles,
      reasonsForProblems,
      problemPlacements
    } = this.state

    const goals = { minutesWalkGoal: null, limpIndexGoal: null }

    const data = {
      patientTypes,
      profile: {
        gender,
        length,
        ...backgroundForTroubles,
        ...reasonsForProblems,
        ...problemPlacements,
      },
      goals
    }

    let userId = user.id

    if (user.type === Constants.CLINIC) {
      userId = clinicData.providers.data[match.params.providerId].user.id
    }

    this.props.tryCreatePatient(user.token, userId, data)
  }

  renderPatientTypes() {
    const { patientTypes } = this.state

    return Constants.PATIENT_TYPES.map(type => {
      const checked = patientTypes.indexOf(type.id) !== -1

      return (
        <Checkbox
          key={type.id}
          label={type.value}
          style={{ width: 250 }}
          checked={checked}
          onChange={() => {
            if (checked) {
              this.setState({
                patientTypes: patientTypes.filter(id => id !== type.id)
              })
            } else {
              this.setState({ patientTypes: [...patientTypes, type.id] })
            }
          }}
        />
      )
    })
  }

  renderProblemPlacements() {
    const { problemPlacements } = this.state

    return Constants.PROBLEM_PLACEMENTS.map(placement => {
      const value = problemPlacements[placement.id]
      const checked = value ? value : false

      return (
        <Checkbox
          key={placement.id}
          label={placement.value}
          style={{ width: 250 }}
          checked={checked}
          onChange={() => {
            this.setState({
              problemPlacements: {
                ...problemPlacements,
                [placement.id]: !checked
              }
            })
          }}
        />
      )
    })
  }

  renderBackgrounds() {
    const { backgroundForTroubles } = this.state

    return Constants.BACKGROUND_FOR_TROUBLES.map(background => {
      const checked = backgroundForTroubles[background.id]

      return (
        <Checkbox
          key={background.id}
          label={background.value}
          style={{ width: 250 }}
          checked={checked}
          onChange={() =>
            this.setState({
              backgroundForTroubles: {
                ...backgroundForTroubles,
                [background.id]: !checked
              }
            })
          }
        />
      )
    })
  }

  renderReasonsForProblems() {
    const { reasonsForProblems } = this.state

    return Constants.REASONS_FOR_PROBLEMS.map(reason => {
      const checked = reasonsForProblems[reason.id]

      return (
        <Checkbox
          key={reason.id}
          label={reason.value}
          style={{ width: 250 }}
          checked={checked}
          onChange={() =>
            this.setState({
              reasonsForProblems: {
                ...reasonsForProblems,
                [reason.id]: !checked
              }
            })
          }
        />
      )
    })
  }

  render() {
    const { gender, length, lengthError } = this.state
    const { classes, patients, history, match } = this.props

    return (
      <main className={classes.content}>
        <GoBack
          history={history}
          show={match.params.providerId}
          title={strings.TextBackToClinicView}
        />
        <p className={'header'}>{strings.TextNewPatient}</p>
        <div className={classes.patientIdContainer}>
          <p className={'secondHeader'}>{strings.TextID}</p>
          <p
            className={'secondHeader'}
            style={{ color: '#F26363', marginLeft: 25 }}
          >
            {strings.TextAutoGen}
          </p>
        </div>

        <Paper style={{ alignItems: 'flex-start' }}>
          <div
            className={classes.innerContainer}
            style={{ alignItems: 'center' }}
          >
            <p style={{ width: 150 }}>
              {strings.TextGender}:
              <span className={'mandatoryStar'}>*</span>
            </p>
            <div className={classes.radioContainer}>
              <RadioButton
                label={strings.TextMan}
                checked={gender === Constants.MAN}
                onChange={() => this.setState({ gender: Constants.MAN })}
                style={{ width: 250 }}
              />
              <RadioButton
                label={strings.TextWoman}
                checked={gender === Constants.WOMAN}
                onChange={() => this.setState({ gender: Constants.WOMAN })}
                style={{ width: 250 }}
              />
              <RadioButton
                label={strings.TextOther}
                checked={gender === Constants.OTHER_GENDER}
                onChange={() =>
                  this.setState({ gender: Constants.OTHER_GENDER })
                }
                style={{ width: 250 }}
              />
            </div>
          </div>
          <div className={classes.innerContainer}>
            <p style={{ width: 150 }}>
              {strings.TextLenght}:
              <span className={'mandatoryStar'}>*</span>
            </p>
            <Inputs
              type={'number'}
              placeholder={strings.TextLenght}
              className={lengthError ? 'noMargin input-warning' : 'noMargin'}
              onChange={event =>
                this.setState({
                  length: event.target.value,
                  lengthError: false
                })
              }
              value={length}
              min={'63'}
              max={'251'}
            />
          </div>
        </Paper>

        <Paper style={{ alignItems: 'flex-start', marginTop: 24 }}>
          <div className={classes.innerContainer}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p style={{ width: 150 }}>
                {strings.TextPatientType}:
                <span className={'mandatoryStar'}>*</span>
              </p>
              <p className={'hint'} style={{ width: 150 }}>
                {strings.TextSelectOneOrMore}
              </p>
            </div>
            <div className={classes.patientTypesContainer}>
              {this.renderPatientTypes()}
            </div>
          </div>
        </Paper>

        <Paper style={{ alignItems: 'flex-start', marginTop: 24 }}>
          <div className={classes.innerContainer}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p style={{ width: 150 }}>
                {strings.TextBgToProblems}:
                <span className={'mandatoryStar'}>*</span>
              </p>
              <p className={'hint'} style={{ width: 150 }}>
                {strings.TextSelectOneOrMore}
              </p>
            </div>
            <div className={classes.patientTypesContainer}>
              {this.renderBackgrounds()}
            </div>
          </div>
        </Paper>

        <Paper style={{ alignItems: 'flex-start', marginTop: 24 }}>
          <div className={classes.innerContainer}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p style={{ width: 150 }}>
                {strings.TextCauseOfProblems}:
                <span className={'mandatoryStar'}>*</span>
              </p>
              <p className={'hint'} style={{ width: 150 }}>
                {strings.TextSelectOneOrMore}
              </p>
            </div>
            <div className={classes.patientTypesContainer}>
              {this.renderReasonsForProblems()}
            </div>
          </div>
        </Paper>

        <Paper style={{ alignItems: 'flex-start', marginTop: 24 }}>
          <div className={classes.innerContainer}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p style={{ width: 150 }}>
                {strings.TextWhereIsTheProblems}
                <span className={'mandatoryStar'}>*</span>
              </p>
            </div>
            <div className={classes.radioContainer}>
              {this.renderProblemPlacements()}
            </div>
          </div>
        </Paper>

        <div className={classes.buttonContainer}>
          <Button
            className={'success'}
            onClick={() => this.tryCreate()}
            loading={patients.isCreating}
            header={strings.ButtonCreate}
          />
        </div>
      </main>
    )
  }
}

const Styles = theme => ({
  innerContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  columnContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  radioContainer: {
    marginLeft: 100
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
    paddingTop: 88
  },
  patientIdContainer: {
    display: 'flex'
  },
  patientTypesContainer: {
    marginLeft: 100
  },
  textField: {
    marginLeft: 100
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

AddUser.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps({ config, user, patients, clinicData }) {
  return { config, user, patients, clinicData }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...configActions, ...patientsActions }, dispatch)
}

export default compose(
  withStyles(Styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AddUser)
