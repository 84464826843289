import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import product from '../../../helpers/Constants/product'
import * as Styles from './styles'

const FullscreenLoader = props => (
  <div style={Styles.default.container}>
    <CircularProgress style={{ color: product.mainColor }} thickness={2} size={82} />
  </div>
)

export default FullscreenLoader
