import strings from '../I18NStrings/index'
export const TOKEN_STORAGE = 'medoclinic_token_storage'
export const USER_STORAGE = 'medoclinic_user_id_storage'

export const ONE_BUTTON = 'one_button'
export const TWO_BUTTONS = 'two_buttons'
export const TWO_BUTTONS_SUCCESS = 'two_buttons_success'
export const THREE_BUTTONS = 'three_buttons'

// Provider pages
export const HOME_PAGE = 'home_page'
export const USER_STATISTICS_PAGE = 'user_statistics_page'
export const USER_SETTINGS_PAGE = 'user_settings_page'
export const ADD_USER_PAGE = 'add_user_page'
export const IMPORT_USER_PAGE = 'import_user_page'
export const USER_GOALS_PAGE = 'user_goals_page'

// Patient pages
export const MY_PAGE = 'my_page'
export const MY_GOALS = 'my_goals'

// Clinic pages
export const CLINIC_HOME_PAGE = 'clinic_home_page'
export const MY_PROVIDERS = 'my_providers'
export const NEW_PROVIDER = 'new_provider'
export const CLINIC_SETTINGS = 'clinic_settings'

// Medotemic pages
export const CLINICS = 'clinics'
export const NEW_CLINIC = 'new_clinic'

export const INFO_PAGE = 'info_page'

export const REGEX_USERNAME_IS_CORRECT = /[a-zA-Z0-9-]+$/

// Patient types
export const PATIENT_TYPES = [
  { id: 'arthritisPatient', value: strings.TextOsteoarthritisPatient },
  { id: 'arthritisSchool', value: strings.TextOsteoarthritisSchool },
  { id: 'strokePatient', value: strings.TextStrokePatient },
  { id: 'implantSurgery', value: strings.TextImplantPatients },
  { id: 'parcelDamaged', value: strings.TextACLInjury },
  { id: 'footFractures', value: strings.TextFootFractures },
  { id: 'secondaryPrevention', value: strings.TextSecondaryPrevention },
  { id: 'backProblems', value: strings.TextBackpain },
  { id: 'neurologicalDamage', value: strings.TextNeurologicalDamage },
  { id: 'prosthesis', value: strings.TextProsthesis },
  { id: 'cerebralPares', value: strings.TextCerebralPalsy },
  { id: 'pelvic', value: strings.TextSPD },
  { id: 'osteoporos', value: strings.TextOsteoporosis },
  { id: 'other', value: strings.TextOtherType }
]

// Reasons for problems
export const BACKGROUND_FOR_TROUBLES = [
  { id: 'backProblems', value: strings.TextBackProblems },
  { id: 'hippProblems', value: strings.TextHipProblems },
  { id: 'kneeProblems', value: strings.TextKneeProblems },
  { id: 'footProblems', value: strings.TextFootProblems },
  { id: 'ankleProblems', value: strings.TextAnkleProblems },
  { id: 'otherProblems', value: strings.TextOtherReason }
]

// Reasons for problems
export const REASONS_FOR_PROBLEMS = [
  { id: 'jointProblems', value: strings.TextJointProblems },
  { id: 'muscleDisorders', value: strings.TextMuscleDisorders },
  { id: 'neurologicalDisorders', value: strings.TextNeurologicalDisorders },
  { id: 'ulceration', value: strings.TextUlceration },
  { id: 'implants', value: strings.TextNewHipOrKneeJoint },
  { id: 'arthritis', value: strings.TextACLInjury },
  { id: 'surgery', value: strings.TextNewlyOperated },
  { id: 'skeletalDamage', value: strings.TextSkeletalInjury },
  { id: 'otherReasons', value: strings.TextOtherReasons }
]

export const PROBLEM_PLACEMENTS = [
  { id: 'problemsLeft', value: strings.TextToTheLeft },
  { id: 'problemsMiddle', value: strings.TextInTheMiddle },
  { id: 'problemsRight', value: strings.TextToTheRight },
  { id: 'problemsUndefined', value: strings.TextUndefined },
]

// Genders
export const MAN = 'M'
export const WOMAN = 'W'
export const OTHER_GENDER = 'O'

// Sides
export const LEFT = 'L'
export const MIDDLE = 'M'
export const RIGHT = 'R'
export const DO_NOT_KNOW = ''

// User types
export const PATIENT = 'PATIENT'
export const HEALTHCARE_PROVIDER = 'HEALTHCARE_PROVIDER'
export const CLINIC = 'CLINIC'
export const MEDOTEMIC = 'MEDOTEMIC'

// Goals
export const DAY = 'DAY'
export const WEEK = 'WEEK'
export const MONTH = 'MONTH'

export const LIMP_INDEX_OUT_OF_BOUNCE = 'limp_index_out_of_bounce'
export const LIMP_INDEX_DATE_OUT_OF_BOUNCE = 'limp_index_date_out_of_bounce'
export const GOAL_OK = 'goal_ok'

// Filter user statistics
export const ALL_TRAININGS = 'all_trainings'
export const DAYS_90 = 'days_90'
export const DAYS_30 = 'days_30'
export const DAYS_7 = 'days_7'
export const SELECT_INTERVAL = 'select_interval'
