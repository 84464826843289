import * as Types from '../actions/Types'

const INITIAL_STATE = {
  isLoading: false,
  error: null
}

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action

  switch (type) {
    case Types.CREATE_TRAININGS_GOAL:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case Types.CREATE_TRAININGS_GOAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null
      }
    case Types.CREATE_TRAININGS_GOAL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: 500
      }
    case Types.CREATE_MINUTE_GOAL:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case Types.CREATE_MINUTE_GOAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null
      }
    case Types.CREATE_MINUTE_GOAL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: 500
      }
    case Types.CREATE_LIMP_GOAL:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case Types.CREATE_LIMP_GOAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null
      }
    case Types.CREATE_LIMP_GOAL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: 500
      }
    case Types.EDIT_TRAININGS_GOAL:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case Types.EDIT_TRAININGS_GOAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null
      }
    case Types.EDIT_TRAININGS_GOAL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: 500
      }
    case Types.EDIT_MINUTE_GOAL:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case Types.EDIT_MINUTE_GOAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null
      }
    case Types.EDIT_MINUTE_GOAL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: 500
      }
    case Types.EDIT_LIMP_GOAL:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case Types.EDIT_LIMP_GOAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null
      }
    case Types.EDIT_LIMP_GOAL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: 500
      }
    default:
      return state
  }
}
