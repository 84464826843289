export const SET_PAGE = 'set_page'

export const SHOW_BLUR_ALERT = 'show_blur_alert'
export const HIDE_BLUR_ALERT = 'hide_blur_alert'

export const SHOW_ALERT = 'show_alert'
export const HIDE_ALERT = 'hide_alert'

export const GET_TERMS_OF_USE = 'get_terms_of_use'
export const GET_TERMS_OF_USE_SUCCESS = 'get_terms_of_use_success'
export const GET_TERMS_OF_USE_FAILED = 'get_terms_of_use_failed'

// User actions
export const SIGN_IN = 'sign_in'
export const SIGN_IN_SUCCESS = 'sign_in_success'
export const SIGN_IN_SUCCESS_PATIENT = 'sign_in_success_patient'
export const SIGN_IN_SUCCESS_CLINIC = 'sign_in_success_clinic'
export const SIGN_IN_ERROR = 'sign_in_error'

export const VALIDATE_TOKEN = 'validate_token'
export const VALIDATE_TOKEN_SUCCESS = 'validate_token_success'
export const VALIDATE_TOKEN_SUCCESS_PATIENT = 'validate_token_success_patient'
export const VALIDATE_TOKEN_SUCCESS_CLINIC = 'validate_token_success_clinic'
export const VALIDATE_TOKEN_SUCCESS_MEDOTEMIC =
  'validate_token_success_medotemic'
export const VALIDATE_TOKEN_ERROR = 'validate_token_error'

export const SIGN_OUT_SUCCESS = 'sign_out_success'

// Patient actions
export const GET_PATIETNS = 'get_patients'
export const GET_PATIETNS_SUCCESS = 'get_patients_success'
export const GET_PATIETNS_ERROR = 'get_patients_error'

export const CREATE_PATIENT = 'crete_patient'
export const CREATE_PATIENT_SUCCESS = 'crete_patient_success'
export const CREATE_PATIENT_ERROR = 'crete_patient_error'

export const IMPORT_PATIENT = 'import_patient'
export const IMPORT_PATIENT_SUCCESS = 'import_patient_success'
export const IMPORT_PATIENT_ERROR = 'import_patient_error'

export const UPDATE_PATIENT = 'update_patient'
export const UPDATE_PATIENT_SUCCESS = 'update_patient_success'
export const UPDATE_PATIENT_ERROR = 'update_patient_error'

export const UPDATE_PATIENT_PASSWORD = 'update_patient_password'
export const UPDATE_PATIENT_PASSWORD_SUCCESS = 'update_patient_success_password'
export const UPDATE_PATIENT_PASSWORD_ERROR = 'update_patient_error_password'

export const UPDATE_PATIENT_USERNAME = 'update_patient_username'
export const UPDATE_PATIENT_USERNAME_SUCCESS = 'update_patient_success_username'
export const UPDATE_PATIENT_USERNAME_ERROR = 'update_patient_error_username'

export const DELETE_PATIENTS = 'delete_patients'
export const DELETE_PATIENTS_SUCCESS = 'delete_patients_success'
export const DELETE_PATIENTS_ERROR = 'delete_patients_error'

export const GET_DETAILED_PATIENT = 'get_detailed_patient'
export const GET_DETAILED_PATIENT_SUCCESS = 'get_detailed_patient_success'
export const GET_DETAILED_PATIENT_ERROR = 'get_detailed_patient_error'

export const SELECT_PATIENT = 'select_patient'

// Goals
export const CREATE_GOALS = 'create_goals'
export const CREATE_GOALS_SUCCESS = 'create_goals_success'
export const CREATE_GOALS_ERROR = 'create_goals_error'

// Patient user actions
export const GET_PATIENT = 'get_my_trainings'
export const GET_PATIENT_SUCCESS = 'get_my_trainings_success'
export const GET_PATIENT_ERROR = 'get_my_trainings_error'

// Clinic actions
export const GET_PROVIDERS = 'get_providers'
export const GET_PROVIDERS_SUCCESS = 'get_providers_success'
export const GET_PROVIDERS_ERROR = 'get_providers_error'

export const CREATE_PROVIDER = 'create_provider'
export const CREATE_PROVIDER_SUCCESS = 'create_provider_success'
export const CREATE_PROVIDER_ERROR = 'create_provider_error'

// Patients Page Config actions
export const PATIENTS_PAGE_SET_FILTER = 'patients_page_set_filter'
export const PATIENTS_PAGE_SHOW_FILTER = 'patients_page_show_filter'
export const PATIENTS_PAGE_SET_SEARCH_INPUT = 'patients_page_set_search_input'
export const PATIENTS_PAGE_SHOW_ONLY_ACTIVATED =
  'patients_page_show_only_activated'
export const PATIENTS_PAGE_SET_PATIENT_STATUS_PERIOD =
  'patients_page_set_patient_status_period'
export const PATIENTS_PAGE_SET_SORT_ORDER = 'patients_page_set_sort_order'
export const PATIENTS_PAGE_SET_PAGINATION_PAGE =
  'patients_page_set_pagination_page'
export const PATIENTS_PAGE_SET_PAGINATION_ROWS_PER_PAGE =
  'patients_page_set_pagination_rows_per_page'

// User Statistics Page Config actions
export const USER_STATISTICS_PAGE_SHOW_TABLES =
  'user_statistics_page_show_tables'
export const USER_STATISTICS_PAGE_SHOW_LIMP_GRAPHS =
  'user_statistics_page_limp_graphs'
  export const USER_STATISTICS_PAGE_SHOW_NUMBER_OF_TRAININGS_GRAPHS =
    'user_statistics_page_number_of_trainings_graphs'
export const USER_STATISTICS_PAGE_SHOW_STEP_GRAPHS =
  'user_statistics_page_step_graphs'
export const USER_STATISTICS_PAGE_SHOW_ACTIVE_TIME_GRAPHS =
  'user_statistics_page_active_time_graphs'
export const USER_STATISTICS_PAGE_SHOW_KADENS_GRAPHS =
  'user_statistics_page_kadens_graphs'
export const USER_STATISTICS_PAGE_SET_SORT_ORDER =
  'user_statistics_page_set_sort_order'
export const USER_STATISTICS_PAGE_SET_PAGINATION_PAGE =
  'user_statistics_page_set_pagination_page'
export const USER_STATISTICS_PAGE_SET_PAGINATION_ROWS_PER_PAGE =
  'user_statistics_page_set_pagination_rows_per_page'
export const USER_STATISTICS_SET_COMPARE_TRAININGS =
  'user_statistics_set_compare_trainings'
export const USER_STATISTICS_SET_TRAINING_FILTER =
  'user_statistics_set_training_filter'
export const USER_STATISTICS_SET_FROM_FILTER = 'user_statistics_set_from_filter'
export const USER_STATISTICS_SET_TO_FILTER = 'user_statistics_set_to_filter'

// Goals
export const DELETE_MINUTE_GOAL = 'delete_minute_goal'
export const DELETE_MINUTE_GOAL_SUCCESS = 'delete_minute_goal_success'
export const DELETE_MINUTE_GOAL_ERROR = 'delete_minute_goal_error'

export const DELETE_LIMP_GOAL = 'delete_limp_goal'
export const DELETE_LIMP_GOAL_SUCCESS = 'delete_limp_goal_success'
export const DELETE_LIMP_GOAL_ERROR = 'delete_limp_goal_error'

export const DELETE_TRAININGS_GOAL = 'delete_trainings_goal'
export const DELETE_TRAININGS_GOAL_SUCCESS = 'delete_trainings_goal_success'
export const DELETE_TRAININGS_GOAL_ERROR = 'delete_trainings_goal_error'

export const CREATE_MINUTE_GOAL = 'create_minute_goal'
export const CREATE_MINUTE_GOAL_SUCCESS = 'create_minute_goal_success'
export const CREATE_MINUTE_GOAL_ERROR = 'create_minute_goal_error'

export const CREATE_LIMP_GOAL = 'create_limp_goal'
export const CREATE_LIMP_GOAL_SUCCESS = 'create_limp_goal_success'
export const CREATE_LIMP_GOAL_ERROR = 'create_limp_goal_error'

export const CREATE_TRAININGS_GOAL = 'create_trainings_goal'
export const CREATE_TRAININGS_GOAL_SUCCESS = 'create_trainings_goal_success'
export const CREATE_TRAININGS_GOAL_ERROR = 'create_trainings_goal_error'

export const EDIT_MINUTE_GOAL = 'edit_minute_goal'
export const EDIT_MINUTE_GOAL_SUCCESS = 'edit_minute_goal_success'
export const EDIT_MINUTE_GOAL_ERROR = 'edit_minute_goal_error'

export const EDIT_LIMP_GOAL = 'edit_limp_goal'
export const EDIT_LIMP_GOAL_SUCCESS = 'edit_limp_goal_success'
export const EDIT_LIMP_GOAL_ERROR = 'edit_limp_goal_error'

export const EDIT_TRAININGS_GOAL = 'edit_trainings_goal'
export const EDIT_TRAININGS_GOAL_SUCCESS = 'edit_trainings_goal_success'
export const EDIT_TRAININGS_GOAL_ERROR = 'edit_trainings_goal_error'

// Training types
export const UPDATE_TRAINING_TYPES = 'update_training_types'
export const UPDATE_TRAINING_TYPES_SUCCESS = 'update_training_types_success'
export const UPDATE_TRAINING_TYPES_ERROR = 'update_training_types_error'

// Clinic types
export const GET_CLINICS = 'get_clinics'
export const GET_CLINICS_SUCCESS = 'get_clinics_success'
export const GET_CLINICS_ERROR = 'get_clinics_error'

export const CREATE_CLINIC = 'create_clinic'
export const CREATE_CLINIC_SUCCESS = 'create_clinic_success'
export const CREATE_CLINIC_ERROR = 'create_clinic_error'

export const UPDATE_CLINIC = 'update_clinic'
export const UPDATE_CLINIC_SUCCESS = 'update_clinic_success'
export const UPDATE_CLINIC_ERROR = 'update_clinic_error'

export const UPDATE_CLINIC_USERNAME = 'update_clinic_username'
export const UPDATE_CLINIC_USERNAME_SUCCESS = 'update_clinic_username_success'
export const UPDATE_CLINIC_USERNAME_ERROR = 'update_clinic_username_error'

export const UPDATE_CLINIC_PASSWORD = 'update_clinic_password'
export const UPDATE_CLINIC_PASSWORD_SUCCESS = 'update_clinic_password_success'
export const UPDATE_CLINIC_PASSWORD_ERROR = 'update_clinic_password_error'

export const UPDATE_LICENSE = 'update_license'
export const UPDATE_LICENSE_SUCCESS = 'update_license_success'
export const UPDATE_LICENSE_ERROR = 'update_license_error'

export const CHANGE_PROVIDER_PASSWORD = 'change_provider_password'
export const CHANGE_PROVIDER_PASSWORD_SUCCESS = 'change_provider_password_success'
export const CHANGE_PROVIDER_PASSWORD_ERROR = 'change_provider_password_error'

export const CHANGE_PROVIDER_USERNAME = 'change_provider_username'
export const CHANGE_PROVIDER_USERNAME_SUCCESS = 'change_provider_username_success'
export const CHANGE_PROVIDER_USERNAME_ERROR = 'change_provider_username_error'

export const CREATE_KADENS_GOAL = 'create_kadens_goal'
export const CREATE_KADENS_GOAL_SUCCESS = 'create_kadens_goal_success'
export const CREATE_KADENS_GOAL_ERROR = 'create_kadens_goal_error'

export const DELETE_KADENS_GOAL = 'delete_kadens_goal'
export const DELETE_KADENS_GOAL_SUCCESS = 'delete_kadens_goal_success'
export const DELETE_KADENS_GOAL_ERROR = 'delete_kadens_goal_error'

export const EDIT_KADENS_GOAL = 'edit_kadens_goal'
export const EDIT_KADENS_GOAL_SUCCESS = 'edit_kadens_goal_success'
export const EDIT_KADENS_GOAL_ERROR = 'edit_kadens_goal_error'

export const GET_CLINIC = 'get_clinic'
export const GET_CLINIC_SUCCESS = 'get_clinic_success'
export const GET_CLINIC_ERROR = 'get_clinic_error'

export const UPDATE_SELF_CLINIC = 'update_clinic'
export const UPDATE_SELF_CLINIC_SUCCESS = 'update_clinic_success'
export const UPDATE_SELF_CLINIC_ERROR = 'update_clinic_error'

export const SET_PASSWORD = 'set_password'
export const SET_PASSWORD_SUCCESS = 'set_password_success'
export const SET_PASSWORD_ERROR = 'set_password_error'

// Training types
export const DELETE_TRAININGS = 'delete_trainings'
export const DELETE_TRAININGS_SUCCESS = 'delete_trainings_success'
export const DELETE_TRAININGS_ERROR = 'delete_trainings_error'
