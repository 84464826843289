import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronDown,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons'
import CircularProgress from '@material-ui/core/CircularProgress'
import MatButton from '@material-ui/core/Button'

import TableHead from '../../../common/TableHead'
import PatientTypeItems from '../../../common/PatientTypeItems'
import Checkbox from '../../../common/Checkbox'
import GoBack from '../../../common/GoBack'
import Button from '../../../common/Button'
import Modal from '../../../common/Modal'

import * as Constants from '../../../../helpers/Constants'
import * as patientsActions from '../../../../actions/Patients'
import * as clinicPatientsActions from '../../../../actions/clinic/Patients'
import * as configActions from '../../../../actions/Config'
import * as patientsPageActions from '../../../../actions/PatientsPage'
import * as providerActions from '../../../../actions/provider/Provider'
import * as Styles from './styles'
import * as Functions from '../../../../helpers/Functions'
import product from '../../../../helpers/Constants/product'
import Inputs from '../../../common/Inputs'
import strings from '../../../../helpers/I18NStrings/index'

const ROWS = [
  {
    id: 'selections',
    label: '',
    numeric: false,
    sortType: 'string',
    sort: false
  },
  {
    id: 'id',
    label: strings.TextPatientId,
    numeric: false,
    sortType: 'string',
    sort: true
  },
  {
    id: 'status',
    label: strings.TextTargetStatus,
    numeric: false,
    sortType: '',
    sort: false
  },
  {
    id: 'creationCode',
    label: strings.TextActivationCode,
    numeric: false,
    sortType: '',
    sort: false
  },
  {
    id: 'patientType',
    label: strings.TextPatientType,
    numeric: false,
    sortType: '',
    sort: false
  }
]

class Patients extends React.Component {
  state = {
    selectedPatients: [],
    password: '',
    repeatPassword: ''
  }

  componentDidMount() {
    const { user, data, match } = this.props

    if (match.params.providerId) {
      this.props.tryGetProviderPatients(
        user.token,
        user.id,
        match.params.providerId
      )
    } else if (!data.patients.fetched) {
      this.props.tryGetPatients(user.token, user.id)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { patients } = this.props

    if (
      !nextProps.patients.isDeleting &&
      patients.isDeleting &&
      nextProps.patients.errorDeletingStatus === null
    ) {
      this.setState({ selectedPatients: [] })
    } else if (!nextProps.patients.isDeleting && patients.isDeleting) {
      this.props.showAlert(
        strings.UnexpectedErrorHeader,
        strings.UnexpectedError,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => { } }
      )
    }
  }

  setPassword() {
    const { password, repeatPassword } = this.state
    const { user } = this.props

    if (!password || !repeatPassword) {
      this.props.showAlert(
        strings.InfoMissingHeader,
        strings.InfoMissing,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => { } }
      )
    } else if (password !== repeatPassword) {
      this.props.showAlert(
        strings.PasswordsNoMatchHeader,
        strings.PasswordsNoMatch,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => { } }
      )
    } else {
      const data = { password }

      this.props.trySetPassword(user.token, user.id, data)
    }
  }

  getPatients() {
    const { data } = this.props
    const patients = []

    Object.keys(data.patients.data).forEach(key => {
      patients.push(data.patients.data[key])
    })

    return patients
  }

  desc(a, b, orderBy, sortType) {
    if (b[orderBy] < a[orderBy]) {
      return 1
    } else if (b[orderBy] > a[orderBy]) {
      return -1
    }

    return 0
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index])

    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0])

      if (order !== 0) {
        return order
      }

      return a[1] - b[1]
    })

    return stabilizedThis.map(el => el[0])
  }

  getSorting(order, orderBy, sortType) {
    return order === 'desc'
      ? (a, b) => this.desc(a, b, orderBy, sortType)
      : (a, b) => -this.desc(a, b, orderBy, sortType)
  }

  filterTypes(patient) {
    const { config } = this.props

    if (Constants.PATIENT_TYPES.length === config.patientPage.filter.length) {
      return true
    }

    for (let i = 0; i < patient.types.length; i++) {
      if (config.patientPage.filter.indexOf(patient.types[i]) !== -1) {
        return true
      }
    }

    return false
  }

  filterId(patient) {
    const { config } = this.props

    return (
      patient.id
        .toString()
        .toLowerCase()
        .indexOf(config.patientPage.searchInput.toLowerCase()) !== -1
    )
  }

  navigateToProfile(patient) {
    const { history, user, match } = this.props

    history.push(
      user.type === Constants.CLINIC
        ? `/providers/${match.params.providerId}/patients/${
        patient.id
        }/statistics`
        : `/patients/${patient.id}/statistics`
    )
  }

  calculateGoal(value, days) {
    return (value / 7) * days
  }

  getActualResult(trainings, days) {
    const result = {
      steps: 0,
      minutes: 0,
      limpIndex: 0,
      limpIsZero: false,
      trainings: [],
    }

    // Calculate deadLine = current date - days (works for negative number results)
    let deadline = new Date()
    deadline = deadline.setDate(deadline.getDate() - days)

    // Process all trainings within the time frame and with result >= 0
    const trainingFiltered = trainings
      .filter(t => {
        if (!t.time || t.walkingOn === 'terrain' || t.walkingOn === 'other') return false

        const date = new Date(t.time.split(' ')[0])

        return t.result >= 0 && deadline - date.getTime() <= 0
      })

    trainingFiltered.forEach(t => {
      // console.log(t)
      result.steps += t.steps
      result.minutes += t.activeTime
      result.limpIndex = (result.limpIndex + t.result)
      result.limpIsZero = true
    })

    result.trainings = trainingFiltered

    return result
  }

  calculatePatientStatus(days, patient, actualResults) {
    const {
      minutesWalkGoal,
      limpIndexGoal,
      trainingsGoal
    } = Functions.getActiveGoals(patient.goals)
    let numberOfGoals = 0
    let totalGoalValue = 0

    if (minutesWalkGoal) {
      const { minutes } = minutesWalkGoal

      numberOfGoals++

      if (actualResults.minutes !== 0) {
        const goal = this.calculateGoal(minutes, days)
        const result = actualResults.minutes / 60 / goal

        if (result >= 1) {
          totalGoalValue += 1
        } else if (result >= 0.5) {
          totalGoalValue += 0.5
        }
      }
    }

    if (
      limpIndexGoal &&
      this.limpIndexGoalWithinBoundaries(days, limpIndexGoal)
    ) {
      numberOfGoals++

      if (actualResults.limpIndex === 0 && actualResults.limpIsZero) {
        // If no limping in the period - goal is reached
        totalGoalValue += 1
      } else if (actualResults.limpIndex !== 0) {
        // startDate is the start date for the goal
        // endDate is the end date for the goal
        const startDate = new Date(limpIndexGoal.startDate)
        const endDate = new Date(limpIndexGoal.endDate)
        const totalGoalDays =
          (endDate.getTime() - startDate.getTime()) / 86400000

        // k is how much the goal is increasing per day (in the interval given by startDate and endDate)
        // Most likely k will be negative whcih de facto means the goal is decreasing with time
        const k =
          (limpIndexGoal.endIndex - limpIndexGoal.startIndex) / totalGoalDays

        let procent = 0

        actualResults.trainings.forEach(t => {
          const date = new Date(t.time)
          if (date < endDate) {
            const y = limpIndexGoal.startIndex + k * ((date - startDate) / 86400000)
            if (t.result > y) {
              procent += (t.result - y) / y
            }
          } else {
            if (t.result > limpIndexGoal.endIndex) {
              procent += (t.result - limpIndexGoal.endIndex) / limpIndexGoal.endIndex
            }
          }
        })

        if (actualResults.trainings.length > 0)
          procent = procent / actualResults.trainings.length

        if (procent <= 0) {
          totalGoalValue += 1
        } else if (procent <= 0.15) {
          totalGoalValue += 0.5
        }
      }
    }

    if (
      trainingsGoal
    ) {
      const { numberOfTrainings } = trainingsGoal
      numberOfGoals++

      if (actualResults.trainings.length !== 0) {
        const goal = this.calculateGoal(numberOfTrainings, days)
        const result = actualResults.trainings.length / goal

        if (result >= 1) {
          totalGoalValue += 1
        } else if (result >= 1.0 - 0.34) {
          totalGoalValue += 0.5
        }
      }
    }

    if (totalGoalValue === 0) {
      return 'patientStatusDanger'
    }

    const mean = totalGoalValue / numberOfGoals

    if (mean >= 1) {
      return 'patientStatusSuccess'
    } else if (mean < 1 && mean > 0.33) {
      return 'patientStatusWarning'
    }

    return 'patientStatusDanger'
  }

  limpIndexGoalWithinBoundaries(days, limpIndexGoal) {
    const endDateDeadline = new Date()
    const startDateDeadline = new Date()
    startDateDeadline.setDate(startDateDeadline.getDate() - days)

    return (
      startDateDeadline >= new Date(limpIndexGoal.startDate).getTime() &&
      endDateDeadline <= new Date(limpIndexGoal.endDate).getTime()
    )
  }

  deletePatients() {
    const { user } = this.props
    const { selectedPatients } = this.state
    const patients = selectedPatients.length

    this.props.showAlert(
      patients === 1
        ? strings.RemovePatientHeader
        : strings.RemovePatientsHeader,
      patients === 1
        ? strings.RemovePatient
        : `${strings.RemovePatientsPt1} ${patients} ${
        strings.RemovePatientsPt2
        }`,
      Constants.TWO_BUTTONS,
      {
        title: strings.ButtonNo,
        onPress: () => { }
      },
      {
        title: strings.ButtonYes,
        onPress: () =>
          this.props.tryDeletePatients(user.token, user.id, selectedPatients)
      }
    )
  }

  getPatientStatus(patient) {
    const { config } = this.props
    const {
      minutesWalkGoal,
      limpIndexGoal
    } = Functions.getActiveGoals(patient.goals)

    if (
      !patient.user ||
      (!minutesWalkGoal && !limpIndexGoal) ||
      patient.trainings.length === 0
    ) {
      return (
        <div
          style={{
            height: 16,
            width: 16,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <p style={{ margin: 0 }}>-</p>
        </div>
      )
    }

    let patientStatus = 'patientStatusSuccess'

    switch (config.patientPage.calculatePatientStatusPerPeriod) {
      case Constants.DAY:
        patientStatus = this.calculatePatientStatus(
          1,
          patient,
          this.getActualResult(patient.trainings, 1)
        )
        break
      case Constants.WEEK:
        patientStatus = this.calculatePatientStatus(
          7,
          patient,
          this.getActualResult(patient.trainings, 7)
        )
        break
      case Constants.MONTH:
        patientStatus = this.calculatePatientStatus(
          30,
          patient,
          this.getActualResult(patient.trainings, 30)
        )
        break
      default:
        patientStatus = 'patientStatusSuccess'
    }

    return <div className={patientStatus} />
  }

  renderPatientGroups() {
    const { config } = this.props

    return Constants.PATIENT_TYPES.map(disease => {
      const checked = config.patientPage.filter.indexOf(disease.id) !== -1

      return (
        <Checkbox
          label={disease.value}
          style={{ width: 250 }}
          checked={checked}
          onChange={() => {
            if (checked) {
              this.props.setFilter(
                config.patientPage.filter.filter(id => id !== disease.id)
              )
            } else {
              this.props.setFilter([...config.patientPage.filter, disease.id])
            }
          }}
        />
      )
    })
  }

  render() {
    const { classes, data, history, match, config, user } = this.props
    const { selectedPatients, password, repeatPassword } = this.state

    if (!data.patients.fetched) {
      return (
        <main style={Styles.default.loaderContainer}>
          <GoBack
            history={history}
            show={match.params.providerId}
            title={strings.TextBackToClinicView}
          />
          <CircularProgress
            style={{ color: product.mainColor }}
            thickness={2}
            size={82}
          />
        </main>
      )
    }

    const startIndex = config.patientPage.page * config.patientPage.rowsPerPage
    const patients = this.getPatients()
      .filter(patient => this.filterTypes(patient))
      .filter(patient => this.filterId(patient))
      .filter(patient =>
        patient.user ? true : false || !config.patientPage.showOnlyActivated
      )

    return (
      <main className={classes.content}>
        <GoBack
          history={history}
          show={match.params.providerId}
          title={strings.TextBackToClinicView}
        />
        <Paper className={classes.root}>
          <div>
            <div style={Styles.default.container}>
              <p className={'boxHeader'}>{strings.TextPatients}</p>
              <div style={Styles.default.innerContainer}>
                <Inputs
                  placeholder={strings.TextSearch}
                  style={{ marginLeft: 0 }}
                  onChange={event =>
                    this.props.setSearchInput(event.target.value)
                  }
                  value={config.patientPage.searchInput}
                />
                <a
                  onClick={() =>
                    this.props.showFilter(!config.patientPage.showFilter)
                  }
                  style={Styles.default.filter}
                >
                  {config.patientPage.showFilter
                    ? strings.TextHideFilter
                    : strings.TextShowFilter}
                  <FontAwesomeIcon
                    icon={
                      config.patientPage.showFilter
                        ? faChevronDown
                        : faChevronRight
                    }
                    style={{ marginLeft: 10 }}
                  />
                </a>
              </div>
            </div>
            {config.patientPage.showFilter ? (
              <div>
                <div style={Styles.default.filterContainer}>
                  <Checkbox
                    label={strings.TextOnlyActivated}
                    checked={config.patientPage.showOnlyActivated}
                    onChange={() =>
                      this.props.showOnlyActivated(
                        !config.patientPage.showOnlyActivated
                      )
                    }
                  />
                </div>
                <a
                  style={{ marginLeft: 24 }}
                  onClick={
                    config.patientPage.filter.length !== 0
                      ? () => this.props.setFilter([])
                      : () =>
                        this.props.setFilter(
                          Constants.PATIENT_TYPES.map(disease => disease.id)
                        )
                  }
                >
                  {config.patientPage.filter.length !== 0
                    ? strings.TextUnmarkAllPatientTypes
                    : strings.TextMarkAllPatientTypes}
                </a>
                <div style={Styles.default.filterContainer}>
                  {this.renderPatientGroups()}
                </div>
              </div>
            ) : null}
            <div style={Styles.default.filterContainer}>
              <p> {strings.TextCalculatePatient} </p>
              <select
                value={config.patientPage.calculatePatientStatusPerPeriod}
                onChange={event =>
                  this.props.setPatientStatusPeriod(event.target.value)
                }
              >
                <option value={Constants.DAY}>{strings.TextDay}</option>
                <option value={Constants.WEEK}>{strings.TextWeek}</option>
                <option value={Constants.MONTH}>{strings.TextMonth}</option>
              </select>
            </div>
          </div>

          <div className={classes.innerRoot}>
            <p className={'smallText'}>
              {`${strings.TextPage} ${1 + config.patientPage.page} ${strings.TextOf} ${Math.ceil(
                patients.length / config.patientPage.rowsPerPage
              )}`}
            </p>
            <Table className={classes.table} aria-labelledby={'tableTitle'}>
              <TableHead
                rows={ROWS}
                order={config.patientPage.order}
                orderBy={config.patientPage.orderBy}
                sortOn={(newOrderBy, newOrder, newSortType) =>
                  this.props.setSortOrder(newOrderBy, newOrder, newSortType)
                }
              />

              <TableBody>
                {this.stableSort(
                  patients,
                  this.getSorting(
                    config.patientPage.order,
                    config.patientPage.orderBy,
                    config.patientPage.sortType
                  )
                )
                  .slice(
                    startIndex,
                    config.patientPage.rowsPerPage + startIndex
                  )
                  .map(patient => {
                    return (
                      <TableRow key={patient.id} className={classes.row}>
                        <TableCell>
                          <Checkbox
                            checked={
                              selectedPatients.indexOf(patient.id) !== -1
                            }
                            onChange={() => {
                              if (selectedPatients.indexOf(patient.id) === -1) {
                                this.setState({
                                  selectedPatients: [
                                    ...selectedPatients,
                                    patient.id
                                  ]
                                })
                              } else {
                                this.setState({
                                  selectedPatients: selectedPatients.filter(
                                    id => id !== patient.id
                                  )
                                })
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell
                          onClick={() => this.navigateToProfile(patient)}
                          className={'hover'}
                        >
                          <p style={{ fontSize: 16 }}>{patient.id}</p>
                        </TableCell>
                        <TableCell>{this.getPatientStatus(patient)}</TableCell>
                        <TableCell>
                          {patient.user ? (
                            <p
                              style={{
                                fontSize: 16,
                                color: product.mainColor,
                                fontWeight: 'bold'
                              }}
                            >
                              {strings.TextActivated}
                            </p>
                          ) : (
                              <p style={{ fontSize: 16 }}>
                                {patient.creationCode}
                              </p>
                            )}
                        </TableCell>
                        <TableCell>
                          {<PatientTypeItems types={patient.types} />}
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </div>

          <div className={classes.root}>
            <TablePagination
              component={'div'}
              count={patients.length}
              rowsPerPage={config.patientPage.rowsPerPage}
              page={config.patientPage.page}
              backIconButtonProps={{
                'aria-label': 'Previous Page'
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page'
              }}
              labelRowsPerPage={strings.TextRowsPerPage}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} ` + strings.TextOf + ` ${count}`
              }
              onChangePage={(event, page) => this.props.setPaginationPage(page)}
              onChangeRowsPerPage={event =>
                this.props.setPaginationRowsPerPage(event.target.value)
              }
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
            />
          </div>
        </Paper>
        {selectedPatients.length !== 0 ? (
          <div className={classes.buttonContainer}>
            <Button
              loading={this.props.patients.isDeleting}
              className={'warning'}
              color={'warning'}
              onClick={() => this.deletePatients()}
              header={
                selectedPatients.length === 1
                  ? strings.RemovePatientHeader
                  : strings.RemovePatientsHeader +
                  ' (' +
                  selectedPatients.length +
                  ')'
              }
            />
          </div>
        ) : null}
        <Modal
          show={
            user.forceNewPassword && user.type === Constants.HEALTHCARE_PROVIDER
          }
          header={strings.TextChoosePassword}
          loading={user.isSettingNewPassword}
          information={strings.TextFirstLogin}
          buttons={
            <MatButton
              onClick={() => this.setPassword()}
              className={classes.successButtonText}
            >
              {strings.ButtonDone}
            </MatButton>
          }
        >
          <div>
            <input
              type={'password'}
              placeholder={strings.TextPassword}
              value={password}
              onChange={e => this.setState({ password: e.target.value })}
            />
            <input
              type={'password'}
              placeholder={strings.TextRepeatPassword}
              value={repeatPassword}
              onChange={e => this.setState({ repeatPassword: e.target.value })}
            />
          </div>
        </Modal>
      </main>
    )
  }
}

const Classes = theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
    paddingTop: 88
  },
  header: {
    fontWeight: 'bold',
    fontStyle: 'italic',
    fontSize: 87,
    margin: 0
  },
  lastHeader: {
    color: product.mainColor
  },
  table: {
    minWidth: 700
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fafafa'
    }
  },
  root: {
    width: '100%',
    marginTop: 24
  },
  innerRoot: {
    width: '100%',
    overflowX: 'auto'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

Patients.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps({ config, user, data, patients }) {
  return { config, user, data, patients }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...configActions,
      ...patientsActions,
      ...patientsPageActions,
      ...clinicPatientsActions,
      ...providerActions
    },
    dispatch
  )
}

export default compose(
  withStyles(Classes),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Patients)
