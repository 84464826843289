import * as Types from '../../actions/Types'

const INITIAL_STATE = {
  isCreating: false,
  errorCreatingStatus: null
}

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action

  switch (type) {
  case Types.CREATE_PROVIDER:
    return {
      ...state,
      isCreating: true,
      errorCreatingStatus: null
    }
  case Types.CREATE_PROVIDER_SUCCESS:
    return {
      ...state,
      isCreating: false,
      errorCreatingStatus: null
    }
  case Types.CREATE_PROVIDER_ERROR:
    return {
      ...state,
      isCreating: false,
      errorCreatingStatus: payload
    }
  case Types.SIGN_OUT_SUCCESS:
    return {
      ...state,
      ...INITIAL_STATE
    }
  default:
    return state
  }
}
