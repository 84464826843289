import * as Types from './Types'

export function setFilter(filter) {
  return dispatch => {
    dispatch({
      type: Types.PATIENTS_PAGE_SET_FILTER,
      payload: filter
    })
  }
}

export function showFilter(show) {
  return dispatch => {
    dispatch({
      type: Types.PATIENTS_PAGE_SHOW_FILTER,
      payload: show
    })
  }
}

export function setSearchInput(text) {
  return dispatch => {
    dispatch({
      type: Types.PATIENTS_PAGE_SET_SEARCH_INPUT,
      payload: text
    })
  }
}

export function showOnlyActivated(show) {
  return dispatch => {
    dispatch({
      type: Types.PATIENTS_PAGE_SHOW_ONLY_ACTIVATED,
      payload: show
    })
  }
}

export function setPatientStatusPeriod(period) {
  return dispatch => {
    dispatch({
      type: Types.PATIENTS_PAGE_SET_PATIENT_STATUS_PERIOD,
      payload: period
    })
  }
}

export function setSortOrder(orderBy, order, sortType) {
  return dispatch => {
    dispatch({
      type: Types.PATIENTS_PAGE_SET_SORT_ORDER,
      payload: { orderBy, order, sortType }
    })
  }
}

export function setPaginationPage(page) {
  return dispatch => {
    dispatch({
      type: Types.PATIENTS_PAGE_SET_PAGINATION_PAGE,
      payload: page
    })
  }
}

export function setPaginationRowsPerPage(rows) {
  return dispatch => {
    dispatch({
      type: Types.PATIENTS_PAGE_SET_PAGINATION_ROWS_PER_PAGE,
      payload: rows
    })
  }
}
