import Axios from 'axios'
import products from '../../../helpers/Constants/product'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8080/api/v1/'
    : 'https://portal.medotemic.com/api/v1/'

export function signIn(username, data) {
  const url = BASE_URL + 'user/' + username + '/login'

  return Axios.post(url, data, { headers: { product: products.product } })
}

export function validateToken(token, userId) {
  const url = BASE_URL + 'user/' + userId + '/validate'

  return Axios.post(url, {}, getConfig(token))
}

export function getPatients(token, userId) {
  const url = BASE_URL + 'clinic/' + userId + '/patients'

  return Axios.get(url, getConfig(token))
}

export function createPatients(token, userId, data) {
  const url = BASE_URL + 'clinic/' + userId + '/add/patient'

  return Axios.post(
    url,
    {},
    {
      headers: {
        authentication: 'Bearer ' + token,
        patientTypes: JSON.stringify(data.patientTypes),
        profile: JSON.stringify(data.profile),
        shownMeasurements: JSON.stringify({}),
        goals: JSON.stringify(data.goals)
      }
    }
  )
}

export function deletePatient(token, userId, patientId) {
  const url = `${BASE_URL}clinic/${userId}/patients/${patientId}`

  return Axios.delete(url, getConfig(token))
}

export function deleteTraining(token, userId, patientId, id) {
  const url = `${BASE_URL}clinic/${userId}/patients/${patientId}/trainings/${id}`

  return Axios.delete(url, getConfig(token))
}

export function importPatient(token, userId, code) {
  const url = `${BASE_URL}clinic/${userId}/patients/${code}`

  return Axios.patch(url, {}, getConfig(token))
}

export function getDetailedPatient(token, userId, patientId) {
  const url =
    BASE_URL + 'clinic/' + userId + '/patients/' + patientId + '/trainings'

  return Axios.get(url, getConfig(token))
}

export function updatePatient(token, userId, patientId, data) {
  const url =
    BASE_URL + 'clinic/' + userId + '/patient/' + patientId + '/profile/update'

  return Axios.post(
    url,
    {},
    {
      headers: {
        authentication: 'Bearer ' + token,
        patientTypes: JSON.stringify(data.patientTypes),
        profile: JSON.stringify(data.profile),
        shownMeasurements: JSON.stringify({}),
      }
    }
  )
}

export function updatePatientUsername(token, userId, patientId, data) {
  const url =
    BASE_URL + 'clinic/' + userId + '/patients/' + patientId + '/changeUsername'

  return Axios.post(
    url,
    {},
    {
      headers: {
        authentication: 'Bearer ' + token,
        username: data.username,
      }
    }
  )
}

export function updatePatientPassword(token, userId, patientId, data) {
  const url =
    BASE_URL + 'clinic/' + userId + '/patients/' + patientId + '/resetPassword'

  return Axios.post(
    url,
    {},
    {
      headers: {
        authentication: 'Bearer ' + token,
        password: data.password,
      }
    }
  )
}

export function getGoals(token, userId, patientId) {
  const url =
    BASE_URL + 'clinic/' + userId + '/patients/' + patientId + '/goals'

  return Axios.get(url, getConfig(token))
}

export function createGoals(token, userId, patientId, data) {
  const url =
    BASE_URL + 'clinic/' + userId + '/patients/' + patientId + '/goals'

  return Axios.post(url, data, getConfig(token))
}

// Goals

// Minute goals
export function createMinuteGoal(token, userId, patientId, data) {
  const url =
    BASE_URL + 'clinic/' + userId + '/patients/' + patientId + '/goals/minutes'

  return Axios.post(url, data, getConfig(token))
}

export function deleteMinuteGoal(token, userId, patientId, id) {
  const url =
    BASE_URL +
    'clinic/' +
    userId +
    '/patients/' +
    patientId +
    '/goals/minutes/' +
    id

  return Axios.delete(url, getConfig(token))
}

export function editMinuteGoal(token, userId, patientId, id, data) {
  const url =
    BASE_URL +
    'clinic/' +
    userId +
    '/patients/' +
    patientId +
    '/goals/minutes/' +
    id

  return Axios.patch(url, data, getConfig(token))
}

// Limp goals
export function createLimpGoal(token, userId, patientId, data) {
  const url =
    BASE_URL + 'clinic/' + userId + '/patients/' + patientId + '/goals/limps'

  return Axios.post(url, data, getConfig(token))
}

export function deleteLimpGoal(token, userId, patientId, id) {
  const url =
    BASE_URL +
    'clinic/' +
    userId +
    '/patients/' +
    patientId +
    '/goals/limps/' +
    id

  return Axios.delete(url, getConfig(token))
}

export function editLimpGoal(token, userId, patientId, id, data) {
  const url =
    BASE_URL +
    'clinic/' +
    userId +
    '/patients/' +
    patientId +
    '/goals/limps/' +
    id

  return Axios.patch(url, data, getConfig(token))
}

// Number of trainings goals
export function createTrainingsGoal(token, userId, patientId, data) {
  const url =
    BASE_URL + 'clinic/' + userId + '/patients/' + patientId + '/goals/numberOfTraining'

  return Axios.post(url, data, getConfig(token))
}

export function deleteTrainingsGoal(token, userId, patientId, id) {
  const url =
    BASE_URL +
    'clinic/' +
    userId +
    '/patients/' +
    patientId +
    '/goals/numberOfTraining/' +
    id

  return Axios.delete(url, getConfig(token))
}

export function editTrainingsGoal(token, userId, patientId, id, data) {
  const url =
    BASE_URL +
    'clinic/' +
    userId +
    '/patients/' +
    patientId +
    '/goals/numberOfTraining/' +

    id
  return Axios.patch(url, data, getConfig(token))
}

export function setPassword(token, userId, data) {
  const url = BASE_URL + 'clinic/' + userId + '/password'

  return Axios.patch(url, data, getConfig(token))
}

// Rapport routes
export function generateRapport(userId, queryParams) {
  const url = `${BASE_URL}reports/users/${userId}/trainings${queryParams}`

  return Axios.get(url)
}

function getConfig(token) {
  return { headers: { authentication: 'Bearer ' + token } }
}
