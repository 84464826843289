import React, { Component } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'

import product from '../../../helpers/Constants/product'

class Button extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hover: false
    }
  }

  componentDidMount() {}

  componentWillUnmount() {}

  hoverOn() {
    this.setState({ hover: true })
  }

  hoverOff() {
    this.setState({ hover: false })
  }

  render() {
    const { header, className, loading, onClick, color, style, icon } = this.props
    const buttonStyle = {
      backgroundColor: this.state.hover
        ? color == 'warning'
          ? '#ff9591'
          : product.thirdColor
        : color == 'warning'
          ? '#f26363'
          : product.mainColor
    }

    return (
      <button
        className={className}
        style={{ ...buttonStyle, ...style }}
        onClick={loading ? () => {} : () => onClick()}
        onMouseEnter={this.hoverOn.bind(this)}
        onMouseLeave={this.hoverOff.bind(this)}
      >
        {loading ? (
          <CircularProgress
            style={{ color: 'white' }}
            thickness={6}
            size={20}
          />
        ) : (
          header
        )}
      </button>
    )
  }
}

export default Button
