import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
    en: {
        Code: "en",
        //Common
        ButtonYes: "Yes",
        ButtonNo: "No",
        ButtonOK: "OK",
        ButtonDone: "Done",
        ButtonCreate: "Create",
        ButtonLogOut: "Log out",
        MedoClinic: "MedoClinic",
        HeaderPt1: "Medo",
        HeaderPt2: "Clinic",
        TextLimpingIndex: "Limping index",
        TextDate: "Date",
        TextLimp: "Limping",
        TextSteps: "Steps",
        TextTraining: "Training",
        UnexpectedErrorHeader: "Unexpected error",
        UnexpectedError: "An unexpected error has occurred, please try again.",
        TextReturn: "Return",
        NotAvailable: "N/A",
        UsernameContainSpecialCharHeader: "The username cannot contain special characters",
        UsernameContainSpecialChar: "The username can contain any character from a to z, A to Z, 0-9 and -",
        UsernameLengthRangeHeader: "The username is too short or long",
        UsernameLengthRange: "The username must be between 6 and 20 characters long.",
        HealthcareProvierLoggedInAs: "Logged in with caregiver-id",
        
        //SignIn
        ButtonSignIn: "Login",
        TextUsername: "Username",
        TextPassword: "Password",
        TextTermsOfUse: "Terms of use",
        UsernameOrPasswordMissingHeader: "Username or password is missing",
        UsernameOrPasswordMissing: "Please enter a username and password.",
        InvalidCredentialsHeader: "Username and password do not match",
        InvalidCredentials: "Username and password do not match, please try again",
        
        //ListItems
        TextPatient: "Patient",
        TextPatients: "Patients",
        TextNewPatient: "New patient",
        TextResults: "Results",
        TextTargets: "Targets",
        TextCaregiver: "Caregiver",
        TextNewCaregiver: "New caregiver",
        TextClinics: "Clinics",
        TextNewClinic: "New clinic",
        TextSettings: "Settings",
        TextInformation: "Information",
        TextImport: "Import",
        
        //Drawer
        LeavePageHeader: "Leave page",
        LeavePage: "Are you sure you want to leave this page? Any changes will disappear.",
        SignOutHeader: "Log out",
        SignOut: "Are you sure you want to log out?",
        
        //CLINIC
        //Dashboard
        TextChoosePassword: "Choose a password",
        TextFirstLogin: "When you login for the first time you need to change password.",
        TextMyPage: "My page",
        InfoMissingHeader: "Information missing",
        InfoMissing: "Please fill in all required fields and try again.",
        PasswordNoMatchHeader: "Password does not match",
        PasswordNoMatch: "The entered password do not match, please try again.",
        
        //MyProviders
        TextSearch: "Search",
        TextCaregiverId: "Caregiver-id",
        TextNrOfPatients: "Number of patients",
        TextActivatedPatients: "patients have been activated",
        
        //NewProvider
        CaregiverCreatedHeader: "Caregiver created",
        CaregiverCreated: "Caregiver has been created, navigate to \"Caregivers\" to see all caregivers.",
        TextNewCaregiver: "New caregiver",
        TextAutoGen: "Generated automatically",
        UsernameInUseHeader: "Username in use",
        UsernameInUse: "The username already exists, please choose another one and try again.",
        
        //Settings
        TextClinicSettings: "Clinic settings",
        TextUpdate: "Update",
        SettingsUpdatedHeader: "Settings updated",
        SettingsUpdated: "Your settings have been updated.",
        TextContacts: "Contact details",
        TextNewPassword: "Choose new password",
        
        //MEDOTEMIC
        //Clinics
        TextClinicId: "Clinic-id",
        TextMissing: "Missing",
        
        //NewClinic
        ClinicCreatedHeader: "Clinic added",
        ClinicCreated: "Clinic has been created, navigate to \"Clinics\" to see all clinics.",
        TextNewClinic: "New clinic",
        TextUpdatedUsernameTitle: "Updated username",
        TextUpdatedUsername: "Username is updated",
        TextUpdateUsername: "Update username",
        TextUpdateClinic: "Update clinic",
        TextClinicUpdated: "The clinic has been updated",
        TextUpdateLicense: "Update license",
        TextResetPassword: "Reset password",
        TextID: "ID:",
        TextUserInfo: "User information",
        TextLicense: "License",
        MaxPatientsHeader: "Limit for number of patients reached",
        MaxPatients: "It is not possible to add more patients to the system. Contact you administrator at the clinic.",
        LicenseUpdatedHeader: "License updated",
        LicenseUpdated: "The clinic's license is now updated.",
        
        //Patient
        //MyGoals
        TextSetUpTargetCaregiver: "The targets are defined by your caregiver.",
        TextNoReducingTargetCaregiver: "Target for reducing the limping index has not been defined by your caregiver.",
        TextNoStepTargetCaregiver: "Target for number of steps has not been defined by your caregiver.",
        TextNoMinuteTargetCaregiver: "Target for walking-time has not been defined by your caregiver.",
        TextNoWalksTargetCaregiver: "Target for number of walks has not been defined by your caregiver.",
        
        //MyTrainings
        TextLimpPerMin: "Limpingindex per minute",
        TextCadencePerMin: "Walking pace (steps per minute)",
        
        //PROVIDER
        //AddUser
        PatientCreatedHeader: "Patient added",
        PatientCreated: "Patient has been added. Do you want to define the targets?",
        TextPatientView: "To patient view",
        TextAdditional: "Add additional patient",
        TextNewPatient: "New patient",
        TextGender: "Gender",
        TextMan: "Man",
        TextWoman: "Woman",
        TextOther: "Other",
        TextLenght: "Body height (cm)",
        TextPatientType: "Patient type",
        TextSelectOneOrMore: "Select one or more from the list to the right",
        TextBgToProblems: "Background to problems",
        TextCauseOfProblems: "Cause of problems",
        TextWhereIsTheProblems: "Where are the problem(s) located?",
        TextToTheLeft: "To the left",
        TextInTheMiddle: "In the middle",
        TextToTheRight: "To the right",
        TextUndefined: "Undefined",
        NotValidLenghtHeader: "The body height is not valid",
        NotValidLenght: "Enter a body height between 63 and 251 cm.",
        TextBackToClinicView: "Return to the clinic view",
        
        //Dashboard
        PasswordsNoMatchHeader: "Passwords do not match",
        PasswordsNoMatch: "Password do not match, please try again",
        
        //GoalHistory
        TextActive: "Active",
        TextTargetLog: "Logbook targets",
        TextNoActiveTargets: "No active or former targets have been defined for the patient.",
        TextCreated: "Added",
        TextRemoved: "Removed",
        TextMinutes: "Minutes",
        TextNrOfWalks: "Number of walks",
        
        //ImportUser
        TextImportPatient: "Import patient data",
        TextCode: "Code",
        CodeNoExistHeader: "The code does not exist",
        CodeNoExist: "The entered code does not exist, please try again.",
        PatientImportedHeader: "The patient profile has been imported",
        PatientImported: "The patient profile with data has been imported and is found under the Patients tab",
        
        //Patients
        TextHideFilter: "Hide filter",
        TextShowFilter: "Show filter",
        TextPatientId: "Patient-id",
        TextActivationCode: "Activation code",
        TextActivated: "Activated",
        TextOnlyActivated: "Only show activated patients",
        TextTargetStatus: "Target achievement",
        RemovePatientHeader: "Remove patient",
        RemovePatientsHeader: "Remove patients",
        RemovePatient: "Are you sure you want to remove the patient? If the account hasn't been activated, it will disappear completely. Otherwise the patient will be able to be connected to a new clinic later.",
        RemovePatientsPt1: "Are you sure you want to remove",
        RemovePatientsPt2: "patients? Accounts that haven't been activated will be removed completely. Activated accounts have the possibilities to be linked to new clinics.",
        TextUnmarkAllPatientTypes: "Unmark all patient categories",
        TextMarkAllPatientTypes: "Mark all patient categories",
        TextCalculatePatient: "Calculate patient status by:",
        TextDay: "Day",
        TextWeek: "Week",
        TextMonth: "Month",
        
        //UserGoals
        RemoveTargetsHeader: "Remove targets",
        RemoveTargets: "Are you sure you want to remove the targets for the patient?",
        TextNotActivated: "Not activated",
        TextProfile: "Profile",
        TextSetUpTargetPatient: "Here targets are defined and changed for the patient",
        TextNoReducingTargetPatient: "A target for limping index reduction has not been defined for the patient.",
        TextNoStepTargetPatient: "A target for number of steps has not been defined for the patient.",
        TextNoMinuteTargetPatient: "A target for number of walking minutes has not been defined for the patient.",
        TextNoWalksTargetPatient: "A target for the number of walks has not been defined for the patient.",
        TextCancel: "Cancel",
        TextIndexBigger: "Start index must be bigger or equal to final index.",
        TextIndexBetween: "Both start and final index must be between 0 and 100.",
        TextStartBfEnd: "The start date must be before the end date",
        TextChangeLimpTargets: "Change limping-index targets",
        TextCreateLimpTargets: "Add limping-index targets",
        TextChangeMinuteTargets: "Change target for number of minutes",
        TextCreateMinuteTargets: "Add target for number of minutes",
        TextChangeWalksTargets: "Change target for number of walks",
        TextCreateWalksTargets: "Add target for number of walks",
        TextNoPatientCreated: "No patients have been added.",
        TextChoosePatient: "Choose a patient",
        
        //UserSettings
        PatientUpdatedHeader: "The patient profile has been updated",
        PatientUpdated: "The patient profile has been updated. Navigate to \"Patients\" to see all patients.",
        
        
        //UserStatistics
        TextSureToRemoveTrainings: "Are you sure you want to remove the selected trainings? This cannot be undone.",
        TextHideFilterReports: "Hide filters & report generation",
        TextShowFilterReports: "Show filters & report generation",
        TextAllTrainings: "All trainings",
        Text90days: "90 days",
        Text30days: "30 days",
        Text7days: "7 days",
        TextOwnInterval: "Own interval",
        TextFrom: "From",
        TextTo: "To",
        TextGenerateReport: "Generate report",
        TextNoPatientInterval: "There are no results for the patient in the selected interval.",
        NumberOfTrainings: "Number of walks",
        Week: "Week",
        ShortWeek: "W",
        UnderConstructionTitle: "Under construction",
        UnderConstructionText: "This functionality is under construction",
        
        //Constants product.js
        NotAConnectedPatientHeader: "Use the Medomotion web",
        NotAConnectedPatient: "You're not connected to a clinic, use the Medomotion web",
        TextMMWeb: "Medomotion web",
        
        //Functions
        TextOsteoarthritisPatient: "Osteoarthritis patient",
        TextOsteoarthritisSchool: "Osteoarthritis school",
        TextStrokePatient: "Stroke patient",
        TextFootFractures: "Foot fracture",
        TextImplantPatients: "Implant patients",
        TextSecondaryPrevention: "Secondary prevention",
        TextBackpain: "Backpain",
        TextNeurologicalDamage: "Neurological damage",
        TextProsthesis: "Prosthesis",
        TextCerebralPalsy: "Cerebral palsy",
        TextSPD: "SPD",
        TextOsteoporosis: "Osteoporosis",
        TextOtherType: "Other type",
        TextUnknownType: "Unknown type",
        TextSeconds: "seconds",
        
        //Contacts index.js
        TextBackProblems: "Back problems",
        TextHipProblems: "Hip problems",
        TextKneeProblems: "Knee problems",
        TextFootProblems: "Foot problems",
        TextAnkleProblems: "Ankle problems",
        TextOtherReason: "Other reason",
        TextJointProblems: "Joint problems",
        TextMuscleDisorders: "Muscle disorders",
        TextNeurologicalDisorders: "Neurological disorders",
        TextUlceration: "Ulceration",
        TextNewHipOrKneeJoint: "New hip or knee joint",
        TextACLInjury: "ACL injury",
        TextNewlyOperated: "Newly operated",
        TextSkeletalInjury: "Skeletal injury",
        TextOtherReasons: "Other reasons",
        
        //Information
        InfoHeader: "How the limping index is calcuated",
        TextInfoPt1: "A limping index is calculated between 0-100 based on the motion data collected from your mobile phone's sensors during a walk. When the app is activated, sensor data starts to be captured and ceases when the user indicates that the walk is ended. The limping index zero means symmetry and undetectable limping, while limping index 100 implies very severe limping. The index is calculated as a weighting of the sensor data captured during a walk where symmetries and imbalances are analyzed and classified.",
        TextInfoPt2: "Only walking data from active walking is analyzed, ie data collected at a standstill is removed. This means that you can easily stop several times during a walk. The recommendation is to walk for at least 5 minutes although value is given after shorter walks. The longer you walk, the better the results of the analysis. If not enough sensor data is available to calculate an index, an error message will appear.",
        TextInfoPt3: "Accelerometer and gyroscope data from the mobile sensors are collected. If any of these sensors are missing, the app cannot be activated. In some cases, a test of the mobile phone needs to be performed to ensure sufficient quality of data collected. The test takes 5 minutes. For iPhones, access to location data is needed for functionality reasons, but this data is not collected.",
        LimpInfoHeader: "Limping index translated into text",
        TextLimpInfoPt1: "1-20: Mild limping",
        TextLimpInfoPt2: "21-40: Moderate limping",
        TextLimpInfoPt3: "41-60: Moderately severe limping",
        TextLimpInfoPt4: "61-80: Severe limping",
        TextLimpInfoPt5: "81-100: Extremely severe limping",
        TrendInfo: "How the trend is calcuated",
        TextTrendInfoPt1: "The trend is indicated in the result in the app, telling how the limping developed during the walk. It is expressed according;",
        TextTrendInfoPt2: "Limping shows no clear trend during this walk",
        TextTrendInfoPt3: "Limping is reducing in the walk",
        TextTrendInfoPt4: "Limping is increasing in the walk",
        TextTrendInfoPt5: "The trend (if any can be detected) is also stated in the web result table for the individual patient. The trend development can on a minute-basis be studied in a graph for one or many walks (trainings) at the same time.",
        TextManualWebCaregiver: "Open manual web (caregiver)",
        TextManualApp: "Open manual (app)",
        TextManualWebPatient: "Open manual web (patient)",
        TextFaq: "Frequently asked questions",
        TargetAchievement: "Target achievement",
        TextTargetInfoPt1: "Target achievement is shown with colours and is calculated based on weekly targets defined. It is shown as colour markings in a separate column in the Patients view.",
        TextTargetInfoPt2: "The colours indicated for each patient are based on how well the defined targets have been fulfilled.",
        TextTargetInfoPt21: "Target fulfilled gives green colour.",
        TextTargetInfoPt22: "Target fulfilled between 34-99% gives yellow colour",
        TextTargetInfoPt23: "Fulfilment up to 33% gives red colour.",
        TextTargetInfoPt3: "The summarized percentage is based on points given for each separate target.",
        TextTargetInfoPt31: "Target fulfilled gives 1p.",
        TextTargetInfoPt32: "Target achieved between limits gives 0,5p.",
        TextTargetInfoPt33: "Target below lower limit gives 0p.",
        TextTargetInfoPt4: "The limits depend on type of target.",
        TextTargetInfoPt41: "0,5p is given when limping index/active time deviates with maximum 15% from target or number of walks deviates with maximum 34%.",
        TextTargetInfoPt42: "0p is given when being outside the limits specified above.",
        
        //Forms
        TextStartingIndex: "Starting index",
        TextFinalIndex: "Final index",
        TextStartingDate: "Starting date",
        TextEndDate: "End date",
        TextMinutes: "Minutes",
        TextMinutesWeek: "minutes/week",
        TextWalksWeek: "walks/week",
        TextStepsPerMinute: "Steps per minute",
        TextClinicName: "Clinic name",
        TextRepeatPassword: "Repeat password",
        TextMaxPatients: "Max patients",
        TextPrice: "Price",
        TextValidity: "Period of validity",
        TextCompanyNumber: "Company number",
        TextMail: "Contact email",
        TextNumber: "Contact number",
        TextAddress: "Address",
        TextCity: "City",
        TextZipCode: "Zip code",
        
        //Goals
        TextReduceLimpIndex: "Reduce your limping index from",
        TextTo: "to",
        TextDuring: "during the",
        TextTimePeriod: "time period",
        TextUntil: "until",
        TextWalk: "Walk",
        TextTimesWeek: "times/week",
        TextShowLog: "Show log for targets",
        TextReducingTarget: "Target for reducing the limping index",
        TextChange: "Change",
        TextRemove: "Remove",
        TextAdd: "Add",
        TextMinuteTarget: "Target for number of minutes",
        TextWalksTarget: "Target for number of walks",
        
        //Graphs
        TextNrOfSteps: "Number of steps",
        TextActiveTimeMins: "Active time (minutes)",
        TextActiveTime: "Active time",
        TextTotalActiveTime: "Total active time (%)",
        TextNoneActiveTime: "None active time",
        TextActiveWalkingP: "Active walking time (%)",
        TextActiveWalking: "Active walking time",
        TextMinute: "Minute",
        TextTarget: "Target",
        
        //Table, TrainingsTable, MyResults, MyTrainings, UserStatistics
        TextUnavailable: "Unavailable",
        TextTotalTime: "Total time",
        TextRowsPerPage: "Rows per page:",
        TextOf: "of",
        TextFilter: "Filter",
        TextCadence: "Steps/min",
        TextTable: "Table",
        TextPhonePosition: "Phone position",
        LeftOrRight: {
            C:  "Center",
            L:  "Left",
            R:  "Right"
        },
        FrontOrBack: {
            B:  "back",
            F:  "front"
        },
        TextViewTraining: "View training",
        TextCompareTraining: "Compare trainings",
        TextPage: "Page",
        TextRemoveTraining: "Remove training",
        TextRemoveTrainings: "Remove trainings",
        TextFilt: "Filter",
        TextInterval: "Interval",
        TextMyTrainings: "My trainings",
        TextSelfLimp: "Self-assessed limping",
        TextPain: "Pain",
        TextTrend: "Trend",
        
        //TableHead
        TextSort: "Sort",
        
        //Highcharts
        TextMon: "Monday",
        TextTue: "Tuesday",
        TextWed: "Wednesday",
        TextThu: "Thursday",
        TextFri: "Friday",
        TextSat: "Saturday",
        TextSun: "Sunday",
        TextJan: "Jan",
        TextFeb: "Feb",
        TextMar: "Mar",
        TextApr: "Apr",
        TextMay: "May",
        TextJun: "Jun",
        TextJul: "Jul",
        TextAug: "Aug",
        TextSep: "Sep",
        TextOct: "Oct",
        TextNov: "Nov",
        TextDec: "Dec",
        
        //ProviderSettings
        TextPasswordIsResetTitle: "Reset password",
        TextPasswordIsReset: "Password is now reset",
    },
    sv: {
        Code: "sv",
        //Common
        ButtonYes: "Ja",
        ButtonNo: "Nej",
        ButtonOK: "OK",
        ButtonDone: "Klar",
        ButtonCreate: "Skapa",
        ButtonLogOut: "Logga ut",
        MedoClinic: "MedoClinic",
        HeaderPt1: "Medo",
        HeaderPt2: "Clinic",
        TextLimpingIndex: "Haltningsindex",
        TextDate: "Datum",
        TextLimp: "Hälta",
        TextSteps: "Steg",
        TextTraining: "Träning",
        UnexpectedErrorHeader: "Oväntat fel",
        UnexpectedError: "Ett oväntat fel har uppstått, var god försök igen.",
        TextReturn: "Gå tillbaka",
        NotAvailable: "Ej tillgängligt",
        UsernameContainSpecialCharHeader: "Användarnamnet får inte innehålla specialtecken",
        UsernameContainSpecialChar: "Användarnamnet kan bestå av tecknen a-z, A till Z, 0-9 eller -",
        UsernameLengthRangeHeader: "Användarnamnet är för kort eller för långt",
        UsernameLengthRange: "Användarnamnet måste innehålla 6-20 tecken.",
        HealthcareProvierLoggedInAs: "Inloggad med vårdgivar-id",
        
        //SignIn
        ButtonSignIn: "Logga in",
        TextUsername: "Användarnamn",
        TextPassword: "Lösenord",
        TextTermsOfUse: "Användarvillkor",
        UsernameOrPasswordMissingHeader: "Användarnamn eller lösenord saknas",
        UsernameOrPasswordMissing: "Var god skriv in ett användarnamn och ett lösenord.",
        InvalidCredentialsHeader: "Användarnamn och lösenord matchar ej",
        InvalidCredentials: "Det angivna användarnamnet och lösenordet matchar ej, var god försök igen.",
        
        //ListItems
        TextPatient: "Patient",
        TextPatients: "Patienter",
        TextNewPatient: "Ny patient",
        TextResults: "Resultat",
        TextTargets: "Mål",
        TextCaregiver: "Vårdgivare",
        TextNewCaregiver: "Ny vårdgivare",
        TextClinics: "Kliniker",
        TextNewClinic: "Ny klinik",
        TextSettings: "Inställningar",
        TextInformation: "Information",
        TextImport: "Importera",
        
        //Drawer
        LeavePageHeader: "Lämna sidan",
        LeavePage: "Är du säker på att du vill lämna sidan? Eventuella ändringar kommer att försvinna.",
        SignOutHeader: "Logga ut",
        SignOut: "Är du säker på att du vill logga ut?",
        
        //CLINIC
        //Dashboard
        TextChoosePassword: "Välj ett lösenord",
        TextFirstLogin: "Första gången du loggar in måste du välja ett nytt lösenord.",
        TextMyPage: "Min sida",
        InfoMissingHeader: "Information saknas",
        InfoMissing: "Var god fyll i alla obligatoriska fält och försök igen.",
        PasswordNoMatchHeader: "Lösenord matchar ej",
        PasswordNoMatch: "Det inskrivna lösenordet matchar ej, var god försök igen.",
        
        //MyProviders
        TextSearch: "Sök",
        TextCaregiverId: "Vårdgivar-id",
        TextNrOfPatients: "Antal patienter",
        TextActivatedPatients: "patienter har aktiverats.",
        
        //NewProvider
        CaregiverCreatedHeader: "Vårdgivare skapad",
        CaregiverCreated: "Vårdgivaren har skapats, navigera till \"Vårdgivare\" för att se alla vårdgivare.",
        TextNewCaregiver: "Ny vårdgivare",
        TextAutoGen: "Genereras automatiskt",
        UsernameInUseHeader: "Användarnamn i bruk",
        UsernameInUse: "Användarnamnet är redan i bruk, var god välj ett annat och försök igen.",
        
        //Settings
        TextClinicSettings: "Klinik inställningar",
        TextUpdate: "Uppdatera",
        SettingsUpdatedHeader: "Inställningar uppdaterade",
        SettingsUpdated: "Dina inställningar har uppdaterats.",
        TextContacts: "Kontaktuppgifter",
        TextNewPassword: "Välj nytt lösenord",
        
        //MEDOTEMIC
        //Clinics
        TextClinicId: "Klinik-id",
        TextMissing: "Saknas",
        
        //NewClinic
        ClinicCreatedHeader: "Klinik tillagd",
        ClinicCreated: "Kliniken har skapats, navigera till \"Kliniker\" för att se alla kliniker.",
        TextNewClinic: "Ny klinik",
        TextUpdatedUsernameTitle: "Uppdaterat användarnamn",
        TextUpdatedUsername: "Användarnamnet har uppdaterats",
        TextUpdateUsername: "Uppdatera användarnamn",
        TextUpdateClinic: "Uppdatera klinik",
        TextClinicUpdated: "Kliniken har uppdaterats",
        TextUpdateLicense: "Uppdatera licens",
        TextResetPassword: "Återställ lösenord",
        TextID: "ID:",
        TextUserInfo: "Användaruppgifter",
        TextLicense: "Licens",
        MaxPatientsHeader: "Gränsen för antal patienter nådd",
        MaxPatients: "Det är inte möjligt att lägga till fler patienter i systemet. Kontakta administratören på kliniken.",
        LicenseUpdatedHeader: "Licens uppdaterad",
        LicenseUpdated: "Klinikens licens är nu uppdaterad.",
        
        //Patient
        //MyGoals
        TextSetUpTargetCaregiver: "Målen definieras av din vårdgivare.",
        TextNoReducingTargetCaregiver: "Mål för minskning av haltningsindex har ej definierats av din vårdgivare.",
        TextNoStepTargetCaregiver: "Mål för antal steg har ej definierats av din vårdgivare.",
        TextNoMinuteTargetCaregiver: "Mål för promenadtid har ej definierats av din vårdgivare.",
        TextNoWalksTargetCaregiver: "Mål för antal promenader har ej definierats av din vårdgivare.",
        
        //MyTrainings
        TextLimpPerMin: "Haltningsindex per minut",
        TextCadencePerMin: "Promenadtakt (steg/min)",
        
        //PROVIDER
        //AddUser
        PatientCreatedHeader: "Patient tillagd",
        PatientCreated: "Patienten har lagts till. Vill du definiera målen?",
        TextPatientView: "Till patientvyn",
        TextAdditional: "Lägg till ytterligare patient",
        TextNewPatient: "Ny patient",
        TextGender: "Kön",
        TextMan: "Man",
        TextWoman: "Kvinna",
        TextOther: "Vill ej uppge",
        TextLenght: "Kroppslängd (cm)",
        TextPatientType: "Patienttyp",
        TextSelectOneOrMore: "Välj en eller flera i listan till höger",
        TextBgToProblems: "Bakgrund till besvär",
        TextCauseOfProblems: "Orsak till problem",
        TextWhereIsTheProblems: "Var är problemen placerade?",
        TextToTheLeft: "Till vänster",
        TextInTheMiddle: "I mitten",
        TextToTheRight: "Till höger",
        TextUndefined: "Odefinierat",
        NotValidLenghtHeader: "Kroppslängden är inte giltlig",
        NotValidLenght: "Ange en längd mellan 63 och 251 cm.",
        TextBackToClinicView: "Återgå till klinikvyn",
        
        //Dashboard
        PasswordsNoMatchHeader: "Lösenorden matchar ej",
        PasswordsNoMatch: "De inskrivna lösenorden matchar ej, var god försök igen.",
        
        //GoalHistory
        TextActive: "Aktivt",
        TextTargetLog: "Loggbok för mål",
        TextNoActiveTargets: "Inga aktiva eller tidigare mål finns definierade för patienten.",
        TextCreated: "Tillagt",
        TextRemoved: "Togs bort",
        TextMinutes: "Antal minuter",
        TextNrOfWalks: "Antal promenader",
        
        //ImportUser
        TextImportPatient: "Importera patientdata",
        TextCode: "Kod",
        CodeNoExistHeader: "Koden finns inte",
        CodeNoExist: "Den inskrivna koden existerar inte, var god försök igen.",
        PatientImportedHeader: "Patientprofilen har importerats",
        PatientImported: "Patientprofil och data har importerats och hittas under fliken patienter.",
        
        //Patients
        TextHideFilter: "Göm filter",
        TextShowFilter: "Visa filter",
        TextPatientId: "Patient-id",
        TextActivationCode: "Aktiveringskod",
        TextActivated: "Aktiverad",
        TextOnlyActivated: "Visa endast aktiverade patienter",
        TextTargetStatus: "Måluppfyllnad",
        RemovePatientHeader: "Ta bort patient",
        RemovePatientsHeader: "Ta bort patienter",
        RemovePatient: "Är du säker på att du vill ta bort patienten? Har kontot inte aktiverats kommer det att försvinna helt. I annat fall kommer patienten kunna koppla sig till en ny klinik senare.",
        RemovePatientsPt1: "Är du säker på att du vill ta bort",
        RemovePatientsPt2: "patienter? Konton som inte har aktiverats kommer att tas bort helt. Aktiverade konton går att koppla till nya kliniker.",
        TextUnmarkAllPatientTypes: "Avmarkera alla patientkategorier",
        TextMarkAllPatientTypes: "Markera alla patientkategorier",
        TextCalculatePatient: "Beräkna patientstatus per:",
        TextDay: "Dag",
        TextWeek: "Vecka",
        TextMonth: "Månad",
        
        //UserGoals
        RemoveTargetsHeader: "Ta bort mål",
        RemoveTargets: "Är du säker på att du vill ta bort målet för patienten?",
        TextNotActivated: "Ej aktiverad",
        TextProfile: "Profil",
        TextSetUpTargetPatient: "Här definieras och ändras mål för patienten.",
        TextNoReducingTargetPatient: "Mål för minskning av haltningsindex har ej definierats för patienten.",
        TextNoStepTargetPatient: "Mål för antal steg har ej definierats för patienten.",
        TextNoMinuteTargetPatient: "Mål för promenadtid har ej definierats  för patienten.",
        TextNoWalksTargetPatient: "Mål för antal promenader har ej definierats för patienten.",
        TextCancel: "Avbryt",
        TextIndexBigger: "Startindex måste vara större eller lika med slutindex.",
        TextIndexBetween: "Både start -och slut index måste ligga mellan 0 och 100.",
        TextStartBfEnd: "Startdatumet måste vara före slutdatumet",
        TextChangeLimpTargets: "Ändra haltindex mål",
        TextCreateLimpTargets: "Skapa ett haltindex mål",
        TextChangeMinuteTargets: "Ändra mål för antal minuter",
        TextCreateMinuteTargets: "Skapa ett mål för antal minuter",
        TextChangeWalksTargets: "Ändra mål för antal promenader",
        TextCreateWalksTargets: "Skapa ett mål för antal promenader",
        TextNoPatientCreated: "Inga patienter har lagts till.",
        TextChoosePatient: "Välj en patient",
        
        //UserSettings
        PatientUpdatedHeader: "Patientprofilen har uppdaterats",
        PatientUpdated: "Patientprofilen har uppdaterats. Gå till \"Patienter\" för att se alla patienter.",
        
        
        //UserStatistics
        TextSureToRemoveTrainings: "Är du säker på att du vill ta bort de markerade träningar? Detta kan inte ångras.",
        TextHideFilterReports: "Dölj filter & rapportgenerering",
        TextShowFilterReports: "Visa filter & rapportgenerering",
        TextAllTrainings: "Alla träningar",
        Text90days: "90 dagar",
        Text30days: "30 dagar",
        Text7days: "7 dagar",
        TextOwnInterval: "Eget intervall",
        TextFrom: "Från",
        TextTo: "Till",
        TextGenerateReport: "Generera rapport",
        TextNoPatientInterval: "Det finns inga resultat för patienten i det valda intervallet.",
        NumberOfTrainings: "Antal promenader",
        Week: "Vecka",
        ShortWeek: "V",
        UnderConstructionTitle: "Under konstruktion",
        UnderConstructionText: "Denna funktionalitet är under konstruktion",
        
        //Constants product.js
        NotAConnectedPatientHeader: "Använd Medomotion webben",
        NotAConnectedPatient: "Du är inte kopplad till en klinik, använd Medomotion webben",
        TextMMWeb: "Medomotion webb",
        
        //Functions
        TextOsteoarthritisPatient: "Artrospatient",
        TextOsteoarthritisSchool: "Artrosskola",
        TextStrokePatient: "Strokepatient",
        TextFootFractures: "Fotraktur",
        TextImplantPatients: "Implantatpatienter",
        TextSecondaryPrevention: "Sekundärprevention",
        TextBackpain: "Ryggsmärtor",
        TextNeurologicalDamage: "Neurologiska skador",
        TextProsthesis: "Protes (gångskola)",
        TextCerebralPalsy: "Cerebral pares",
        TextSPD: "Foglossning",
        TextOsteoporosis: "Osteoporos",
        TextOtherType: "Annan typ",
        TextUnknownType: "Okänd typ",
        TextSeconds: "sekunder",
        
        //Contacts index.js
        TextBackProblems: "Ryggproblem",
        TextHipProblems: "Höftproblem",
        TextKneeProblems: "Knäproblem",
        TextFootProblems: "Fotproblem",
        TextAnkleProblems: "Fotledsproblem",
        TextOtherReason: "Annan anledning",
        TextJointProblems: "Ledbesvär",
        TextMuscleDisorders: "Muskelbesvär",
        TextNeurologicalDisorders: "Neurologiska besvär",
        TextUlceration: "Sårbildning",
        TextNewHipOrKneeJoint: "Ny höft -eller knäled",
        TextACLInjury: "Korsbandsskada",
        TextNewlyOperated: "Nyopererad",
        TextSkeletalInjury: "Skelettskada",
        TextOtherReasons: "Andra orsaker",
        
        //Information
        InfoHeader: "Hur haltningsindexet beräknas",
        TextInfoPt1: "Ett haltningsindex beräknas mellan 0-100 baserat på den rörelsedata som insamlas från din mobiltelefons sensorer under en promenad. När appen startas börjar sensordata att samlas in och upphör när användaren i appen anger att promenaden är avslutad. Haltningsindex 0 innebär symmetri och ej synlig hälta medan haltningsindex 100 innebär maximalt utslag och mycket svår hälta. Indexet beräknas som en sammanvägning av den sensordata som samlas in under ett promenadtillfälle där symmetrier och obalanser analyseras och klassificeras.",
        TextInfoPt2: "Endast gångdata från aktiv promenad analyseras, dvs data insamlad vid stillastående sorteras bort. Det innebär att man utan problem kan stanna ett flertal gånger under en promenad. Rekommendationen är att promenera minst 5 minuter fastän värde ges efter kortare promenader. Ju längre tid man promenerar desto bättre blir resultatet av analysen. Om inte tillräckligt med sensordata finns för att beräkna ett index kommer ett felmeddelande att synas.",
        TextInfoPt3: "Accelerometer och gyroskop-data från mobilens sensorer samlas in. Saknas någon av dessa sensorer kan appen inte aktiveras. I vissa fall behöver ett test av mobilen genomföras för att säkerställa tillräcklig kvalitet på insamllad data. Testet tar 5 minuter. För iPhones behövs tillgång till platsdata för funktionalitetens skull, men denna data samlas inte in.",
        LimpInfoHeader: "Haltningsindex översatt i text",
        TextLimpInfoPt1: "1-20: Lätt hälta",
        TextLimpInfoPt2: "21-40: Måttlig hälta",
        TextLimpInfoPt3: "41-60: Medelsvår hälta",
        TextLimpInfoPt4: "61-80: Svår hälta",
        TextLimpInfoPt5: "81-100: Mycket svår hälta",
        TrendInfo: "Hur trenden beräknas",
        TextTrendInfoPt1: "Trenden indikeras i resultatet i appen och berättar hur haltningen utvecklades under promenaden. Det uttrycks enligt;",
        TextTrendInfoPt2: "Hältan visar ingen tydlig trend under denna promenad",
        TextTrendInfoPt3: "Hältan minskar under promenaden",
        TextTrendInfoPt4: "Hältan ökar under promenaden",
        TextTrendInfoPt5: "Trenden (om någon kan upptäckas) anges också i webbresultat tabellen för den enskilda patienten. Trendutvecklingen kan studeras på en minut i en graf för en eller flera promenader (träningar) samtidigt.",
        TextManualWebCaregiver: "Öppna manual webb (vårdgivare)",
        TextManualApp: "Öppna manual (app)",
        TextManualWebPatient: "Öppna manual webb (patient)",
        TextFaq: "Vanliga frågor",
        TargetAchievement: "Måluppfyllnad",
        TextTargetInfoPt1: "Måluppfyllnad visas med färger och beräknas baserat på definierade veckomål. Det visas som färgmarkeringar i en separat kolumn i Patient-vyn.",
        TextTargetInfoPt2: "Färgmarkeringen indikerar hur väl patienten har uppfyllt definierade mål.",
        TextTargetInfoPt21: "Uppfyllda mål ger grön färg",
        TextTargetInfoPt22: "Mål uppfyllda mellan 34-99% ger gul färg",
        TextTargetInfoPt23: "Mål uppfyllda till 33% ger röd färg",
        TextTargetInfoPt3: "Den sammanlagda procentsatsen baseras på poäng som ges för varje separat mål.",
        TextTargetInfoPt31: "Uppfyllda mål ger 1p.",
        TextTargetInfoPt32: "Mål uppfyllda mellan nivåer ger 0,5p.",
        TextTargetInfoPt33: "Mål under gränser nedre gränsen ger 0p.",
        TextTargetInfoPt4: "Nivåerna för måluppfyllnad beror på typ av mål.",
        TextTargetInfoPt41: "0,5p ges när haltningsindex/aktiv tid avviker med maximalt 15% från målet eller när antal promenader avviker med maximalt 34%.",
        TextTargetInfoPt42: "0p ges om man befinner sig utanför nivåerna angivna ovan",
        
        //Forms
        TextStartingIndex: "Startindex",
        TextFinalIndex: "Slutindex",
        TextStartingDate: "Startdatum",
        TextEndDate: "Slutdatum",
        TextMinutes: "Minuter",
        TextMinutesWeek: "minuter/vecka",
        TextWalksWeek: "promenader/vecka",
        TextStepsPerMinute: "Steg per minut",
        TextClinicName: "Kliniknamn",
        TextRepeatPassword: "Upprepa lösenord",
        TextMaxPatients: "Max patienter",
        TextPrice: "Pris",
        TextValidity: "Giltighetstid",
        TextCompanyNumber: "Organisationsnummer",
        TextMail: "Kontakt epost",
        TextNumber: "Kontaktnummer",
        TextAddress: "Adress",
        TextCity: "Stad",
        TextZipCode: "Postnummer",
        
        //Goals
        TextReduceLimpIndex: "Minska ditt haltindex från",
        TextTo: "till",
        TextDuring: "under",
        TextTimePeriod: "tidsperioden",
        TextUntil: "tills den",
        TextWalk: "Gå",
        TextTimesWeek: "gånger/vecka",
        TextShowLog: "Visa logg för mål",
        TextReducingTarget: "Mål för minskning av haltindex",
        TextChange: "Ändra",
        TextRemove: "Ta bort",
        TextAdd: "Lägg till",
        TextMinuteTarget: "Mål för antal minuter",
        TextWalksTarget: "Mål för antal promenader",
        
        //Graphs
        TextNrOfSteps: "Antal steg",
        TextActiveTimeMins: "Aktiv tid (minuter)",
        TextActiveTime: "Aktiv tid",
        TextTotalActiveTime: "Total aktiv tid (%)",
        TextNoneActiveTime: "Stillastående tid",
        TextActiveWalkingP: "Aktiv promenadtid (%)",
        TextActiveWalking: "Aktiv promenadtid",
        TextMinute: "Minut",
        TextTarget: "Mål",
        
        //Table, TrainingsTable, MyResults, MyTrainings, UserStatistics
        TextUnavailable: "Inte tillgänglig",
        TextTotalTime: "Total tid",
        TextRowsPerPage: "Rader per sida:",
        TextOf: "av",
        TextFilter: "Filtrera",
        TextCadence: "Steg/min",
        TextTable: "Tabell",
        TextPhonePosition: "Mobilens placering",
        LeftOrRight: {
            C:  "Mitten",
            L:  "Vänster",
            R:  "Höger"
        },
        FrontOrBack: {
            B:  "bak",
            F:  "fram"
        },
        TextViewTraining: "Se träning",
        TextCompareTraining: "Jämför träningar",
        TextPage: "Sida",
        TextRemoveTraining: "Ta bort träning",
        TextRemoveTrainings: "Ta bort träningar",
        TextFilt: "Filter",
        TextInterval: "Intervall",
        TextMyTrainings: "Mina träningar",
        TextSelfLimp: "Egenskattad hälta",
        TextPain: "Smärta",
        TextTrend: "Trend",
        
        //TableHead
        TextSort: "Sortera",
        
        //Highcharts
        TextMon: "Måndag",
        TextTue: "Tisdag",
        TextWed: "Onsdag",
        TextThu: "Torsdag",
        TextFri: "Fredag",
        TextSat: "Lördag",
        TextSun: "Söndag",
        TextJan: "Jan",
        TextFeb: "Feb",
        TextMar: "Mars",
        TextApr: "April",
        TextMay: "Maj",
        TextJun: "Juni",
        TextJul: "Juli",
        TextAug: "Aug",
        TextSep: "Sept",
        TextOct: "Okt",
        TextNov: "Nov",
        TextDec: "Dec",
        
        //ProviderSettings
        TextPasswordIsResetTitle: "Återställ lösenord",
        TextPasswordIsReset: "Lösenordet är återställts",
    }
});

export default strings;
