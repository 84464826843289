import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import strings from '../../../helpers/I18NStrings/index'

const DRAWER_WIDTH = 240

class Header extends React.Component {
  render() {
    const { classes, open, onClose, user } = this.props

    return (
      <AppBar
        position={'absolute'}
        className={classNames(classes.appBar, open && classes.appBarShift)}
        style={{
          backgroundColor: '#1b4428',
          boxShadow: '0px',
          zIndex: 20000
        }}
      >
        <Toolbar disableGutters={!open} className={classes.toolbar}>
          <IconButton
            color={'inherit'}
            aria-label={'Open drawer'}
            onClick={() => onClose()}
            className={classNames(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
        {user.type === 'HEALTHCARE_PROVIDER' && (
          <div
            style={{ flex: 1, display: 'flex', justifyContent: 'center' }}
          >
            <p
              style={{ display: 'inline', color: '#FFF' }}
            >{strings.HealthcareProvierLoggedInAs + " " + user.id}</p>
          </div>
        )}
      </AppBar>
    )
  }
}

const Styles = theme => ({
  toolbar: {
    paddingRight: 24
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBar: {
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto'
  },
  grow: {
    flexGrow: 1
  },
  selectCommunityButton: {
    borderColor: 'white',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)'
    },
    textTransform: 'none',
    fontFamily: 'Lato',
    fontSize: 16,
    fontWeight: 'bold',
    marginRight: 15
  }
})

Header.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps({ user }) {
  return { user }
}

export default compose(
  withStyles(Styles),
  connect(
    mapStateToProps
  )
)(withRouter(Header))
