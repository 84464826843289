import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import MaterialPaper from '@material-ui/core/Paper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown, faMinus } from '@fortawesome/free-solid-svg-icons'


import TableHead from '../TableHead'
import Checkbox from '../Checkbox'
import * as Functions from '../../../helpers/Functions'
import strings from '../../../helpers/I18NStrings/index'

const ROWS = [
  {
    id: 'selections',
    label: '',
    numeric: false,
    sortType: 'string',
    sort: false
  },
  {
    id: 'time',
    label: strings.TextDate,
    numeric: false,
    sortType: 'string',
    sort: true
  },
  {
    id: 'result',
    label: strings.TextLimp,
    numeric: false,
    sortType: 'integer',
    sort: true
  },
  {
    id: 'trend',
    label: strings.TextTrend,
    numeric: false,
    sortType: 'integer',
    sort: true
  },
  {
    id: 'selfLimp',
    label: strings.TextSelfLimp,
    numeric: false,
    sortType: 'string',
    sort: false
  },
  {
    id: 'pain',
    label: strings.TextPain,
    numeric: false,
    sortType: 'string',
    sort: false
  },
  {
    id: 'steps',
    label: strings.TextSteps,
    numeric: false,
    sortType: 'integer',
    sort: true
  },
  {
    id: 'activeTime',
    label: strings.TextActiveTime,
    numeric: false,
    sortType: 'integer',
    sort: true
  },
  {
    id: 'totTime',
    label: strings.TextTotalTime,
    numeric: false,
    sortType: 'integer',
    sort: true
  },
  {
    id: 'placement',
    label: strings.TextPhonePosition,
    numeric: false,
    sortType: 'integer',
    sort: false
  }
]

class TrainingsTable extends React.Component {
  desc(a, b, orderBy, sortType) {
    if (b[orderBy] < a[orderBy]) {
      return 1
    } else if (b[orderBy] > a[orderBy]) {
      return -1
    }

    return 0
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index])

    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0])

      if (order !== 0) {
        return order
      }

      return a[1] - b[1]
    })

    return stabilizedThis.map(el => el[0])
  }

  getSorting(order, orderBy, sortType) {
    return order === 'desc'
      ? (a, b) => this.desc(a, b, orderBy, sortType)
      : (a, b) => -this.desc(a, b, orderBy, sortType)
  }

  render() {
    const {
      classes,
      order,
      orderBy,
      sortType,
      setSortOrder,
      trainings,
      rowsPerPage,
      setPaginationRowsPerPage,
      page,
      setPaginationPage,
      compareTrainings = [],
      setCompareTrainings = () => { },
      columns = ['time', 'result', 'trend', 'selfLimp', 'pain',  'steps', 'activeTime', 'totTime'],
      onToggleAllCheckbox,
      allCheckboxesValue,
      allCheckboxesIndeterminate
    } = this.props

    const startIndex = page * rowsPerPage
    const rows = ROWS.filter(r => columns.indexOf(r.id) !== -1).map(r => {
      if (r.id === 'selections') {
        return {
          ...r,
          label: (
            <Checkbox
              indeterminate={allCheckboxesIndeterminate}
              onChange={() => onToggleAllCheckbox()}
              checked={allCheckboxesValue}
            />
          )
        }
      }

      return r
    })

    return (
      <MaterialPaper className={classes.root}>
        <div className={classes.innerRoot}>
          <p className={'smallText'}>
            {`${strings.TextPage} ${1 + page} ${strings.TextOf} ${Math.ceil(trainings.length / rowsPerPage)}`}
          </p>
          <Table className={classes.table} aria-labelledby={'tableTitle'}>
            <TableHead
              rows={rows}
              order={order}
              orderBy={orderBy}
              sortOn={(newOrderBy, newOrder, newSortType) =>
                setSortOrder(newOrderBy, newOrder, newSortType)
              }
            />

            <TableBody>
              {this.stableSort(
                trainings,
                this.getSorting(order, orderBy, sortType)
              )
                .slice(startIndex, rowsPerPage + startIndex)
                .map(t => {
                  const selected = compareTrainings.indexOf(t.id) !== -1
                  let trendText = <FontAwesomeIcon icon={faMinus} />;
                  if (t.rateIsSignificant === true) {
                    if (t.scoreRateIndex > 0.0)
                      trendText = <FontAwesomeIcon icon={faArrowUp} />;
                    else if (t.scoreRateIndex < 0.0)
                      trendText = <FontAwesomeIcon icon={faArrowDown} />;
                  }
                
                  return (
                    <TableRow
                      key={t.id}
                      className={classes.row}
                      style={{ cursor: 'auto' }}
                    >
                      {columns.indexOf('selections') !== -1 ? (
                        <TableCell>
                          <Checkbox
                            checked={selected}
                            onChange={() => {
                              if (selected) {
                                setCompareTrainings(
                                  compareTrainings.filter(id => id !== t.id)
                                )
                              } else {
                                setCompareTrainings([...compareTrainings, t.id])
                              }
                            }}
                          />
                        </TableCell>
                      ) : null}
                      {columns.indexOf('time') !== -1 ? (
                        <TableCell>
                          <p style={{ fontSize: 14 }}>{t.time}</p>
                        </TableCell>
                      ) : null}
                      {columns.indexOf('result') !== -1 ? (
                        (t.walkingOn === 'terrain' || t.walkingOn === 'other') ? (
                          <TableCell>
                            <p style={{ fontSize: 14 }}>{strings.NotAvailable}</p>
                          </TableCell>
                        ) : (
                          <TableCell>
                            <p style={{ fontSize: 14 }}>{t.result}</p>
                          </TableCell>
                        )
                      ) : null}
                      {columns.indexOf('trend') !== -1 ? (
                        <TableCell>
                          <p style={{ fontSize: 14 }}>{trendText}</p>
                        </TableCell>
                      ) : null}
                      {columns.indexOf('selfLimp') !== -1 ? (
                        <TableCell>
                          {(t.applimp && t.applimp != 'null') &&
                            <p style={{ fontSize: 14 }}>{t.applimp}</p>
                          }
                        </TableCell>
                      ) : null}
                      {columns.indexOf('pain') !== -1 ? (
                        <TableCell>
                          <p style={{ fontSize: 14 }}>{t.pain + '/10'}</p>
                        </TableCell>
                      ) : null}
                      {columns.indexOf('steps') !== -1 ? (
                        <TableCell>
                          <p style={{ fontSize: 14 }}>{t.steps}</p>
                        </TableCell>
                      ) : null}
                      {columns.indexOf('activeTime') !== -1 ? (
                        <TableCell>
                          <p style={{ fontSize: 14 }}>
                            {Functions.getTimeString(t.activeTime)}
                          </p>
                        </TableCell>
                      ) : null}
                      {columns.indexOf('totTime') !== -1 ? (
                        <TableCell>
                          <p style={{ fontSize: 14 }}>
                            {Functions.getTimeString(t.totTime)}
                          </p>
                        </TableCell>
                      ) : null}
                      {columns.indexOf('placement') !== -1 ? (
                        <TableCell>
                          <p style={{ fontSize: 14 }}>
                            {strings.LeftOrRight[t.placement] +
                              ' ' +
                              strings.FrontOrBack[
                                t.backOrFrontPlacement
                              ]}
                          </p>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </div>

        <div className={classes.root}>
          <TablePagination
            component={'div'}
            count={trainings.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            labelRowsPerPage={strings.TextRowsPerPage}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ` + strings.TextOf + ` ${count}`
            }
            onChangePage={(event, page) => setPaginationPage(page)}
            onChangeRowsPerPage={event =>
              setPaginationRowsPerPage(event.target.value)
            }
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
        </div>
      </MaterialPaper>
    )
  }
}

const Classes = theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto'
  },
  emptyContent: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  table: {
    minWidth: 700
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fafafa'
    }
  },
  root: {
    width: '100%',
    marginTop: 24,
    padding: 0
  },
  innerRoot: {
    width: '100%',
    overflowX: 'auto'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

TrainingsTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(Classes)(TrainingsTable)
