import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'

class Modal extends Component {
  render() {
    const {
      show,
      children,
      buttons,
      classes,
      header,
      information,
      loading
    } = this.props

    return (
      <Dialog
        open={show}
        aria-labelledby={'form-dialog-title'}
        classes={{ paperScrollPaper: classes.container }}
        style={{ marginTop: 60 }}
      >
        <DialogTitle id={'form-dialog-title'}>{header}</DialogTitle>
        <DialogContent>
          <DialogContentText>{information}</DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          {loading ? (
            <CircularProgress
              style={{ color: 'rgba(0, 0, 0, 0.67)' }}
              thickness={6}
              size={20}
            />
          ) : (
            buttons
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

const Styles = {
  container: {
    width: '100%'
  }
}

export default withStyles(Styles)(Modal)
