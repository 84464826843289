import * as Constants from '../Constants'

export function setToken(token) {
  localStorage.setItem(Constants.TOKEN_STORAGE, token)
}

export function getToken() {
  return localStorage.getItem(Constants.TOKEN_STORAGE)
}

export function removeToken() {
  localStorage.removeItem(Constants.TOKEN_STORAGE)
}

export function setUserId(userId) {
  localStorage.setItem(Constants.USER_STORAGE, userId)
}

export function getUserId() {
  return localStorage.getItem(Constants.USER_STORAGE)
}

export function removeUserId() {
  localStorage.removeItem(Constants.USER_STORAGE)
}
