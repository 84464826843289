import * as Types from '../../actions/Types'

const INITIAL_STATE = {
  isLoading: false,
  errorLoading: null,
  isUpdatingLicense: false,
  isUpdatingUsername: false,
  isUpdatingPassword: false
}

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action

  switch (type) {
    case Types.CREATE_CLINIC:
      return {
        ...state,
        isLoading: true,
        errorLoading: null
      }
    case Types.CREATE_CLINIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorLoading: null
      }
    case Types.CREATE_CLINIC_ERROR:
      return {
        ...state,
        isLoading: false,
        errorLoading: payload
      }
    case Types.UPDATE_CLINIC:
      return {
        ...state,
        isLoading: true,
        errorLoading: null
      }
    case Types.UPDATE_CLINIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorLoading: null
      }
    case Types.UPDATE_CLINIC_ERROR:
      return {
        ...state,
        isLoading: false,
        errorLoading: payload
      }
    case Types.UPDATE_CLINIC_USERNAME:
      return {
        ...state,
        isUpdatingUsername: true,
        errorLoading: null
      }
    case Types.UPDATE_CLINIC_USERNAME_SUCCESS:
      return {
        ...state,
        isUpdatingUsername: false,
        errorLoading: null
      }
    case Types.UPDATE_CLINIC_USERNAME_ERROR:
      return {
        ...state,
        isUpdatingUsername: false,
        errorLoading: payload
      }

    case Types.UPDATE_CLINIC_PASSWORD:
      return {
        ...state,
        isUpdatingPassword: true,
        errorLoading: null
      }
    case Types.UPDATE_CLINIC_PASSWORD_SUCCESS:
      return {
        ...state,
        isUpdatingPassword: false,
        errorLoading: null
      }
    case Types.UPDATE_CLINIC_PASSWORD_ERROR:
      return {
        ...state,
        isUpdatingPassword: false,
        errorLoading: payload
      }
    case Types.UPDATE_LICENSE:
      return {
        ...state,
        isUpdatingLicense: true,
        errorLoading: null
      }
    case Types.UPDATE_LICENSE_SUCCESS:
      return {
        ...state,
        isUpdatingLicense: false,
        errorLoading: null
      }
    case Types.UPDATE_LICENSE_ERROR:
      return {
        ...state,
        isUpdatingLicense: false,
        errorLoading: payload
      }
    case Types.SIGN_OUT_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
