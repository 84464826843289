import * as Types from '../Types'
import * as API from '../api/clinic'

export function tryGetClinic(token, userId) {
  return dispatch => {
    dispatch({
      type: Types.GET_CLINIC
    })

    API.getClinic(token, userId)
      .then(response => {
        dispatch({
          type: Types.GET_CLINIC_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: Types.GET_CLINIC_ERROR
        })
      })
  }
}

export function tryChangeProviderPassword(token, userId, data) {
  return dispatch => {
    dispatch({
      type: Types.CHANGE_PROVIDER_PASSWORD
    })

    API.ChangeProviderPassword(token, userId, data)
      .then(response => {
        dispatch({
          type: Types.CHANGE_PROVIDER_PASSWORD_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        let status = 500

        if (error.response && error.response.status) {
          status = error.response.status
        }

        dispatch({
          type: Types.CHANGE_PROVIDER_PASSWORD_ERROR,
          payload: status
        })
      })
  }
}

export function tryChangeProviderUsername(token, userId, data) {
  return dispatch => {
    dispatch({
      type: Types.CHANGE_PROVIDER_USERNAME
    })

    API.ChangeProviderUsername(token, userId, data)
      .then(response => {
        dispatch({
          type: Types.CHANGE_PROVIDER_USERNAME_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        let status = 500

        if (error.response && error.response.status) {
          status = error.response.status
        }

        dispatch({
          type: Types.CHANGE_PROVIDER_USERNAME_ERROR,
          payload: status
        })
      })
  }
}

export function tryUpdateClinic(token, userId, data) {
  return dispatch => {
    dispatch({
      type: Types.UPDATE_SELF_CLINIC
    })

    API.updateClinic(token, userId, data)
      .then(response => {
        dispatch({
          type: Types.UPDATE_SELF_CLINIC_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: Types.UPDATE_SELF_CLINIC_ERROR,
          payload: error.response ? error.response.status : 500
        })
      })
  }
}

export function trySetPassword(token, userId, data) {
  return dispatch => {
    dispatch({
      type: Types.SET_PASSWORD
    })

    API.setPassword(token, userId, data)
      .then(response => {
        dispatch({
          type: Types.SET_PASSWORD_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: Types.SET_PASSWORD_ERROR,
          payload: error.response ? error.response.status : 500
        })
      })
  }
}
