import * as Types from '../Types'
import * as API from '../api/clinic'

export function tryGetProviderPatients(token, userId, providerId) {
  return dispatch => {
    dispatch({
      type: Types.GET_PATIETNS
    })

    API.getPatients(token, userId, providerId)
      .then(response => {
        dispatch({
          type: Types.GET_PATIETNS_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: Types.GET_PATIETNS_ERROR
        })
      })
  }
}
