import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CircularProgress from '@material-ui/core/CircularProgress'

import Paper from '../../../common/Paper'
import Button from '../../../common/Button'
import * as Forms from '../../../common/Forms'
import Inputs from '../../../common/Inputs'

import * as Styles from './styles'
import * as clinicsActions from '../../../../actions/medotemic/Clinics'
import * as configActions from '../../../../actions/Config'
import * as Constants from '../../../../helpers/Constants'
import * as Functions from '../../../../helpers/Functions'
import product from '../../../../helpers/Constants/product'
import strings from '../../../../helpers/I18NStrings/index'
import { checkPassword, checkUsername } from '../../../../helpers/Functions'

class NewClinic extends React.Component {
  state = {
    name: '',
    username: '',
    password: '',
    repeatPassword: '',
    newPassword: '',
    repeatNewPassword: '',
    maxPatients: '',
    price: '',
    expires: Functions.getDate(
      new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    ),
    orgNumber: '',
    address: '',
    city: '',
    email: '',
    phone: '',
    zipCode: ''
  }

  componentDidMount() {
    const { match, medotemicData, user } = this.props

    if (!medotemicData.clinics.fetched) {
      this.props.tryGetClinics(user.token, user.id)
    }

    if (this.clinicIsPresent() && match.params.clinicId) {
      const license = medotemicData.clinics.data[match.params.clinicId].license

      this.setState({
        ...medotemicData.clinics.data[match.params.clinicId],
        maxPatients: license ? license.maxPatients : '',
        price: license ? license.price : '',
        expires: license ? license.expires : ''
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { clinics, match, medotemicData } = this.props
    const edit = match.params.clinicId

    if (
      !medotemicData.clinics.fetched &&
      nextProps.medotemicData.clinics.fetched &&
      this.clinicIsPresent(nextProps) &&
      match.params.clinicId
    ) {
      const license =
        nextProps.medotemicData.clinics.data[match.params.clinicId].license

      this.setState({
        ...nextProps.medotemicData.clinics.data[match.params.clinicId],
        maxPatients: license ? license.maxPatients : '',
        price: license ? license.price : '',
        expires: license ? license.expires : ''
      })
    }

    if (
      !nextProps.clinics.isLoading &&
      clinics.isLoading &&
      nextProps.clinics.errorLoading === null
    ) {
      if (!edit) {
        this.setState({
          name: '',
          username: '',
          password: '',
          newPassword: '',
          repeatPassword: '',
          maxPatients: '',
          price: '',
          expires: Functions.getDate(
            new Date(new Date().setFullYear(new Date().getFullYear() + 1))
          ),
          orgNumber: '',
          address: '',
          city: '',
          email: '',
          phone: '',
          zipCode: ''
        })
      }

      this.setState({
        newPassword: '',
        repeatNewPassword: ''
      })

      this.props.showAlert(
        edit
          ? strings.TextClinicUpdated
          : strings.ClinicCreatedHeader,
        edit ? strings.TextClinicUpdated : strings.ClinicCreated,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => { } }
      )
    } else if (!nextProps.clinics.isLoading && clinics.isLoading) {
      if (nextProps.clinics.errorLoading === 409) {
        this.props.showAlert(
          strings.UsernameInUseHeader,
          strings.UsernameInUse,
          Constants.ONE_BUTTON,
          { title: strings.ButtonOK, onPress: () => { } }
        )
      } else {
        this.props.showAlert(
          strings.UnexpectedErrorHeader,
          strings.UnexpectedError,
          Constants.ONE_BUTTON,
          { title: strings.ButtonOK, onPress: () => { } }
        )
      }
    }

    if (
      !nextProps.clinics.isUpdatingUsername &&
      clinics.isUpdatingUsername &&
      nextProps.clinics.errorLoading === null
    ) {
      if (!edit) {
        this.setState({
          name: '',
          username: '',
          password: '',
          newPassword: '',
          repeatPassword: '',
          maxPatients: '',
          price: '',
          expires: Functions.getDate(
            new Date(new Date().setFullYear(new Date().getFullYear() + 1))
          ),
          orgNumber: '',
          address: '',
          city: '',
          email: '',
          phone: '',
          zipCode: ''
        })
      }

      this.setState({
        newUsername: ''
      })

      this.props.showAlert(
        strings.TextUpdatedUsernameTitle,
        strings.TextUpdatedUsername,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => { } }
      )
    } else if (!nextProps.clinics.isUpdatingUsername && clinics.isUpdatingUsername) {
      if (nextProps.clinics.errorLoading === 409) {
        this.props.showAlert(
          strings.UsernameInUseHeader,
          strings.UsernameInUse,
          Constants.ONE_BUTTON,
          { title: strings.ButtonOK, onPress: () => { } }
        )
      } else {
        this.props.showAlert(
          strings.UnexpectedErrorHeader,
          strings.UnexpectedError,
          Constants.ONE_BUTTON,
          { title: strings.ButtonOK, onPress: () => { } }
        )
      }
    }

    if (
      !nextProps.clinics.isUpdatingPassword &&
      clinics.isUpdatingPassword &&
      nextProps.clinics.errorLoading === null
    ) {
      if (!edit) {
        this.setState({
          name: '',
          username: '',
          password: '',
          newPassword: '',
          repeatPassword: '',
          maxPatients: '',
          price: '',
          expires: Functions.getDate(
            new Date(new Date().setFullYear(new Date().getFullYear() + 1))
          ),
          orgNumber: '',
          address: '',
          city: '',
          email: '',
          phone: '',
          zipCode: ''
        })
      }

      this.setState({
        password: '',
        newPassword: '',
        repeatPassword: '',
      })

      this.props.showAlert(
        strings.TextPasswordIsResetTitle,
        strings.TextPasswordIsReset,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => { } }
      )
    } else if (!nextProps.clinics.isUpdatingPassword && clinics.isUpdatingPassword) {
      this.props.showAlert(
        strings.UnexpectedErrorHeader,
        strings.UnexpectedError,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => { } }
      )
    }
  }

  clinicIsPresent(props = this.props) {
    const { match, medotemicData } = props

    if (match.params.clinicId) {
      if (
        !medotemicData ||
        !medotemicData.clinics.fetched ||
        !medotemicData.clinics.data[match.params.clinicId]
      ) {
        return false
      }
    }

    return true
  }

  onClick() {
    const { user, match } = this.props
    const {
      name,
      username,
      password,
      repeatPassword,
      maxPatients,
      price,
      expires,
      orgNumber,
      address,
      city,
      email,
      phone,
      zipCode
    } = this.state
    const edit = match.params.clinicId

    if (!edit) {
      if (!checkUsername(username, this.props)) return
      if (!checkPassword(password, repeatPassword, this.props)) return
    }

    if (!maxPatients ||
      !price ||
      !expires ||
      !name
    ) {
      this.props.showAlert(
        strings.InfoMissingHeader,
        strings.InfoMissing,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => { } }
      )
    } else {
      const data = {
        name,
        username,
        password,
        maxPatients,
        price,
        expires,
        orgNumber,
        address,
        city,
        email,
        phone,
        zipCode
      }

      if (edit) {
        this.props.tryUpdateClinic(
          user.token,
          user.id,
          match.params.clinicId,
          data
        )
      } else {
        this.props.tryCreateClinic(user.token, user.id, data)
      }
    }
  }

  onUpdateLicense() {
    const { user, match } = this.props
    const { maxPatients, price, expires } = this.state

    if (!maxPatients || !price || !expires) {
      this.props.showAlert(
        strings.InfoMissingHeader,
        strings.InfoMissing,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => { } }
      )
    } else {
      const data = { maxPatients, price, expires }

      this.props.tryUpdateLicense(
        user.token,
        user.id,
        match.params.clinicId,
        data
      )
      this.props.showAlert(
        strings.LicenseUpdatedHeader,
        strings.LicenseUpdated,
        Constants.ONE_BUTTON,
        {
          title: strings.ButtonOK,
          onPress: () => { }
        })
    }
  }

  onUpdatePassword() {
    const { user, match } = this.props
    const { newPassword, repeatNewPassword } = this.state

    if (!checkPassword(newPassword, repeatNewPassword, this.props)) return

    const data = newPassword

    this.props.tryUpdateClinicPassword(
      user.token,
      user.id,
      match.params.clinicId,
      data
    )
  }

  onUpdateUsername() {
    const { user, match } = this.props
    const { newUsername } = this.state

    if (!checkUsername(newUsername, this.props)) {
      const data = newUsername

      this.props.tryUpdateClinicUsername(
        user.token,
        user.id,
        match.params.clinicId,
        data
      )
    }
  }

  render() {
    const { classes, clinics, history, match, medotemicData } = this.props
    const edit = match.params.clinicId

    if (!medotemicData.clinics.fetched) {
      return (
        <main style={Styles.default.loaderContainer}>
          <CircularProgress
            style={{ color: product.mainColor }}
            thickness={2}
            size={82}
          />
        </main>
      )
    }

    return (
      <main className={classes.content}>
        <p class={'header'}>
          {edit ? strings.TextUpdateClinic : strings.TextNewClinic}
        </p>
        <div className={classes.providerIdContainer}>
          <p class={'secondHeader'}>{strings.TextID}</p>
          <p
            class={'secondHeader'}
            style={{ color: '#F26363', marginLeft: 25 }}
          >
            {edit
              ? match.params.clinicId
              : strings.TextAutoGen}
          </p>
        </div>

        {edit ? <p class={'secondHeader'}>{strings.TextUserInfo}</p> : null}
        <Paper style={{ alignItems: 'flex-start' }}>
          <Forms.Clinic
            classes={classes}
            edit={edit}
            inputs={this.state}
            onChange={(key, text) => this.setState({ [key]: text })}
          />
        </Paper>

        <div className={classes.buttonContainer}>
          <Button
            className={'success'}
            onClick={() => this.onClick()}
            loading={clinics.isLoading}
            header={edit ? strings.TextUpdate : strings.ButtonCreate}
          />
        </div>

        {edit ? (
          <div>
            <p class={'secondHeader'}>{strings.TextLicense}</p>
            <Paper style={{ alignItems: 'flex-start', marginTop: 24 }}>
              <Forms.License
                classes={classes}
                edit={edit}
                inputs={this.state}
                onChange={(key, text) => this.setState({ [key]: text })}
              />
            </Paper>

            <div className={classes.buttonContainer}>
              <Button
                className={'success'}
                onClick={() => this.onUpdateLicense()}
                loading={clinics.isUpdatingLicense}
                header={strings.TextUpdateLicense}
              />
            </div>
          </div>
        ) : null}

        {edit ? (
          <div>
            <p class={'secondHeader'}>{strings.TextUpdateUsername}</p>
            <Paper style={{ alignItems: 'flex-start', marginTop: 24 }}>
              <Forms.Username
                classes={classes}
                edit={edit}
                inputs={this.state}
                onChange={(key, text) => this.setState({ [key]: text })}
              />
            </Paper>

            <div className={classes.buttonContainer}>
              <Button
                className={'success'}
                onClick={() => this.onUpdateUsername()}
                loading={clinics.isLoading}
                header={strings.TextUpdateUsername}
              />
            </div>
          </div>
        ) : null}

        {edit ? (
          <div>
            <p class={'secondHeader'}>{strings.TextResetPassword}</p>
            <Paper style={{ alignItems: 'flex-start', marginTop: 24 }}>
              <Forms.Password
                classes={classes}
                edit={edit}
                inputs={this.state}
                onChange={(key, text) => this.setState({ [key]: text })}
              />
            </Paper>

            <div className={classes.buttonContainer}>
              <Button
                className={'success'}
                onClick={() => this.onUpdatePassword()}
                loading={clinics.isLoading}
                header={strings.TextResetPassword}
              />
            </div>
          </div>
        ) : null}
      </main>
    )
  }
}

const Classes = theme => ({
  innerContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
    paddingTop: 88
  },
  providerIdContainer: {
    display: 'flex'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

NewClinic.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps({ config, user, clinics, medotemicData }) {
  return { config, user, clinics, medotemicData }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...configActions, ...clinicsActions }, dispatch)
}

export default compose(
  withStyles(Classes),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NewClinic)
