import * as Types from '../actions/Types'
import * as Constants from '../helpers/Constants'

const INITIAL_STATE = {
  isSigningIn: false,
  errorSignInStatus: null,
  signedIn: false,
  isValidatingToken: true,
  errorValidatingToken: false,
  token: null,
  id: null,
  type: null,
  forceNewPassword: false,
  isSettingNewPassword: false
}

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action

  switch (type) {
  case Types.SIGN_IN:
    return {
      ...state,
      isSigningIn: true,
      errorSignInStatus: null
    }
  case Types.SIGN_IN_SUCCESS:
    return {
      ...state,
      isSigningIn: false,
      errorSignInStatus: null,
      signedIn: true,
      token: payload.token,
      id: payload.id,
      type: Constants.HEALTHCARE_PROVIDER,
      forceNewPassword: payload.forceNewPassword
    }
  case Types.SIGN_IN_SUCCESS_PATIENT:
    return {
      ...state,
      isSigningIn: false,
      errorSignInStatus: null,
      signedIn: true,
      token: payload.token,
      id: payload.id,
      type: Constants.PATIENT
    }
  case Types.SIGN_IN_SUCCESS_CLINIC:
    return {
      ...state,
      isSigningIn: false,
      errorSignInStatus: null,
      signedIn: true,
      token: payload.token,
      id: payload.id,
      type: Constants.CLINIC,
      forceNewPassword: payload.forceNewPassword
    }
  case Types.SIGN_IN_ERROR:
    return {
      ...state,
      isSigningIn: false,
      errorSignInStatus: payload
    }
  case Types.VALIDATE_TOKEN:
    return {
      ...state,
      isValidatingToken: true,
      errorValidatingToken: false
    }
  case Types.VALIDATE_TOKEN_SUCCESS:
    return {
      ...state,
      isValidatingToken: false,
      errorValidatingToken: false,
      signedIn: true,
      token: payload.token,
      id: payload.id,
      type: Constants.HEALTHCARE_PROVIDER,
      forceNewPassword: payload.forceNewPassword
    }
  case Types.VALIDATE_TOKEN_SUCCESS_PATIENT:
    return {
      ...state,
      isValidatingToken: false,
      errorValidatingToken: false,
      signedIn: true,
      token: payload.token,
      id: payload.id,
      type: Constants.PATIENT
    }
  case Types.VALIDATE_TOKEN_SUCCESS_CLINIC:
    return {
      ...state,
      isValidatingToken: false,
      errorValidatingToken: null,
      signedIn: true,
      token: payload.token,
      id: payload.id,
      type: Constants.CLINIC,
      forceNewPassword: payload.forceNewPassword
    }
  case Types.VALIDATE_TOKEN_SUCCESS_MEDOTEMIC:
    return {
      ...state,
      isValidatingToken: false,
      errorValidatingToken: null,
      signedIn: true,
      token: payload.token,
      id: payload.id,
      type: Constants.MEDOTEMIC
    }
  case Types.VALIDATE_TOKEN_ERROR:
    return {
      ...state,
      isValidatingToken: false,
      errorValidatingToken: true
    }
  case Types.SET_PASSWORD:
    return {
      ...state,
      isSettingNewPassword: true
    }
  case Types.SET_PASSWORD_SUCCESS:
    return {
      ...state,
      forceNewPassword: false,
      isSettingNewPassword: false
    }
  case Types.SET_PASSWORD_ERROR:
    return {
      ...state,
      isSettingNewPassword: false
    }
  case Types.SIGN_OUT_SUCCESS:
    return {
      ...state,
      isSigningIn: false,
      errorSignInStatus: null,
      isValidatingToken: false,
      errorValidatingToken: false,
      signedIn: false,
      token: null,
      id: null
    }
  default:
    return state
  }
}
