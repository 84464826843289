import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Paper from '../../../common/Paper'
import Button from '../../../common/Button'
import GoBack from '../../../common/GoBack'

import * as patientsActions from '../../../../actions/Patients'
import * as configActions from '../../../../actions/Config'
import * as Constants from '../../../../helpers/Constants'
import strings from '../../../../helpers/I18NStrings/index'

class ImportUser extends React.Component {
  state = { code: '' }

  componentWillReceiveProps(nextProps) {
    const { patients } = this.props

    if (
      !nextProps.patients.isImporting &&
      patients.isImporting &&
      nextProps.patients.errorImportingStatus === null
    ) {
      this.props.showAlert(
        strings.PatientImportedHeader,
        strings.PatientImported,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => {} }
      )

      this.setState({ code: '' })
    } else if (!nextProps.patients.isImporting && patients.isImporting) {
      if (nextProps.patients.errorImportingStatus === 404) {
        this.props.showAlert(
          strings.CodeNoExistHeader,
          strings.CodeNoExist,
          Constants.ONE_BUTTON,
          { title: strings.ButtonOK, onPress: () => {} }
        )
      } else {
        this.props.showAlert(
          strings.UnexpectedErrorHeader,
          strings.UnexpectedError,
          Constants.ONE_BUTTON,
          { title: strings.ButtonOK, onPress: () => {} }
        )
      }
    }
  }

  import() {
    const { code } = this.state
    const { user } = this.props

    if (!code) {
      this.props.showAlert(
        strings.InfoMissingHeader,
        strings.InfoMissing,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => {} }
      )
    } else {
      this.props.tryImportPatient(user.token, user.id, code)
    }
  }

  render() {
    const { code } = this.state
    const { classes, patients, history, match } = this.props

    return (
      <main className={classes.content}>
        <GoBack
          history={history}
          show={match.params.providerId}
          title={strings.TextBackToClinicView}
        />
        <p className={'header'}>{strings.TextImportPatient}</p>

        <Paper style={{ alignItems: 'flex-start', marginTop: 24 }}>
          <div className={classes.innerContainer}>
            <p style={{ width: 150 }}>
              {strings.TextCode}:<span className={'mandatoryStar'}>*</span>
            </p>
            <input
              placeholder={strings.TextCode}
              className={'noMargin'}
              style={{ marginTop: 0, marginLeft: 100 }}
              onChange={event => this.setState({ code: event.target.value })}
              value={code}
            />
          </div>
        </Paper>

        <div className={classes.buttonContainer}>
          <Button
            className={'success'}
            onClick={() => this.import()}
            loading={patients.isImporting}
            header={strings.TextImportPatient}
          />
        </div>
      </main>
    )
  }
}

const Styles = theme => ({
  innerContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
    paddingTop: 88
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

ImportUser.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps({ config, user, patients }) {
  return { config, user, patients }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...configActions, ...patientsActions }, dispatch)
}

export default compose(
  withStyles(Styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ImportUser)
