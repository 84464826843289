const Styles = {
  listItem: {
    width: 25,
    height: 25
  },
  iconContainer: {
    display: 'flex',
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    fontSize: 32,
    color: 'rgba(0, 0, 0, 0.87)',
    width: 25,
    height: 25
  },
  text: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 'bold',
    textAlign: 'center'
  }
}

export default Styles
