import Axios from 'axios'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8080/api/v1/'
    : 'https://portal.medotemic.com/api/v1/'

export function createProvider(token, userId, data) {
  const url = BASE_URL + 'clinicAdmin/' + userId + '/add/provider'

  return Axios.post(url, data, getConfig(token))
}

export function getProviders(token, userId) {
  const url = BASE_URL + 'clinicAdmin/' + userId + '/providers'

  return Axios.get(url, getConfig(token))
}

export function getPatients(token, userId, providerId) {
  const url = `${BASE_URL}clinicAdmin/${userId}/providers/${providerId}/patients`

  return Axios.get(url, getConfig(token))
}

export function getClinic(token, userId) {
  const url = `${BASE_URL}clinicAdmin/${userId}`

  return Axios.get(url, getConfig(token))
}

export function updateClinic(token, userId, data) {
  const url = `${BASE_URL}clinicAdmin/${userId}`

  return Axios.patch(url, data, getConfig(token))
}

export function setPassword(token, userId, data) {
  const url = `${BASE_URL}clinicAdmin/${userId}/password`

  return Axios.patch(url, data, getConfig(token))
}

export function ChangeProviderUsername(token, userId, data) {
  const url = `${BASE_URL}clinicAdmin/${userId}/changeProviderUsername`

  return Axios.post(url, {}, {
    headers: {
      authentication: 'Bearer ' + token,
      username: data.username,
      providerId: data.providerId,
    }
  })
}

export function ChangeProviderPassword(token, userId, data) {
  const url = `${BASE_URL}clinicAdmin/${userId}/changeProviderPassword`

  return Axios.post(url, {}, {
    headers: {
      authentication: 'Bearer ' + token,
      password: data.password,
      providerId: data.providerId,
    }
  })
}

function getConfig(token) {
  return { headers: { authentication: 'Bearer ' + token } }
}
