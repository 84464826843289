import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Alert from '../../containers/Alert'
import FullscreenLoader from '../../common/FullscreenLoader'
import Button from '../../common/Button'
import ScrollDialog from '../../common/ScrollDialog'

import * as configActions from '../../../actions/Config'
import * as userActions from '../../../actions/User'
import * as Styles from './styles'
import * as Constants from '../../../helpers/Constants'
import products from '../../../helpers/Constants/product'
import Inputs from '../../common/Inputs'
import strings from '../../../helpers/I18NStrings/index'

class SignIn extends React.Component {
  state = {
    username: '',
    password: '',
    showTermsOfUse: false
  }

  enterHandler = this.enterHandler.bind(this)

  componentDidMount() {
    const { user } = this.props

    this.props.tryGetTermsOfUse()

    window.addEventListener('keypress', this.enterHandler)

    if (user.signedIn) {
      this.navigateToSignedIn()
    } else if (user.isValidatingToken) {
      this.props.tryValidateToken()
    }
  }

  componentWillReceiveProps(nextProps) {
    const { history, user } = this.props

    if (nextProps.user.signedIn && !user.signedIn) {
      this.navigateToSignedIn(nextProps)
    }

    if (
      !nextProps.user.isSigningIn &&
      user.isSigningIn &&
      nextProps.user.errorSignInStatus !== null
    ) {
      if (nextProps.user.errorSignInStatus === 456) {
        this.props.showAlert(
          products.useOtherWebHeader,
          products.useOtherWeb,
          Constants.TWO_BUTTONS_SUCCESS,
          { title: strings.ButtonOK, onPress: () => {} },

          {
            title: products.useOtherWebButton,
            onPress: () => window.open(products.useOtherWebLink, '_blank')
          }
        )
      } else if (nextProps.user.errorSignInStatus === 401) {
        this.props.showAlert(
          strings.InvalidCredentialsHeader,
          strings.InvalidCredentials,
          Constants.ONE_BUTTON,
          { title: strings.ButtonOK, onPress: () => {} }
        )
      } else {
        this.props.showAlert(
          strings.UnexpectedErrorHeader,
          strings.UnexpectedError,
          Constants.ONE_BUTTON,
          { title: strings.ButtonOK, onPress: () => {} }
        )
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keypress', this.enterHandler)
  }

  enterHandler(e) {
    const { config } = this.props

    if (e.charCode === 13) {
      if (config.alert.show) {
        this.props.hideAlert()
      } else {
        this.signIn()
      }
    }
  }

  navigateToSignedIn(props = this.props) {
    const { user, history } = props

    if (user.type === Constants.HEALTHCARE_PROVIDER) {
      history.push('/patients')
    } else if (user.type === Constants.PATIENT) {
      history.push('/results')
    } else if (user.type === Constants.CLINIC) {
      history.push('/providers')
    } else {
      history.push('/clinics')
    }
  }

  signIn() {
    const { username, password } = this.state

    if (username === '' && password === '') {
      this.props.showAlert(
        strings.UsernameOrPasswordMissingHeader,
        strings.UsernameOrPasswordMissing,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => {} }
      )
    } else {
      this.props.trySignIn(username, { password })
    }
  }

  render() {
    const { username, password, showTermsOfUse } = this.state
    const { user, config } = this.props
    const termsOfUse = []

    if (user.isValidatingToken) {
      return <FullscreenLoader />
    }

    if (config.termsOfUse.fetched) {
      config.termsOfUse.data.termsOfUse.split('\n').forEach((t, index) => {
        termsOfUse.push(
          <p key={index} style={Styles.default.termsText}>
            {t}
          </p>
        )
      })
    }

    return (
      <div className={'signInContainer'} style={Styles.default.container}>
        <div style={Styles.default.signInContainer}>
          <img src={products.bigLogo} alt={strings.MedoClinic} style={{height: 150, padding: 30}}/>
          <Inputs
            placeholder={strings.TextUsername}
            style={{ marginLeft: 0 }}
            onChange={event => this.setState({ username: event.target.value })}
            value={username}
          />
          <Inputs
            placeholder={strings.TextPassword}
            style={{ marginLeft: 0, marginTop: 5 }}
            onChange={event => this.setState({ password: event.target.value })}
            value={password}
            type={'password'}
          />
          <Button
            className={'success'}
            onClick={() => this.signIn()}
            loading={user.isSigningIn}
            header={strings.ButtonSignIn}
          />

          <a
            style={{ textDecoration: 'underline', marginTop: 24 }}
            onClick={() => this.setState({ showTermsOfUse: true })}
          >
            {strings.TextTermsOfUse}
          </a>
        </div>
        <ScrollDialog
          show={showTermsOfUse}
          header={strings.TextTermsOfUse}
          children={termsOfUse}
          onClose={() => this.setState({ showTermsOfUse: false })}
        />
      </div>
    )
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps({ config, user }) {
  return { config, user }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...configActions, ...userActions }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn)
