const Styles = {
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 24
  },
  innerFilterContainer: {},
  chartContainer: {
    display: 'flex',
    marginTop: 24
  },
  usersContainer: {
    backgroundColor: 'white',
    width: 260,
    height: '100vh',
    boxShadow:
      'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    alignItems: 'center',
    flexDirection: 'column',
    overflowY: 'auto',
    paddingTop: 88
  },
  input: {
    width: '100%',
    marginTop: 0,
    marginLeft: 24,
    marginBottom: 24
  },
  loaderContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  }
}

export default Styles
