import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MatButton from '@material-ui/core/Button'
import {
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons'

import Paper from '../../../common/Paper'
import Checkbox from '../../../common/Checkbox'
import RadioButton from '../../../common/RadioButton'
import Button from '../../../common/Button'
import Modal from '../../../common/Modal'
import GoBack from '../../../common/GoBack'
import Inputs from '../../../common/Inputs'

import * as Styles from './styles'
import * as Forms from '../../../common/Forms'
import * as patientsActions from '../../../../actions/Patients'
import * as configActions from '../../../../actions/Config'
import * as clinicPatientsActions from '../../../../actions/clinic/Patients'
import * as Constants from '../../../../helpers/Constants'
import product from '../../../../helpers/Constants/product'
import strings from '../../../../helpers/I18NStrings/index'
import { checkPassword, checkUsername } from '../../../../helpers/Functions'

class UserSettings extends React.Component {
  state = {
    gender: '',
    length: '',
    patientTypes: '',
    backgroundForTroubles: {},
    reasonsForProblems: {},
    problemPlacements: {},
    changePatient: false,
    newPassword: null,
    repeatNewPassword: null,
    username: '',
  }

  componentDidMount() {
    const { user, data, match } = this.props

    if (match.params.providerId) {
      this.props.tryGetProviderPatients(
        user.token,
        user.id,
        match.params.providerId
      )
    } else if (!data.patients.fetched) {
      this.props.tryGetPatients(user.token, user.id)
    } else if (match.params.patientId) {
      const patient = data.patients.data[match.params.patientId]

      if (patient) {
        const backgroundForTroubles = {}
        const reasonsForProblems = {}
        const problemPlacements = {}

        Constants.BACKGROUND_FOR_TROUBLES.forEach(t => {
          backgroundForTroubles[t.id] = patient.patientProfile[t.id] || false
        })

        Constants.REASONS_FOR_PROBLEMS.forEach(t => {
          reasonsForProblems[t.id] = patient.patientProfile[t.id] || false
        })

        Constants.PROBLEM_PLACEMENTS.forEach(t => {
          problemPlacements[t.id] = patient.patientProfile[t.id] || false
        })

        this.setState({
          gender: patient.patientProfile.gender,
          length: patient.patientProfile.length || '',
          patientTypes: patient.types,
          backgroundForTroubles,
          reasonsForProblems,
          problemPlacements,
        })
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { patients, match, data } = this.props

    if (
      nextProps.match.params.patientId &&
      ((nextProps.match.params.patientId !== match.patientId &&
        nextProps.data.patients.fetched) ||
        (!data.patients.fetched && nextProps.data.patients.fetched))
    ) {
      const patient =
        nextProps.data.patients.data[nextProps.match.params.patientId]

      const backgroundForTroubles = {}
      const reasonsForProblems = {}
      const problemPlacements = {}

      Constants.BACKGROUND_FOR_TROUBLES.forEach(t => {
        backgroundForTroubles[t.id] = patient.patientProfile[t.id] || false
      })

      Constants.REASONS_FOR_PROBLEMS.forEach(t => {
        reasonsForProblems[t.id] = patient.patientProfile[t.id] || false
      })

      Constants.PROBLEM_PLACEMENTS.forEach(t => {
        problemPlacements[t.id] = patient.patientProfile[t.id] || false
      })

      this.setState({
        gender: patient.patientProfile.gender,
        length: patient.patientProfile.length || '',
        patientTypes: patient.types,
        backgroundForTroubles,
        reasonsForProblems,
        problemPlacements,
      })
    }

    if (
      !nextProps.patients.isUpdating &&
      patients.isUpdating &&
      nextProps.patients.errorUpdatingStatus === null
    ) {
      this.props.showAlert(
        strings.PatientUpdatedHeader,
        strings.PatientUpdated,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => { } }
      )
    } else if (!nextProps.patients.isUpdating && patients.isUpdating) {
      this.props.showAlert(
        strings.UnexpectedErrorHeader,
        strings.UnexpectedError,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => { } }
      )
    }

    if (
      !nextProps.patients.isChangingPassword &&
      patients.isChangingPassword &&
      nextProps.patients.errorChangingPassword === null
    ) {

      this.setState({
        newPassword: '',
        repeatNewPassword: '',
      })

      this.props.showAlert(
        strings.TextPasswordIsResetTitle,
        strings.TextPasswordIsReset,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => { } }
      )
    } else if (!nextProps.patients.isChangingPassword && patients.isChangingPassword) {
      this.props.showAlert(
        strings.UnexpectedErrorHeader,
        strings.UnexpectedError,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => { } }
      )
    }

    if (
      !nextProps.patients.isChangingUsername &&
      patients.isChangingUsername &&
      nextProps.patients.errorChangingUsername === null
    ) {

      this.setState({
        newUsername: ''
      })

      this.props.showAlert(
        strings.TextUpdatedUsernameTitle,
        strings.TextUpdatedUsername,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => { } }
      )
      this.setState({ username: '' })
    } else if (!nextProps.patients.isChangingUsername && patients.isChangingUsername) {
      if (nextProps.clinics.errorLoading === 409) {
        this.props.showAlert(
          strings.UsernameInUseHeader,
          strings.UsernameInUse,
          Constants.ONE_BUTTON,
          { title: strings.ButtonOK, onPress: () => {} }
        )
      } else {
        this.props.showAlert(
          strings.UnexpectedErrorHeader,
          strings.UnexpectedError,
          Constants.ONE_BUTTON,
          { title: strings.ButtonOK, onPress: () => { } }
        )
      }
    }
  }

  hasTrue(object) {
    let has = false

    Object.keys(object).forEach(key => {
      if (object[key]) {
        has = true
      }
    })

    return has
  }

  tryUpdate() {
    const {
      gender,
      length,
      patientTypes,
      problemPlacements,
      reasonsForProblems,
      backgroundForTroubles
    } = this.state

    if (
      gender === null ||
      length === '' ||
      patientTypes.length === 0 ||
      !this.hasTrue(backgroundForTroubles) ||
      !this.hasTrue(reasonsForProblems) ||
      !this.hasTrue(problemPlacements)
    ) {
      this.props.showAlert(
        strings.InfoMissingHeader,
        strings.InfoMissing,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => { } }
      )
    } else {
      this.update()
    }
  }

  tryResetPassword() {
    const { user, patients, match } = this.props
    const {
      newPassword,
      repeatNewPassword,
    } = this.state

    if (!checkPassword(newPassword, repeatNewPassword, this.props)) return

    const data = { password: newPassword }

    this.props.tryUpdatePatientPassword(
      user.token,
      user.id,
      match.params.patientId,
      data
    )
  }

  tryChangeUsername() {
    const { user, patients, match } = this.props
    const {
      username,
    } = this.state

    if (!checkUsername(username, this.props)) return

    const data = { username }

    this.props.tryUpdatePatientUsername(
      user.token,
      user.id,
      match.params.patientId,
      data
    )
  }

  update() {
    const { user, patients, match } = this.props
    const {
      gender,
      patientTypes,
      length,
      backgroundForTroubles,
      reasonsForProblems,
      problemPlacements,
    } = this.state

    const data = {
      patientTypes,
      profile: {
        gender,
        length,
        ...backgroundForTroubles,
        ...reasonsForProblems,
        ...problemPlacements,
      }
    }

    this.props.tryUpdatePatient(
      user.token,
      user.id,
      match.params.patientId,
      data
    )
  }

  renderPatientTypes() {
    const { patientTypes } = this.state

    return Constants.PATIENT_TYPES.map(type => {
      const value = patientTypes.indexOf(type.id) !== -1
      const checked = value ? value : false

      return (
        <Checkbox
          key={type.id}
          label={type.value}
          style={{ width: 250 }}
          checked={checked}
          onChange={() => {
            if (checked) {
              this.setState({
                patientTypes: patientTypes.filter(id => id !== type.id)
              })
            } else {
              this.setState({ patientTypes: [...patientTypes, type.id] })
            }
          }}
        />
      )
    })
  }

  renderProblemPlacements() {
    const { problemPlacements } = this.state

    return Constants.PROBLEM_PLACEMENTS.map(placement => {
      const value = problemPlacements[placement.id]
      const checked = value ? value : false

      return (
        <Checkbox
          key={placement.id}
          label={placement.value}
          style={{ width: 250 }}
          checked={checked}
          onChange={() => {
            this.setState({
              problemPlacements: {
                ...problemPlacements,
                [placement.id]: !checked
              }
            })
          }}
        />
      )
    })
  }

  renderBackgrounds() {
    const { backgroundForTroubles } = this.state

    return Constants.BACKGROUND_FOR_TROUBLES.map(background => {
      const value = backgroundForTroubles[background.id]
      const checked = value ? value : false

      return (
        <Checkbox
          key={background.id}
          label={background.value}
          style={{ width: 250 }}
          checked={checked}
          onChange={() =>
            this.setState({
              backgroundForTroubles: {
                ...backgroundForTroubles,
                [background.id]: !checked
              }
            })
          }
        />
      )
    })
  }

  renderReasonsForProblems() {
    const { reasonsForProblems } = this.state

    return Constants.REASONS_FOR_PROBLEMS.map(reason => {
      const value = reasonsForProblems[reason.id]
      const checked = value ? value : false

      return (
        <Checkbox
          key={reason.id}
          label={reason.value}
          style={{ width: 250 }}
          checked={checked}
          onChange={() =>
            this.setState({
              reasonsForProblems: {
                ...reasonsForProblems,
                [reason.id]: !checked
              }
            })
          }
        />
      )
    })
  }

  renderContent() {
    const { gender, length, username } = this.state
    const { classes, patients, history, match, data, user } = this.props

    if (match.params.patientId) {
      const patient = data.patients.data[match.params.patientId]

      if (patient) {
        return (
          <div>
            <GoBack
              history={history}
              show={match.params.providerId}
              title={strings.TextBackToClinicView}
            />
            <p
              className={'header'}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer'
              }}
              onClick={() => this.setState({ changePatient: true })}
            >
              {strings.TextPatient + ' '}
              <span style={{ color: product.mainColor, marginLeft: 12 }}>
                {match.params.patientId}
              </span>
              <FontAwesomeIcon
                icon={faChevronDown}
                style={{ marginLeft: 12, fontSize: 24 }}
              />
            </p>

            <Button
              className={'success'}
              onClick={() =>
                user.type === Constants.CLINIC
                  ? history.push(
                    `/providers/${match.params.providerId}/patients/${
                    match.params.patientId
                    }/statistics`
                  )
                  : history.push(
                    `/patients/${match.params.patientId}/statistics`
                  )
              }
              header={strings.TextResults}
            />
            <Button
              style={{ marginLeft: 24 }}
              className={'success'}
              onClick={() =>
                user.type === Constants.CLINIC
                  ? history.push(
                    `/providers/${match.params.providerId}/patients/${
                    match.params.patientId
                    }/goals`
                  )
                  : history.push(`/patients/${match.params.patientId}/goals`)
              }
              header={strings.TextTargets}
            />

            <Paper style={{ alignItems: 'flex-start', marginTop: 24 }}>
              <div
                className={classes.innerContainer}
                style={{ alignItems: 'center' }}
              >
                <p style={{ width: 150 }}>
                  {strings.TextGender}:
                  <span className={'mandatoryStar'}>*</span>
                </p>
                <div className={classes.radioContainer}>
                  <RadioButton
                    label={strings.TextMan}
                    checked={gender === Constants.MAN}
                    onChange={() => this.setState({ gender: Constants.MAN })}
                    style={{ width: 250 }}
                  />
                  <RadioButton
                    label={strings.TextWoman}
                    checked={gender === Constants.WOMAN}
                    onChange={() => this.setState({ gender: Constants.WOMAN })}
                    style={{ width: 250 }}
                  />
                  <RadioButton
                    label={strings.TextOther}
                    checked={gender === Constants.OTHER_GENDER}
                    onChange={() =>
                      this.setState({ gender: Constants.OTHER_GENDER })
                    }
                    style={{ width: 250 }}
                  />
                </div>
              </div>
              <div className={classes.innerContainer}>
                <p style={{ width: 150 }}>
                  {strings.TextLenght}:
                  <span className={'mandatoryStar'}>*</span>
                </p>
                <Inputs
                  placeholder={strings.TextLenght}
                  onChange={event =>
                    this.setState({ length: event.target.value })
                  }
                  value={length}
                />
              </div>
            </Paper>

            <Paper style={{ alignItems: 'flex-start', marginTop: 24 }}>
              <div className={classes.innerContainer}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <p style={{ width: 150 }}>
                    {strings.TextPatientType}:
                    <span className={'mandatoryStar'}>*</span>
                  </p>
                  <p className={'hint'} style={{ width: 150 }}>
                    {strings.TextSelectOneOrMore}
                  </p>
                </div>
                <div className={classes.patientTypesContainer}>
                  {this.renderPatientTypes()}
                </div>
              </div>
            </Paper>

            <Paper style={{ alignItems: 'flex-start', marginTop: 24 }}>
              <div className={classes.innerContainer}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <p style={{ width: 150 }}>
                    {strings.TextBgToProblems}:
                    <span className={'mandatoryStar'}>*</span>
                  </p>
                  <p className={'hint'} style={{ width: 150 }}>
                    {strings.TextSelectOneOrMore}
                  </p>
                </div>
                <div className={classes.patientTypesContainer}>
                  {this.renderBackgrounds()}
                </div>
              </div>
            </Paper>

            <Paper style={{ alignItems: 'flex-start', marginTop: 24 }}>
              <div className={classes.innerContainer}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <p style={{ width: 150 }}>
                    {strings.TextCauseOfProblems}:
                    <span className={'mandatoryStar'}>*</span>
                  </p>
                  <p className={'hint'} style={{ width: 150 }}>
                    {strings.TextSelectOneOrMore}
                  </p>
                </div>
                <div className={classes.patientTypesContainer}>
                  {this.renderReasonsForProblems()}
                </div>
              </div>
            </Paper>

            <Paper style={{ alignItems: 'flex-start', marginTop: 24 }}>
              <div className={classes.innerContainer}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <p style={{ width: 150 }}>
                    {strings.TextWhereIsTheProblems}
                    <span className={'mandatoryStar'}>*</span>
                  </p>
                </div>
                <div className={classes.patientTypesContainer}>
                  {this.renderProblemPlacements()}
                </div>
              </div>
            </Paper>

            <div className={classes.buttonContainer}>
              <Button
                className={'success'}
                onClick={() => this.tryUpdate()}
                loading={patients.isUpdating}
                header={strings.TextUpdate}
              />
            </div>
            {patient.user &&
              <div>
                <Paper style={{ alignItems: 'flex-start', marginTop: 24 }}>
                  <div className={classes.innerContainer}>
                    <div className={classes.innerContainer}>
                      <p style={{ width: 150 }}>
                        {"Användarnamn"}:
                    <span className={'mandatoryStar'}>*</span>
                      </p>
                      <Inputs
                        placeholder={"Användarnamn"}
                        onChange={event =>
                          this.setState({ username: event.target.value })
                        }
                        value={username}
                      />
                    </div>
                  </div>
                </Paper>

                <div className={classes.buttonContainer}>
                  <Button
                    className={'success'}
                    onClick={() => this.tryChangeUsername()}
                    loading={patients.isChangingUsername}
                    header={"Uppdatera användarnamn"}
                  />
                </div>

                <Paper style={{ alignItems: 'flex-start', marginTop: 24 }}>
                  <Forms.Password
                    classes={classes}
                    inputs={this.state}
                    onChange={(key, text) => this.setState({ [key]: text })}
                  />
                </Paper>

                <div className={classes.buttonContainer}>
                  <Button
                    className={'success'}
                    onClick={() => this.tryResetPassword()}
                    loading={patients.isChangingPassword}
                    header={"Återställ lösenord"}
                  />
                </div>
              </div>
            }
          </div>
        )
      }
    }
  }

  render() {
    const { gender, length, changePatient } = this.state
    const { classes, history, match, data, user } = this.props

    if (!data.patients.fetched) {
      return (
        <main style={Styles.default.loaderContainer}>
          <GoBack
            history={history}
            show={match.params.providerId}
            title={strings.TextBackToClinicView}
          />
          <CircularProgress
            style={{ color: product.mainColor }}
            thickness={2}
            size={82}
          />
        </main>
      )
    } else if (Object.keys(data.patients.data).length === 0) {
      return (
        <main style={Styles.default.loaderContainer}>
          <GoBack
            history={history}
            show={match.params.providerId}
            title={strings.TextBackToClinicView}
          />
          <p>{strings.TextNoPatientCreated}</p>
        </main>
      )
    }

    const patients = []

    Object.keys(data.patients.data).forEach(key => {
      patients.push(data.patients.data[key])
    })

    return (
      <main className={classes.content}>
        {this.renderContent()}
        <Modal
          show={
            !match.params.patientId ||
            !data.patients.data[match.params.patientId] ||
            changePatient
          }
          header={strings.TextChoosePatient}
          buttons={
            !match.params.patientId ? null : (
              <MatButton
                onClick={() => this.setState({ changePatient: false })}
                className={classes.successButtonText}
              >
                {strings.TextCancel}
              </MatButton>
            )
          }
        >
          {patients.map(p => {
            const selected = p.id == match.params.patientId

            let textStyles = {}

            if (selected) {
              textStyles = { color: product.mainColor, fontWeight: 'bold' }
            }

            return (
              <div
                key={p.id}
                style={{
                  paddingTop: 6,
                  paddingBottom: 6,
                  borderBottom: '1px solid #dddfe2',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  user.type === Constants.CLINIC
                    ? history.push(
                      `/providers/${match.params.providerId}/patients/${
                      p.id
                      }/settings`
                    )
                    : history.push(`/patients/${p.id}/settings`)

                  this.setState({ changePatient: false })
                }}
              >
                <p style={textStyles}>{strings.TextPatient + ' ' + p.id}</p>
              </div>
            )
          })}
        </Modal>
      </main>
    )
  }
}

const Classes = theme => ({
  innerContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  radioContainer: {
    marginLeft: 100
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
    paddingTop: 88
  },
  patientIdContainer: {
    display: 'flex'
  },
  patientTypesContainer: {
    marginLeft: 100
  },
  textField: {
    marginLeft: 100
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

UserSettings.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps({ config, user, patients, data }) {
  return { config, user, patients, data }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...configActions, ...patientsActions, ...clinicPatientsActions },
    dispatch
  )
}

export default compose(
  withStyles(Classes),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserSettings)
