import * as Types from './Types'
import * as API from './api/provider'

export function tryDeleteTrainings(token, userId, patientId, ids) {
  return dispatch => {
    dispatch({
      type: Types.DELETE_TRAININGS
    })

    Promise.all(ids.map(id => API.deleteTraining(token, userId, patientId, id)))
      .then(response => {
        dispatch({
          type: Types.DELETE_TRAININGS_SUCCESS,
          payload: { patientId, ids }
        })
      })
      .catch(error => {
        dispatch({
          type: Types.DELETE_TRAININGS_ERROR,
          payload: error.response ? error.response.status : 500
        })
      })
  }
}
