import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

const GoBack = ({ history, show, title = 'Gå tillbaka', returnPage = "/providers" }) => {
  if (show) {
    return (
      <a onClick={() => history.push(returnPage)} className={'warningRef'}>
        <FontAwesomeIcon icon={faChevronLeft} /> {title}
      </a>
    )
  }

  return null
}

export default GoBack
