import product from '../../../helpers/Constants/product'

const Styles = {
    inputs: {
        marginTop: 0,
        marginLeft: 100,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#dddfe2',
        borderStyle: "solid",
    },
    focus: {
        marginTop: 0,
        marginLeft: 100,
        borderRadius: 8,
        borderWidth: 2,
        borderColor: product.mainColor,
        borderStyle: "solid",
    }
}

export default Styles

