import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import TrainingsTable from '../../../common/TrainingsTable'
import Paper from '../../../common/Paper'
import * as Graphs from '../../../common/Graphs'
import GoBack from '../../../common/GoBack'

import * as patientsActions from '../../../../actions/Patients'
import * as configActions from '../../../../actions/Config'
import * as Functions from '../../../../helpers/Functions'
import product from '../../../../helpers/Constants/product'
import strings from '../../../../helpers/I18NStrings/index'

class Trainings extends React.Component {
  state = {
    orderBy: 'time',
    order: 'asc',
    sortType: 'string',
    rowsPerPage: 5,
    page: 0
  }

  render() {
    const { classes, match, data, config, history } = this.props
    const { orderBy, order, sortType, rowsPerPage, page } = this.state

    if (!data.patients.fetched) {
      history.push('/')

      return null
    }

    const trainings = data.patients.data[
      match.params.patientId
    ].trainings.filter(
      t =>
        t.config >= 0 &&
        config.userStatisticsPage.compareTrainings.indexOf(t.id) !== -1 &&
        t.walkingOn !== 'terrain' &&
        t.walkingOn !== 'other'
    )

    const limpIndexPerMinute = []
    const kadensPerMinute = []

    trainings.forEach(t => {
      if (t.kadensOverTime) {
        const kadens_over_time_decimals = Functions.stringToDecimal(
          atob(t.kadensOverTime)
        )
        const kadensArray = Functions.parsePerMinute(
          kadens_over_time_decimals,
          254
        )

        if (kadensArray.length !== 0) {
          kadensPerMinute.push({
            name: t.time,
            data: kadensArray
          })
        }
      }

      if (t.limpIndexOverTime) {
        const limtp_per_min_decimals = Functions.stringToDecimal(
          atob(t.limpIndexOverTime)
        )
        const limpArray = Functions.parsePerMinute(limtp_per_min_decimals, 100)

        if (limpArray.length !== 0) {
          limpIndexPerMinute.push({
            name: t.time,
            data: limpArray
          })
        }
      }
    })

    if (trainings.length === 0) {
      history.push('/')

      return null
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.content}>
          <div>
            <GoBack
                history={history}
                returnPage= {'/patients/' + match.params.patientId + '/statistics'}
                show={true}
                title={strings.TextReturn}
              />
          </div>
          <p className={'header'}>
            {strings.TextPatient + ' '}
            <span style={{ color: product.mainColor }}>
              {match.params.patientId}
            </span>
          </p>
          <p className={'secondHeader'}>
            {trainings.length === 1
              ? strings.TextTraining + ' ' + trainings[0].time
              : strings.TextCompareTraining}
          </p>

          {trainings.length === 1 ? null : (
            <TrainingsTable
              columns={[
                'time',
                'result',
                'steps',
                'activeTime',
                'totTime',
                'placement'
              ]}
              order={order}
              orderBy={orderBy}
              sortType={sortType}
              setSortOrder={(newOrderBy, newOrder, newSortType) =>
                this.setState({
                  orderBy: newOrderBy,
                  order: newOrder,
                  sortType: newSortType
                })
              }
              trainings={trainings}
              rowsPerPage={rowsPerPage}
              setPaginationRowsPerPage={row =>
                this.setState({ rowsPerPage: row })
              }
              page={page}
              setPaginationPage={page => this.setState({ page })}
            />
          )}

          {limpIndexPerMinute.length === 0 ? null : (
            <Paper style={{ marginTop: 24 }}>
              <Graphs.PerMinute
                series={limpIndexPerMinute}
                title={strings.TextLimpPerMin}
                yTitle={strings.TextLimpingIndex}
              />
            </Paper>
          )}

          {kadensPerMinute.length === 0 ? null : (
            <Paper style={{ marginTop: 24 }}>
              <Graphs.PerMinute
                series={kadensPerMinute}
                title={strings.TextCadencePerMin}
                yTitle={strings.TextCadence}
              />
            </Paper>
          )}

          {trainings.length === 1 ? null : (
            <Paper style={{ marginTop: 24 }}>
              <Graphs.CompareTrainingsColumn
                title={strings.TextLimpingIndex}
                data={trainings.map(t => {
                  return { name: t.time, y: t.result }
                })}
              />
            </Paper>
          )}

          {trainings.length === 1 ? null : (
            <Paper style={{ marginTop: 24 }}>
              <Graphs.CompareTrainingsColumn
                title={strings.TextNrOfSteps}
                data={trainings.map(t => {
                  return { name: t.time, y: t.steps }
                })}
              />
            </Paper>
          )}
        </main>
      </React.Fragment>
    )
  }
}

const Classes = theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
    paddingTop: 88
  }
})

Trainings.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps({ config, user, patients, data }) {
  return { config, user, patients, data }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...configActions, ...patientsActions }, dispatch)
}

export default compose(
  withStyles(Classes),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Trainings)
