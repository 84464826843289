const Styles = {
  container: {
    display: 'flex'
  },
  innerContainer: {
    height: 35,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: 17.5,
    paddingRight: 24,
    marginRight: 24,
    whiteSpace: 'nowrap'
  },
  text: {
    fontSize: 16,
    fontWeight: 'bold'
  }
}

export default Styles
