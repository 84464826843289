import * as Types from '../actions/Types'
import * as Constants from '../helpers/Constants'
import * as Functions from '../helpers/Functions'

const INITIAL_STATE = {
  alert: {
    show: false,
    header: '',
    text: '',
    type: Constants.ONE_BUTTON,
    firstButton: { title: '', onPress: () => { } },
    secondButton: { title: '', onPress: () => { } },
    thirdButton: { title: '', onPress: () => { } }
  },
  page: Constants.HOME_PAGE,
  blurAlert: {
    show: false,
    onSuccess: () => { }
  },
  patientPage: {
    filter: Constants.PATIENT_TYPES.map(disease => disease.id),
    orderBy: 'id',
    order: 'desc',
    sortType: 'string',
    rowsPerPage: 10,
    page: 0,
    showFilter: false,
    searchInput: '',
    showOnlyActivated: false,
    calculatePatientStatusPerPeriod: Constants.WEEK
  },
  userStatisticsPage: {
    showLimpGraphs: true,
    showNumberOfTrainingsGraphs: true,
    showStepGraphs: true,
    showActiveTimeGraphs: true,
    showKadensGraphs: true,
    showTables: true,
    orderBy: 'time',
    order: 'asc',
    sortType: 'string',
    rowsPerPage: 5,
    page: 0,
    compareTrainings: [],
    filterTrainings: Constants.ALL_TRAININGS,
    filterFrom: Functions.getDate(new Date()),
    filterTo: Functions.getDate(new Date())
  },
  termsOfUse: {
    fetched: false,
    data: ''
  }
}

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action
  
  switch (type) {
    case Types.SIGN_OUT_SUCCESS:
      return {
        ...state,
        userStatisticsPage: INITIAL_STATE.userStatisticsPage
      }
    case Types.SELECT_PATIENT:
      if (payload === null) {
        return {
          ...state,
          page: Constants.USER_STATISTICS_PAGE
        }
      }

      return state
    case Types.SHOW_ALERT:
      return {
        ...state,
        alert: {
          show: true,
          header: payload.header,
          text: payload.text,
          type: payload.type,
          firstButton: payload.firstButton,
          secondButton: payload.secondButton,
          thirdButton: payload.thirdButton
        }
      }
    case Types.HIDE_ALERT:
      return {
        ...state,
        alert: {
          ...state.alert,
          show: false
        }
      }
    case Types.SET_PAGE:
      return {
        ...state,
        page: payload
      }
    case Types.SHOW_BLUR_ALERT:
      return {
        ...state,
        blurAlert: {
          show: true,
          onSuccess: () => payload()
        }
      }
    case Types.HIDE_BLUR_ALERT:
      return {
        ...state,
        blurAlert: {
          show: false,
          onSuccess: () => { }
        }
      }
    case Types.PATIENTS_PAGE_SET_FILTER:
      return {
        ...state,
        patientPage: {
          ...state.patientPage,
          filter: payload
        }
      }
    case Types.PATIENTS_PAGE_SHOW_FILTER:
      return {
        ...state,
        patientPage: {
          ...state.patientPage,
          showFilter: payload
        }
      }
    case Types.PATIENTS_PAGE_SET_SEARCH_INPUT:
      return {
        ...state,
        patientPage: {
          ...state.patientPage,
          searchInput: payload
        }
      }
    case Types.PATIENTS_PAGE_SHOW_ONLY_ACTIVATED:
      return {
        ...state,
        patientPage: {
          ...state.patientPage,
          showOnlyActivated: payload
        }
      }
    case Types.PATIENTS_PAGE_SET_PATIENT_STATUS_PERIOD:
      return {
        ...state,
        patientPage: {
          ...state.patientPage,
          calculatePatientStatusPerPeriod: payload
        }
      }
    case Types.PATIENTS_PAGE_SET_SORT_ORDER:
      return {
        ...state,
        patientPage: {
          ...state.patientPage,
          orderBy: payload.orderBy,
          order: payload.order,
          sortType: payload.sortType
        }
      }
    case Types.PATIENTS_PAGE_SET_PAGINATION_PAGE:
      return {
        ...state,
        patientPage: {
          ...state.patientPage,
          page: payload
        }
      }
    case Types.PATIENTS_PAGE_SET_PAGINATION_ROWS_PER_PAGE:
      return {
        ...state,
        patientPage: {
          ...state.patientPage,
          rowsPerPage: payload
        }
      }
    case Types.USER_STATISTICS_PAGE_SHOW_TABLES:
      return {
        ...state,
        userStatisticsPage: {
          ...state.userStatisticsPage,
          showTables: payload
        }
      }
    case Types.USER_STATISTICS_PAGE_SHOW_LIMP_GRAPHS:
      return {
        ...state,
        userStatisticsPage: {
          ...state.userStatisticsPage,
          showLimpGraphs: payload
        }
      }
    case Types.USER_STATISTICS_PAGE_SHOW_NUMBER_OF_TRAININGS_GRAPHS:
      return {
        ...state,
        userStatisticsPage: {
          ...state.userStatisticsPage,
          showNumberOfTrainingsGraphs: payload
        }
      }
    case Types.USER_STATISTICS_PAGE_SHOW_STEP_GRAPHS:
      return {
        ...state,
        userStatisticsPage: {
          ...state.userStatisticsPage,
          showStepGraphs: payload
        }
      }
    case Types.USER_STATISTICS_PAGE_SHOW_ACTIVE_TIME_GRAPHS:
      return {
        ...state,
        userStatisticsPage: {
          ...state.userStatisticsPage,
          showActiveTimeGraphs: payload
        }
      }
    case Types.USER_STATISTICS_PAGE_SHOW_KADENS_GRAPHS:
      return {
        ...state,
        userStatisticsPage: {
          ...state.userStatisticsPage,
          showKadensGraphs: payload
        }
      }
    case Types.USER_STATISTICS_PAGE_SET_SORT_ORDER:
      return {
        ...state,
        userStatisticsPage: {
          ...state.userStatisticsPage,
          orderBy: payload.orderBy,
          order: payload.order,
          sortType: payload.sortType
        }
      }
    case Types.USER_STATISTICS_PAGE_SET_PAGINATION_PAGE:
      return {
        ...state,
        userStatisticsPage: {
          ...state.userStatisticsPage,
          page: payload
        }
      }
    case Types.USER_STATISTICS_PAGE_SET_PAGINATION_ROWS_PER_PAGE:
      return {
        ...state,
        userStatisticsPage: {
          ...state.userStatisticsPage,
          rowsPerPage: payload
        }
      }
    case Types.USER_STATISTICS_SET_COMPARE_TRAININGS:
      return {
        ...state,
        userStatisticsPage: {
          ...state.userStatisticsPage,
          compareTrainings: payload
        }
      }
    case Types.USER_STATISTICS_SET_TRAINING_FILTER:
      return {
        ...state,
        userStatisticsPage: {
          ...state.userStatisticsPage,
          filterTrainings: payload
        }
      }
    case Types.USER_STATISTICS_SET_FROM_FILTER:
      return {
        ...state,
        userStatisticsPage: {
          ...state.userStatisticsPage,
          filterFrom: payload
        }
      }
    case Types.USER_STATISTICS_SET_TO_FILTER:
      return {
        ...state,
        userStatisticsPage: {
          ...state.userStatisticsPage,
          filterTo: payload
        }
      }
    case Types.GET_TERMS_OF_USE_SUCCESS:
      return {
        ...state,
        termsOfUse: {
          fetched: true,
          data: payload
        }
      }
    default:
      return state
  }
}
