import * as Types from '../actions/Types'

const INITIAL_STATE = {
  isDeleting: false,
  errorDeletingStatus: null
}

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action

  switch (type) {
  case Types.DELETE_TRAININGS:
    return {
      ...state,
      isDeleting: true,
      errorDeletingStatus: null
    }
  case Types.DELETE_TRAININGS_SUCCESS:
    return {
      ...state,
      isDeleting: false,
      errorDeletingStatus: null
    }
  case Types.DELETE_TRAININGS_ERROR:
    return {
      ...state,
      isDeleting: false,
      errorDeletingStatus: payload
    }
  default:
    return state
  }
}
