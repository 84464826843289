import { combineReducers } from 'redux'
import config from './Config'
import user from './User'
import data from './Data'
import myData from './MyData'
import patients from './Patients'
import clinicData from './clinic/Data'
import providers from './clinic/Providers'
import providerSettings from './clinic/ProviderSettings'
import goalsState from './Goals'
import medotemicData from './medotemic/Data'
import clinics from './medotemic/Clinics'
import clinic from './clinic/Clinic'
import trainings from './Trainings'

export default combineReducers({
  config,
  user,
  data,
  patients,
  myData,
  clinicData,
  providers,
  providerSettings,
  goalsState,
  medotemicData,
  clinics,
  clinic,
  trainings
})
