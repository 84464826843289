import * as Types from './Types'
import * as API from './api/provider'

export function tryGetPatients(token, userId) {
  return dispatch => {
    dispatch({
      type: Types.GET_PATIETNS
    })

    API.getPatients(token, userId)
      .then(response => {
        dispatch({
          type: Types.GET_PATIETNS_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: Types.GET_PATIETNS_ERROR
        })
      })
  }
}

export function tryCreatePatient(token, userId, data) {
  return dispatch => {
    dispatch({
      type: Types.CREATE_PATIENT
    })

    API.createPatients(token, userId, data)
      .then(response => {
        dispatch({
          type: Types.CREATE_PATIENT_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        let status = 500

        if (error.response && error.response.status) {
          status = error.response.status
        }

        dispatch({
          type: Types.CREATE_PATIENT_ERROR,
          payload: status
        })
      })
  }
}

export function tryImportPatient(token, userId, code) {
  return dispatch => {
    dispatch({
      type: Types.IMPORT_PATIENT
    })

    API.importPatient(token, userId, code)
      .then(response => {
        dispatch({
          type: Types.IMPORT_PATIENT_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: Types.IMPORT_PATIENT_ERROR,
          payload: error.response ? error.response.status : 500
        })
      })
  }
}

export function tryDeletePatients(token, userId, patientIds) {
  return dispatch => {
    dispatch({
      type: Types.DELETE_PATIENTS
    })

    Promise.all(patientIds.map(id => API.deletePatient(token, userId, id)))
      .then(() => {
        dispatch({
          type: Types.DELETE_PATIENTS_SUCCESS,
          payload: patientIds
        })
      })
      .catch(error => {
        dispatch({
          type: Types.DELETE_PATIENTS_ERROR,
          payload: error.response ? error.response.status : 500
        })
      })
  }
}

export function tryUpdatePatient(token, userId, patientId, data) {
  return dispatch => {
    dispatch({
      type: Types.UPDATE_PATIENT
    })

    API.updatePatient(token, userId, patientId, data)
      .then(response => {
        dispatch({
          type: Types.UPDATE_PATIENT_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        let status = 500

        // If the server can not respond response will be undefined which will cause a crash.
        if (error.response && error.response.status) {
          status = error.response.status
        }

        dispatch({
          type: Types.UPDATE_PATIENT_ERROR,
          payload: status
        })
      })
  }
}

export function tryUpdatePatientPassword(token, userId, patientId, data) {
  return dispatch => {
    dispatch({
      type: Types.UPDATE_PATIENT_PASSWORD
    })

    API.updatePatientPassword(token, userId, patientId, data)
      .then(response => {
        dispatch({
          type: Types.UPDATE_PATIENT_PASSWORD_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        let status = 500

        // If the server can not respond response will be undefined which will cause a crash.
        if (error.response && error.response.status) {
          status = error.response.status
        }

        dispatch({
          type: Types.UPDATE_PATIENT_PASSWORD_ERROR,
          payload: status
        })
      })
  }
}

export function tryUpdatePatientUsername(token, userId, patientId, data) {
  return dispatch => {
    dispatch({
      type: Types.UPDATE_PATIENT_USERNAME
    })

    API.updatePatientUsername(token, userId, patientId, data)
      .then(response => {
        dispatch({
          type: Types.UPDATE_PATIENT_USERNAME_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        let status = 500

        // If the server can not respond response will be undefined which will cause a crash.
        if (error.response && error.response.status) {
          status = error.response.status
        }

        dispatch({
          type: Types.UPDATE_PATIENT_USERNAME_ERROR,
          payload: status
        })
      })
  }
}

export function tryCreateGoals(token, userId, patientId, data) {
  return dispatch => {
    dispatch({
      type: Types.CREATE_GOALS
    })

    API.createGoals(token, userId, patientId, data)
      .then(response => {
        dispatch({
          type: Types.CREATE_GOALS_SUCCESS,
          payload: { data: response.data, patientId }
        })
      })
      .catch(error => {
        let status = 500

        console.log(error.response)

        // If the server can not respond response will be undefined which will cause a crash.
        if (error.response && error.response.status) {
          status = error.response.status
        }

        dispatch({
          type: Types.CREATE_GOALS_ERROR,
          payload: status
        })
      })
  }
}

export function selectPatient(id) {
  return dispatch => {
    dispatch({
      type: Types.SELECT_PATIENT,
      payload: id
    })
  }
}
