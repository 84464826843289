import * as Types from '../../actions/Types'

const INITIAL_STATE = {
  clinics: {
    fetched: false,
    data: {}
  }
}

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action

  switch (type) {
  case Types.GET_CLINICS_SUCCESS:
    return {
      ...state,
      clinics: {
        fetched: true,
        data: prepareClinics(payload)
      }
    }
  case Types.CREATE_CLINIC_SUCCESS:
    return {
      ...state,
      clinics: {
        ...state.clinics,
        data: {
          ...state.clinics.data,
          [payload.id]: {
            ...payload
          }
        }
      }
    }
  case Types.UPDATE_CLINIC_SUCCESS:
    return {
      ...state,
      clinics: {
        ...state.clinics,
        data: {
          ...state.clinics.data,
          [payload.id]: {
            ...payload
          }
        }
      }
    }
  case Types.UPDATE_LICENSE_SUCCESS:
    return {
      ...state,
      clinics: {
        ...state.clinics,
        data: {
          ...state.clinics.data,
          [payload.clinicId]: {
            ...state.clinics.data[payload.clinicId],
            license: payload.data
          }
        }
      }
    }
  case Types.SIGN_OUT_SUCCESS:
    return {
      ...state,
      ...INITIAL_STATE
    }
  default:
    return state
  }
}

function prepareClinics(clinics) {
  const data = {}

  clinics.forEach(c => {
    data[c.id] = {
      ...c
    }
  })

  return data
}
