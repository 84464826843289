import * as Types from '../Types'
import * as API from '../api/medotemic'

export function tryGetClinics(token, userId) {
  return dispatch => {
    dispatch({
      type: Types.GET_CLINICS
    })

    API.getClinics(token, userId)
      .then(response => {
        dispatch({
          type: Types.GET_CLINICS_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: Types.GET_CLINICS_ERROR
        })
      })
  }
}

export function tryCreateClinic(token, userId, data) {
  return dispatch => {
    dispatch({
      type: Types.CREATE_CLINIC
    })

    API.createClinic(token, userId, data)
      .then(response => {
        dispatch({
          type: Types.CREATE_CLINIC_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        let status = 500

        if (error.response && error.response.status) {
          status = error.response.status
        }

        dispatch({
          type: Types.CREATE_CLINIC_ERROR,
          payload: status
        })
      })
  }
}

export function tryUpdateClinic(token, userId, clinicId, data) {
  return dispatch => {
    dispatch({
      type: Types.UPDATE_CLINIC
    })

    API.updateClinic(token, userId, clinicId, data)
      .then(response => {
        dispatch({
          type: Types.UPDATE_CLINIC_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        let status = 500

        if (error.response && error.response.status) {
          status = error.response.status
        }

        dispatch({
          type: Types.UPDATE_CLINIC_ERROR,
          payload: status
        })
      })
  }
}

export function tryUpdateClinicUsername(token, userId, clinicId, data) {
  return dispatch => {
    dispatch({
      type: Types.UPDATE_CLINIC_USERNAME
    })

    API.updateClinicUsername(token, userId, clinicId, data)
      .then(response => {
        dispatch({
          type: Types.UPDATE_CLINIC_USERNAME_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        let status = 500

        if (error.response && error.response.status) {
          status = error.response.status
        }

        dispatch({
          type: Types.UPDATE_CLINIC_USERNAME_ERROR,
          payload: status
        })
      })
  }
}

export function tryUpdateClinicPassword(token, userId, clinicId, data) {
  return dispatch => {
    dispatch({
      type: Types.UPDATE_CLINIC_PASSWORD
    })

    API.updateClinicPassword(token, userId, clinicId, data)
      .then(response => {
        dispatch({
          type: Types.UPDATE_CLINIC_PASSWORD_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        let status = 500

        if (error.response && error.response.status) {
          status = error.response.status
        }

        dispatch({
          type: Types.UPDATE_CLINIC_PASSWORD_ERROR,
          payload: status
        })
      })
  }
}

export function tryUpdateLicense(token, userId, clinicId, data) {
  return dispatch => {
    dispatch({
      type: Types.UPDATE_LICENSE
    })

    API.updateLicense(token, userId, clinicId, data)
      .then(response => {
        dispatch({
          type: Types.UPDATE_LICENSE_SUCCESS,
          payload: { data: response.data, clinicId }
        })
      })
      .catch(error => {
        let status = 500

        if (error.response && error.response.status) {
          status = error.response.status
        }

        dispatch({
          type: Types.UPDATE_LICENSE_ERROR,
          payload: status
        })
      })
  }
}
