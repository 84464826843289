import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUsers,
  faUser,
  faUserPlus,
  faSignOutAlt,
  faChartBar,
  faChartLine,
  faInfo,
  faCog,
  faUserCheck
} from '@fortawesome/free-solid-svg-icons'

import * as Styles from './styles'
import strings from '../../../helpers/I18NStrings/index'

export const HomeListItem = props => (
  <ListItem button onClick={() => props.onClick()} selected={props.selected}>
    <div style={Styles.default.iconContainer}>
      <FontAwesomeIcon icon={faUsers} style={Styles.default.icon} />
    </div>
    <ListItemText style={{ position: 'absolute', left: 55 }}>
      <p>{strings.TextPatients}</p>
    </ListItemText>
  </ListItem>
)

export const UsersListItem = props => (
  <ListItem button onClick={() => props.onClick()} selected={props.selected}>
    <div style={Styles.default.iconContainer}>
      <FontAwesomeIcon icon={faUser} style={Styles.default.icon} />
    </div>
    <ListItemText style={{ position: 'absolute', left: 55 }}>
      <p>{strings.TextPatient}</p>
    </ListItemText>
  </ListItem>
)

export const AddUserItem = props => {
  if (props.hidden) {
    return null
  }

  return (
    <ListItem button onClick={() => props.onClick()} selected={props.selected}>
      <div style={Styles.default.iconContainer}>
        <FontAwesomeIcon icon={faUserPlus} style={Styles.default.icon} />
      </div>
      <ListItemText style={{ position: 'absolute', left: 55 }}>
        <p>{strings.TextNewPatient}</p>
      </ListItemText>
    </ListItem>
  )
}

export const SignOutItem = props => (
  <ListItem button onClick={() => props.onClick()} selected={props.selected}>
    <div style={Styles.default.iconContainer}>
      <FontAwesomeIcon icon={faSignOutAlt} style={Styles.default.icon} />
    </div>
    <ListItemText style={{ position: 'absolute', left: 55 }}>
      <p>{strings.ButtonLogOut}</p>
    </ListItemText>
  </ListItem>
)

export const MyResultsItem = props => (
  <ListItem button onClick={() => props.onClick()} selected={props.selected}>
    <div style={Styles.default.iconContainer}>
      <FontAwesomeIcon icon={faChartBar} style={Styles.default.icon} />
    </div>
    <ListItemText style={{ position: 'absolute', left: 55 }}>
      <p>{strings.TextResults}</p>
    </ListItemText>
  </ListItem>
)

export const MyGoalsItem = props => (
  <ListItem button onClick={() => props.onClick()} selected={props.selected}>
    <div style={Styles.default.iconContainer}>
      <FontAwesomeIcon icon={faChartLine} style={Styles.default.icon} />
    </div>
    <ListItemText style={{ position: 'absolute', left: 55 }}>
      <p>{strings.TextTargets}</p>
    </ListItemText>
  </ListItem>
)

export const ProvidersItem = props => (
  <ListItem button onClick={() => props.onClick()} selected={props.selected}>
    <div style={Styles.default.iconContainer}>
      <FontAwesomeIcon icon={faUsers} style={Styles.default.icon} />
    </div>
    <ListItemText style={{ position: 'absolute', left: 55 }}>
      <p>{strings.TextCaregiver}</p>
    </ListItemText>
  </ListItem>
)

export const NewProvidersItem = props => (
  <ListItem button onClick={() => props.onClick()} selected={props.selected}>
    <div style={Styles.default.iconContainer}>
      <FontAwesomeIcon icon={faUserPlus} style={Styles.default.icon} />
    </div>
    <ListItemText style={{ position: 'absolute', left: 55 }}>
      <p>{strings.TextNewCaregiver}</p>
    </ListItemText>
  </ListItem>
)

export const InformationItem = props => {
  if (props.hidden) {
    return null
  }

  return (
    <ListItem button onClick={() => props.onClick()} selected={props.selected}>
      <div style={Styles.default.iconContainer}>
        <FontAwesomeIcon icon={faInfo} style={Styles.default.icon} />
      </div>
      <ListItemText style={{ position: 'absolute', left: 55 }}>
        <p>{strings.TextInformation}</p>
      </ListItemText>
    </ListItem>
  )
}

export const ClinicsItem = props => (
  <ListItem button onClick={() => props.onClick()} selected={props.selected}>
    <div style={Styles.default.iconContainer}>
      <FontAwesomeIcon icon={faUsers} style={Styles.default.icon} />
    </div>
    <ListItemText style={{ position: 'absolute', left: 55 }}>
      <p>{strings.TextClinics}</p>
    </ListItemText>
  </ListItem>
)

export const AddClinicItem = props => (
  <ListItem button onClick={() => props.onClick()} selected={props.selected}>
    <div style={Styles.default.iconContainer}>
      <FontAwesomeIcon icon={faUserPlus} style={Styles.default.icon} />
    </div>
    <ListItemText style={{ position: 'absolute', left: 55 }}>
      <p>{strings.TextNewClinic}</p>
    </ListItemText>
  </ListItem>
)

export const SettingsItem = props => (
  <ListItem button onClick={() => props.onClick()} selected={props.selected}>
    <div style={Styles.default.iconContainer}>
      <FontAwesomeIcon icon={faCog} style={Styles.default.icon} />
    </div>
    <ListItemText style={{ position: 'absolute', left: 55 }}>
      <p>{strings.TextSettings}</p>
    </ListItemText>
  </ListItem>
)

export const ProviderSettingsItem = props => {
  if (props.hidden) {
    return null
  }
  
  return (
    <ListItem button onClick={() => props.onClick()} selected={props.selected}>
      <div style={Styles.default.iconContainer}>
        <FontAwesomeIcon icon={faCog} style={Styles.default.icon} />
      </div>
      <ListItemText style={{ position: 'absolute', left: 55 }}>
        <p>{strings.TextSettings}</p>
      </ListItemText>
    </ListItem>
  )
}

export const ImportUserItem = props => {
  if (props.hidden) {
    return null
  }

  return (
    <ListItem button onClick={() => props.onClick()} selected={props.selected}>
      <div style={Styles.default.iconContainer}>
        <FontAwesomeIcon icon={faUserCheck} style={Styles.default.icon} />
      </div>
      <ListItemText style={{ position: 'absolute', left: 55 }}>
        <p>{strings.TextImport}</p>
      </ListItemText>
    </ListItem>
  )
}
