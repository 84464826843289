import product from "../../../helpers/Constants/product";

const Styles = {
  container: {
    backgroundColor: '#f4f4f8',
    backgroundImage: 'url('+product.SignInBackground+')',
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'noRepeat'
  },
  signInContainer: {
    backgroundColor: '#fff',
    width: 396,
    height: 554,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12)',
    border: '1px solid #dddfe2',
    margin: 48
  },
  termsText: {
    fontSize: 16
  }
}

export default Styles
