import React from 'react'

import * as Functions from '../../../helpers/Functions'
import * as Styles from './styles'

const PatientTypeItems = ({ types }) => {
  return (
    <div style={Styles.default.container}>
      {types.sort().map((type, index) => {
        return (
          <div style={Styles.default.innerContainer} key={index}>
            <p style={Styles.default.text}>
              {Functions.parsePatientType(type)}
            </p>
          </div>
        )
      })}
    </div>
  )
}

export default PatientTypeItems
