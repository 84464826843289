import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import MaterialPaper from '@material-ui/core/Paper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button'

import strings from '../../../helpers/I18NStrings/index'

class Goals extends React.Component {
  getLimpIndexGoal() {
    const { goals, emptyLimpText } = this.props
    const goal = goals.limpIndexGoal

    if (goal) {
      return (
        <p style={{ marginTop: 0 }}>
          {strings.TextReduceLimpIndex}{' '}
          <span className={'greenBold'}>{goal.startIndex}</span> {strings.TextTo}{' '}
          <span className={'greenBold'}>{goal.endIndex}</span> {strings.TextDuring}{' '}
          {strings.TextTimePeriod} <span className={'greenBold'}>{goal.startDate}</span>{' '}
          {strings.TextUntil} <span className={'greenBold'}>{goal.endDate}</span>.
        </p>
      )
    }

    return (
      <p className={'warningText'} style={{ marginTop: 0 }}>
        {emptyLimpText}
      </p>
    )
  }

  getMinuteGoal() {
    const { goals, emptyMinuteText } = this.props
    const goal = goals.minutesWalkGoal

    if (goal) {
      return (
        <p style={{ marginTop: 0 }}>
          {strings.TextWalk} <span className={'greenBold'}>{goal.minutes}</span> {strings.TextMinutesWeek}{'.'}
        </p>
      )
    }

    return (
      <p className={'warningText'} style={{ marginTop: 0 }}>
        {emptyMinuteText}
      </p>
    )
  }

  getTrainingsGoal() {
    const { goals, emptyTrainingsText } = this.props
    const goal = goals.trainingsGoal

    if (goal) {
      return (
        <p style={{ marginTop: 0 }}>
          {strings.TextWalk}{' '}<span className={'greenBold'}>{goal.numberOfTrainings}</span>{' '}
          {strings.TextTimesWeek}{'.'}
        </p>
      )
    }

    return (
      <p className={'warningText'} style={{ marginTop: 0 }}>
        {emptyTrainingsText}
      </p>
    )
  }

  render() {
    const {
      classes,
      hint,
      goals,
      options = false,
      onRemoveLimpGoal,
      onRemoveMinutesGoal,
      onEditLimpGoal,
      onEditMinutesGoal,
      onAddLimpGoal,
      onAddMinutesGoal,
      onEditTrainingsGoal,
      onRemoveTrainingsGoal,
      onAddTrainingsGoal,
      onHistory,
      historyHidden
    } = this.props

    return (
      <MaterialPaper className={classes.root}>
        {options && !historyHidden ? (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* <a onClick={() => onHistory()}>
              <FontAwesomeIcon icon={faHistory} /> {strings.TextShowLog}
            </a> */}
            <Button
              style={{  }}
              className={'success'}
              onClick={() => onHistory()}
              header={
                <div>
                  <FontAwesomeIcon icon={faHistory} style={{ marginRight: 10 }} />
                  {strings.TextShowLog}
                </div>
              }
            />
          </div>
        ) : null}
        <p className={'secondHeader'}>{strings.TextReducingTarget}</p>
        {this.getLimpIndexGoal()}
        {options ? (
          goals.limpIndexGoal ? (
            <div style={{ display: 'flex' }}>
              <a style={{ marginRight: 24 }} onClick={() => onEditLimpGoal()}>
                {strings.TextChange}
              </a>
              <a className={'warningRef'} onClick={() => onRemoveLimpGoal()}>
                {strings.TextRemove}
              </a>
            </div>
          ) : (
              <a className={'successRef'} onClick={() => onAddLimpGoal()}>
                {strings.TextAdd}
              </a>
            )
        ) : null}
        <p className={'secondHeader'}>{strings.TextMinuteTarget}</p>
        {this.getMinuteGoal()}
        {options ? (
          goals.minutesWalkGoal ? (
            <div style={{ display: 'flex' }}>
              <a
                style={{ marginRight: 24 }}
                onClick={() => onEditMinutesGoal()}
              >
                {strings.TextChange}
              </a>
              <a
                className={'warningRef'}
                onClick={() => onRemoveMinutesGoal()}
              >
                {strings.TextRemove}
              </a>
            </div>
          ) : (
              <a className={'successRef'} onClick={() => onAddMinutesGoal()}>
                {strings.TextAdd}
              </a>
            )
        ) : null}
        <p className={'secondHeader'}>{strings.TextWalksTarget}</p>
        {this.getTrainingsGoal()}
        {options ? (
            goals.trainingsGoal ? (
              <div style={{ display: 'flex' }}>
                <a
                  style={{ marginRight: 24 }}
                  onClick={() => onEditTrainingsGoal()}
                >
                  {strings.TextChange}
                </a>
                <a
                  className={'warningRef'}
                  onClick={() => onRemoveTrainingsGoal()}
                >
                  {strings.TextRemove}
                </a>
              </div>
            ) : (
                <a className={'successRef'} onClick={() => onAddTrainingsGoal()}>
                  {strings.TextAdd}
                </a>
              )
          ) : null}
        <p className={'hint'} style={{ marginTop: 48 }}>
          {hint}
        </p>
      </MaterialPaper>
    )
  }
}

const Classes = theme => ({
  root: {
    width: '100%',
    marginTop: 24,
    padding: 24
  }
})

Goals.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(Classes)(Goals)
