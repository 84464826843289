import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import TrainingsTable from '../../../common/TrainingsTable'
import * as Graphs from '../../../common/Graphs'
import Paper from '../../../common/Paper'
import Checkbox from '../../../common/Checkbox'
import Button from '../../../common/Button'
import GoBack from '../../../common/GoBack'
import FullscreenLoader from '../../../common/FullscreenLoader'

import * as Styles from './styles'
import * as patientActions from '../../../../actions/Patient'
import * as patientsActions from '../../../../actions/Patients'
import * as configActions from '../../../../actions/Config'
import * as Constants from '../../../../helpers/Constants'
import * as userStatisticsPageActions from '../../../../actions/UserStatisticsPage'
import * as Functions from '../../../../helpers/Functions'
import product from '../../../../helpers/Constants/product'
import strings from '../../../../helpers/I18NStrings/index'

class MyResults extends React.Component {
  state = {
    width: window.innerWidth,
    orderBy: 'time',
    order: 'asc',
    sortType: 'string',
    rowsPerPage: 5,
    page: 0
  }

  resizeHandler = this.resizeHandler.bind(this)

  componentDidMount() {
    const { user, myData } = this.props

    if (!myData.trainings.fetched) {
      this.props.tryFetchPatient(user.token, user.id)
    }

    window.addEventListener('resize', this.resizeHandler)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler)
  }

  resizeHandler() {
    this.setState({ width: window.innerWidth })
  }

  renderGraphs() {
    const { patients, data, myData, config, match } = this.props
    const { width } = this.state

    const trainings = myData.trainings.data
      .filter(t => t.config >= 0)
      .sort((t1, t2) => {
        return new Date(t1.time).getTime() - new Date(t2.time).getTime()
      })

    const perDayParsedData = Functions.getGraphDataPerDayFromTrainings(trainings)
    const perWeekParsedData = Functions.getGraphDataPerWeekFromTrainings(trainings)

    const goals = myData.goals.data

    const graphs = [
      {
        show: config.userStatisticsPage.showLimpGraphs,
        component: (
          <Graphs.LimpIndexPerDay
            data={perDayParsedData.results}
            goalData={Functions.parseLimpIndexGoal(
              goals.limpIndexGoal,
              perDayParsedData.results
            )}
          />
        )
      },
      {
        show: config.userStatisticsPage.showNumberOfTrainingsGraphs,
        component: (
          <Graphs.NumberOfTrainingsPerWeek
            data={perWeekParsedData.numberOfTrainings}
            goalData={Functions.parseNumberOfTrainingsGoal(
              goals.numberOfTrainingGoal,
              perWeekParsedData.numberOfTrainings
            )}
          />
        )
      },
      {
        show: config.userStatisticsPage.showStepGraphs,
        component: (
          <Graphs.StepsPerDay
            data={perDayParsedData.steps}
          />
        )
      },
      {
        show: config.userStatisticsPage.showActiveTimeGraphs,
        component: (
          <Graphs.ActiveTimePerDay
            data={perWeekParsedData.activeTime}
            goalData={Functions.parseMinutesGoal(
              goals.minutesWalkGoal,
              perWeekParsedData.activeTime
            )}
          />
        )
      },
      {
        show: config.userStatisticsPage.showKadensGraphs,
        component: (
          <Graphs.KadensPerDay
            data={perDayParsedData.kadens}
          />
        )
      },
    ]

    const filteredGraphs = graphs.filter(g => g.show)
    let graphComponents = []

    if (width > 1700) {
      for (let i = 0; i < filteredGraphs.length; i = i + 2) {
        if (i + 1 < filteredGraphs.length) {
          graphComponents.push(
            <div key={i} style={Styles.default.chartContainer}>
              <Paper style={{ marginRight: 12 }}>
                {filteredGraphs[i].component}
              </Paper>
              <Paper style={{ marginLeft: 12 }}>
                {filteredGraphs[i + 1].component}
              </Paper>
            </div>
          )
        } else {
          graphComponents.push(
            <div key={i} style={Styles.default.chartContainer}>
              <Paper style={{ marginRight: 12 }}>
                {filteredGraphs[i].component}
              </Paper>
              <div style={{ marginLeft: 12, width: '100%', padding: 24 }} />
            </div>
          )
        }
      }
    } else {
      graphComponents = filteredGraphs.map((g, index) => (
        <Paper style={{ marginTop: 24 }} key={index}>
          {g.component}
        </Paper>
      ))
    }

    return graphComponents
  }

  render() {
    const { myData, classes, config, history, patients } = this.props
    const { width, order, orderBy, sortType, page, rowsPerPage } = this.state

    if (!myData.trainings.fetched) {
      return <FullscreenLoader />
    }

    const trainings = myData.trainings.data
      .filter(t => t.config >= 0)
      .sort((t1, t2) => {
        return new Date(t1.time).getTime() - new Date(t2.time).getTime()
      })
    const compareLength = config.userStatisticsPage.compareTrainings.length

    return (
      <main className={classes.content}>
        <p className={'header'}>{strings.TextResults}</p>
        <TrainingsTable
          columns={
            product.showTrainingCheckbox === true
              ? [
                  'selections',
                  'time',
                  'result',
                  'trend',
                  'steps',
                  'activeTime',
                  'totTime'
                ]
              : ['time', 'result', 'trend', 'steps', 'activeTime', 'totTime']
          }
          order={config.userStatisticsPage.order}
          orderBy={config.userStatisticsPage.orderBy}
          sortType={config.userStatisticsPage.sortType}
          setSortOrder={(newOrderBy, newOrder, newSortType) =>
            this.props.setSortOrder(newOrderBy, newOrder, newSortType)
          }
          trainings={trainings}
          rowsPerPage={config.userStatisticsPage.rowsPerPage}
          setPaginationRowsPerPage={row =>
            this.props.setPaginationRowsPerPage(row)
          }
          page={config.userStatisticsPage.page}
          setPaginationPage={page => this.props.setPaginationPage(page)}
          compareTrainings={config.userStatisticsPage.compareTrainings}
          setCompareTrainings={t => this.props.setCompareTrainings(t)}
          onToggleAllCheckbox={() => {
            if (config.userStatisticsPage.compareTrainings.length === 0) {
              const ids = trainings.map(t => t.id)

              this.props.setCompareTrainings(ids)
            } else {
              this.props.setCompareTrainings([])
            }
          }}
          allCheckboxesValue={
            trainings.length ===
            config.userStatisticsPage.compareTrainings.length
          }
          allCheckboxesIndeterminate={
            config.userStatisticsPage.compareTrainings.length !== 0 &&
            trainings.length !==
              config.userStatisticsPage.compareTrainings.length
          }
        />
        {compareLength !== 0 ? (
          <div className={classes.buttonContainer}>
            <Button
              className={'success'}
              onClick={() => {
                history.push('/mytrainings')
              }}
              header={
                compareLength === 1
                  ? strings.TextViewTraining
                  : strings.TextCompareTraining +
                    ' (' +
                    compareLength +
                    ')'
              }
            />
          </div>
        ) : null}

        {this.renderGraphs()}
      </main>
    )
  }
}

const Classes = theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
    paddingTop: 88
  },
  table: {
    minWidth: 700
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fafafa'
    }
  },
  root: {
    width: '100%',
    marginTop: 24,
    padding: 0
  },
  innerRoot: {
    width: '100%',
    overflowX: 'auto'
  }
})

MyResults.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps({ config, user, myData }) {
  return { config, user, myData }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...configActions,
      ...patientsActions,
      ...userStatisticsPageActions,
      ...patientActions
    },
    dispatch
  )
}

export default compose(
  withStyles(Classes),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MyResults)
