const Styles = {
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 24
  },
  innerFilterContainer: {},
  chartContainer: {
    display: 'flex',
    marginTop: 24
  }
}

export default Styles
