import * as Types from '../actions/Types'

const INITIAL_STATE = {
  trainings: {
    fetched: false,
    data: []
  },
  goals: {
    fetched: false,
    data: {
      limpIndexGoal: null,
      minutesWalkGoal: null
    }
  }
}

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action

  switch (type) {
  case Types.GET_PATIENT_SUCCESS:
    return {
      ...state,
      trainings: {
        fetched: true,
        data: payload.trainings.filter(
          t => t.result !== null && t.result >= 0
        )
      },
      goals: {
        fetched: true,
        data: payload.goals
      }
    }
  case Types.SIGN_OUT_SUCCESS:
    return {
      ...state,
      ...INITIAL_STATE
    }
  default:
    return state
  }
}
