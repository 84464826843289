import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import FullscreenLoader from '../../../common/FullscreenLoader'
import Goals from '../../../common/Goals'

import * as patientActions from '../../../../actions/Patient'
import * as configActions from '../../../../actions/Config'
import strings from '../../../../helpers/I18NStrings/index'

class MyGoals extends React.Component {
  componentDidMount() {
    const { user, myData } = this.props

    if (!myData.trainings.fetched) {
      this.props.tryFetchPatient(user.token, user.id)
    }
  }

  render() {
    const { myData, classes } = this.props

    if (!myData.goals.fetched) {
      return <FullscreenLoader />
    }

    return (
      <main className={classes.content}>
        <p className={'header'}>{strings.TextTargets}</p>
        <Goals
          goals={myData.goals.data}
          hint={strings.TextSetUpTargetCaregiver}
          emptyLimpText={strings.TextNoReducingTargetCaregiver}
          emptyStepText={strings.TextNoStepTargetCaregiver}
          emptyMinuteText={strings.TextNoMinuteTargetCaregiver}
        />
      </main>
    )
  }
}

const Classes = theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
    paddingTop: 88
  }
})

MyGoals.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps({ config, user, myData }) {
  return { config, user, myData }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...configActions, ...patientActions }, dispatch)
}

export default compose(
  withStyles(Classes),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MyGoals)
