const Styles = {
  container: {
    width: '100%',
    backgroundColor: '#fff',
    padding: 25,
    border: '1px solid #dddfe2',
    boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: 4
  }
}

export default Styles
