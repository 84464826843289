import Axios from 'axios'
import products from '../../helpers/Constants/product';

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8080/api/v1/'
    : 'https://portal.medotemic.com/api/v1/'

export function getTermsOfUse() {
  const url = BASE_URL + 'config/termsOfUse/sv'

  return Axios.get(url, {headers: {product: products.product}})
}
