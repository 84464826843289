import * as Types from '../actions/Types'
import * as Functions from '../helpers/Functions'

const INITIAL_STATE = {
  patients: {
    fetched: false,
    data: {}
  }
}

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action

  switch (type) {
    case Types.GET_PATIETNS:
      return {
        ...state,
        patients: {
          fetched: false,
          data: {}
        }
      }
    case Types.GET_PATIETNS_SUCCESS:
      return {
        ...state,
        patients: {
          fetched: true,
          data: preparePatients(payload)
        }
      }
    case Types.CREATE_PATIENT_SUCCESS:
      return {
        ...state,
        patients: {
          ...state.patients,
          data: {
            ...state.patients.data,
            [payload.patient.id]: {
              ...payload.patient,
              trainings: [],
              goals: {
                minutesWalkGoals: [],
                limpIndexGoals: [],
                numberOfTrainingGoal: [],
              }
            }
          }
        }
      }
    case Types.IMPORT_PATIENT_SUCCESS:
      return {
        ...state,
        patients: {
          ...state.patients,
          data: {
            ...state.patients.data,
            [payload.id]: {
              ...payload.patients,
              trainings: payload.trainings,
              goals: payload.goals
            }
          }
        }
      }
    case Types.UPDATE_PATIENT_SUCCESS:
      return {
        ...state,
        patients: {
          ...state.patients,
          data: {
            ...state.patients.data,
            [payload.id]: { ...state.patients.data[payload.id], ...payload }
          }
        }
      }
    case Types.DELETE_PATIENTS_SUCCESS:
      return {
        ...state,
        patients: {
          ...state.patients,
          data: deletePatients(payload, state.patients.data)
        }
      }
    case Types.DELETE_TRAININGS_SUCCESS:
      return {
        ...state,
        patients: {
          ...state.patients,
          data: {
            ...state.patients.data,
            [payload.patientId]: {
              ...state.patients.data[payload.patientId],
              trainings: state.patients.data[
                payload.patientId
              ].trainings.filter(t => {
                return payload.ids.indexOf(t.id) === -1
              })
            }
          }
        }
      }
    case Types.CREATE_GOALS_SUCCESS:
      return {
        ...state,
        patients: {
          ...state.patients,
          data: {
            ...state.patients.data,
            [payload.patientId]: {
              ...state.patients.data[payload.patientId],
              goals: payload.data
            }
          }
        }
      }
    case Types.CREATE_MINUTE_GOAL_SUCCESS:
      return {
        ...state,
        patients: {
          ...state.patients,
          data: {
            ...state.patients.data,
            [payload.patientId]: {
              ...state.patients.data[payload.patientId],
              goals: {
                ...state.patients.data[payload.patientId].goals,
                minutesWalkGoals: [
                  ...state.patients.data[payload.patientId].goals
                    .minutesWalkGoals,
                  payload.data
                ]
              }
            }
          }
        }
      }
    case Types.DELETE_MINUTE_GOAL_SUCCESS:
      return {
        ...state,
        patients: {
          ...state.patients,
          data: {
            ...state.patients.data,
            [payload.patientId]: {
              ...state.patients.data[payload.patientId],
              goals: {
                ...state.patients.data[payload.patientId].goals,
                minutesWalkGoals: state.patients.data[
                  payload.patientId
                ].goals.minutesWalkGoals.map(g => {
                  if (g.id === payload.data.id) {
                    return payload.data
                  }

                  return g
                })
              }
            }
          }
        }
      }
    case Types.EDIT_MINUTE_GOAL_SUCCESS:
      return {
        ...state,
        patients: {
          ...state.patients,
          data: {
            ...state.patients.data,
            [payload.patientId]: {
              ...state.patients.data[payload.patientId],
              goals: {
                ...state.patients.data[payload.patientId].goals,
                minutesWalkGoals: [
                  ...state.patients.data[
                    payload.patientId
                  ].goals.minutesWalkGoals.map(g => {
                    if (g.id === payload.id) {
                      return { ...g, deletedAt: Functions.getTimestamp() }
                    }

                    return g
                  }),
                  payload.data
                ]
              }
            }
          }
        }
      }
    case Types.CREATE_LIMP_GOAL_SUCCESS:
      return {
        ...state,
        patients: {
          ...state.patients,
          data: {
            ...state.patients.data,
            [payload.patientId]: {
              ...state.patients.data[payload.patientId],
              goals: {
                ...state.patients.data[payload.patientId].goals,
                limpIndexGoals: [
                  ...state.patients.data[payload.patientId].goals
                    .limpIndexGoals,
                  payload.data
                ]
              }
            }
          }
        }
      }
    case Types.DELETE_LIMP_GOAL_SUCCESS:
      return {
        ...state,
        patients: {
          ...state.patients,
          data: {
            ...state.patients.data,
            [payload.patientId]: {
              ...state.patients.data[payload.patientId],
              goals: {
                ...state.patients.data[payload.patientId].goals,
                limpIndexGoals: state.patients.data[
                  payload.patientId
                ].goals.limpIndexGoals.map(g => {
                  if (g.id === payload.data.id) {
                    return payload.data
                  }

                  return g
                })
              }
            }
          }
        }
      }
    case Types.EDIT_LIMP_GOAL_SUCCESS:
      return {
        ...state,
        patients: {
          ...state.patients,
          data: {
            ...state.patients.data,
            [payload.patientId]: {
              ...state.patients.data[payload.patientId],
              goals: {
                ...state.patients.data[payload.patientId].goals,
                limpIndexGoals: [
                  ...state.patients.data[
                    payload.patientId
                  ].goals.limpIndexGoals.map(g => {
                    if (g.id === payload.id) {
                      return { ...g, deletedAt: Functions.getTimestamp() }
                    }

                    return g
                  }),
                  payload.data
                ]
              }
            }
          }
        }
      }
    case Types.CREATE_TRAININGS_GOAL_SUCCESS:
      return {
        ...state,
        patients: {
          ...state.patients,
          data: {
            ...state.patients.data,
            [payload.patientId]: {
              ...state.patients.data[payload.patientId],
              goals: {
                ...state.patients.data[payload.patientId].goals,
                numberOfTrainingGoal: [
                  ...state.patients.data[payload.patientId].goals.numberOfTrainingGoal,
                  payload.data
                ]
              }
            }
          }
        }
      }
    case Types.DELETE_TRAININGS_GOAL_SUCCESS:
      return {
        ...state,
        patients: {
          ...state.patients,
          data: {
            ...state.patients.data,
            [payload.patientId]: {
              ...state.patients.data[payload.patientId],
              goals: {
                ...state.patients.data[payload.patientId].goals,
                numberOfTrainingGoal: state.patients.data[
                  payload.patientId
                ].goals.numberOfTrainingGoal.map(g => {
                  if (g.id === payload.data.id) {
                    return payload.data
                  }

                  return g
                })
              }
            }
          }
        }
      }
    case Types.EDIT_TRAININGS_GOAL_SUCCESS:
      return {
        ...state,
        patients: {
          ...state.patients,
          data: {
            ...state.patients.data,
            [payload.patientId]: {
              ...state.patients.data[payload.patientId],
              goals: {
                ...state.patients.data[payload.patientId].goals,
                numberOfTrainingGoal: [
                  ...state.patients.data[
                    payload.patientId
                  ].goals.numberOfTrainingGoal.map(g => {
                    if (g.id === payload.id) {
                      return { ...g, deletedAt: Functions.getTimestamp() }
                    }

                    return g
                  }),
                  payload.data
                ]
              }
            }
          }
        }
      }
    case Types.SIGN_OUT_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}

function preparePatients(patients) {
  const data = {}

  patients.forEach(p => {
    data[p.id] = {
      ...p.patients,
      goals: p.goals,
      trainings: p.trainings.filter(t => t.result !== null && t.result >= 0)
    }
  })

  return data
}

function deletePatients(ids, patients) {
  const newPatients = { ...patients }

  ids.forEach(id => {
    delete newPatients[id]
  })

  return newPatients
}
