import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import product from '../../../helpers/Constants/product'
import strings from '../../../helpers/I18NStrings/index'
import * as Functions from '../../../helpers/Functions'

const tooltipHtml = "<path fill=\"none\" class=\"highcharts-label-box highcharts-tooltip-box highcharts-shadow\" d=\"M 3.5 0.5 L 118.5 0.5 C 121.5 0.5 121.5 0.5 121.5 3.5 L 121.5 41.5 C 121.5 44.5 121.5 44.5 118.5 44.5 L 109.5 44.5 103.5 50.5 97.5 44.5 3.5 44.5 C 0.5 44.5 0.5 44.5 0.5 41.5 L 0.5 3.5 C 0.5 0.5 0.5 0.5 3.5 0.5\" stroke=\"#000000\" stroke-opacity=\"0.049999999999999996\" stroke-width=\"5\" transform=\"translate(1, 1)\"></path><path fill=\"none\" class=\"highcharts-label-box highcharts-tooltip-box highcharts-shadow\" d=\"M 3.5 0.5 L 118.5 0.5 C 121.5 0.5 121.5 0.5 121.5 3.5 L 121.5 41.5 C 121.5 44.5 121.5 44.5 118.5 44.5 L 109.5 44.5 103.5 50.5 97.5 44.5 3.5 44.5 C 0.5 44.5 0.5 44.5 0.5 41.5 L 0.5 3.5 C 0.5 0.5 0.5 0.5 3.5 0.5\" stroke=\"#000000\" stroke-opacity=\"0.09999999999999999\" stroke-width=\"3\" transform=\"translate(1, 1)\"></path><path fill=\"none\" class=\"highcharts-label-box highcharts-tooltip-box highcharts-shadow\" d=\"M 3.5 0.5 L 118.5 0.5 C 121.5 0.5 121.5 0.5 121.5 3.5 L 121.5 41.5 C 121.5 44.5 121.5 44.5 118.5 44.5 L 109.5 44.5 103.5 50.5 97.5 44.5 3.5 44.5 C 0.5 44.5 0.5 44.5 0.5 41.5 L 0.5 3.5 C 0.5 0.5 0.5 0.5 3.5 0.5\" stroke=\"#000000\" stroke-opacity=\"0.15\" stroke-width=\"1\" transform=\"translate(1, 1)\"></path><path fill=\"rgba(247,247,247,0.85)\" class=\"highcharts-label-box highcharts-tooltip-box\" d=\"M 3.5 0.5 L 118.5 0.5 C 121.5 0.5 121.5 0.5 121.5 3.5 L 121.5 41.5 C 121.5 44.5 121.5 44.5 118.5 44.5 L 109.5 44.5 103.5 50.5 97.5 44.5 3.5 44.5 C 0.5 44.5 0.5 44.5 0.5 41.5 L 0.5 3.5 C 0.5 0.5 0.5 0.5 3.5 0.5\" stroke=\"darkBlue\" stroke-width=\"1\"></path><text x=\"8\" data-z-index=\"1\" style=\"font-size:12px;color:#333333;cursor:default;fill:#333333;\" y=\"20\"><tspan style=\"font-size: 10px\">{0}</tspan><br/><tspan x=\"8\" dy=\"15\">●</tspan><tspan dx=\"0\"> {1}: </tspan><tspan style=\"font-weight:bold\" dx=\"0\">{2}</tspan></text>"

Highcharts.dateFormats = {
  W: function (timestamp) {
    var date = new Date(timestamp)
    return Functions.convertDateToWeekNumber(date)
  }
}

export const LimpIndexPerDay = ({ data, goalData }) => (
  <HighchartsReact
    highcharts={Highcharts}
    options={{
      title: {
        text: strings.TextLimpingIndex
      },
      yAxis: {
        title: {
          text: strings.TextLimpingIndex
        },
        max: 100,
        min: 0
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          month: '%e. %b',
          year: '%b'
        },
        title: {
          text: strings.TextDate
        },
        min: data.length > 1 ? data[0][0] : null,
        max: data.length > 1 ? data[data.length - 1][0] : null
      },
      series: [
        {
          data,
          color: 'rgba(0, 0, 0, 1)',
          name: strings.TextLimpingIndex
        },
        {
          data: goalData,
          color: product.mainColor,
          name: strings.TextTarget,
          lineWidth: 2,
        }
      ],
      legend: {
        enabled: false
      },
      plotOptions: {
        line: {
          lineWidth: 2,
          states: {
            hover: {
              lineWidthPlus: 0
            }
          }
        }
      },
    }}
  />
)

export const StepsPerDay = ({ data }) => (
  <HighchartsReact
    highcharts={Highcharts}
    options={{
      chart: {
        type: 'column'
      },
      title: {
        text: strings.TextNrOfSteps
      },
      yAxis: {
        title: {
          text: strings.TextNrOfSteps
        }
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          month: '%e. %b',
          year: '%b'
        },
        title: {
          text: strings.TextDate
        }
      },
      series: [
        {
          data,
          color: 'darkBlue',
          name: strings.TextNrOfSteps
        }
      ],
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          pointPadding: 0.1,
        }
      },
    }}
  />
)

export const ActiveTimePerDay = ({ data, goalData }) => (
  <HighchartsReact
    highcharts={Highcharts}
    options={{
      chart: {
        type: 'column'
      },
      title: {
        text: strings.TextActiveTimeMins
      },
      yAxis: {
        title: {
          text: strings.TextActiveTimeMins
        }
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 7 * 24 * 3600 * 1000,
        labels: {
          format: '{value:' + strings.ShortWeek + '%W}',
          align: 'right',
          rotation: -30
        },
        title: {
          text: strings.Week
        }
      },
      series: [
        {
          type: 'column',
          data,
          color: 'darkGreen',
          name: strings.TextActiveTime,
        },
        {
          type: 'line',
          data: goalData,
          color: product.mainColor,
          name: strings.TextTarget,
        }
      ],
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          pointPadding: 0.1,
        }
      },
      tooltip: {
        formatter: function () {
          const date = new Date(this.x)
          return strings.formatString(tooltipHtml, date.getFullYear() + ' ' + strings.Week + ' ' + Functions.convertDateToWeekNumber(date), this.series.name, this.y + " " + strings.TextMinutes.charAt(0).toLowerCase() + strings.TextMinutes.substring(1))
        }
      }
    }}
  />
)

export const NumberOfTrainingsPerWeek = ({ data, goalData }) => (
  <HighchartsReact
    highcharts={Highcharts}
    options={{
      chart: {
        type: 'column'
      },
      title: {
        text: strings.NumberOfTrainings
      },
      yAxis: {
        title: {
          text: strings.NumberOfTrainings
        },
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 7 * 24 * 3600 * 1000,
        labels: {
          format: '{value:' + strings.ShortWeek + '%W}',
          align: 'right',
          rotation: -30
        },
        title: {
          text: strings.Week
        }
      },
      series: [
        {
          type: 'column',
          data,
          color: 'darkGreen',
          name: strings.NumberOfTrainings,
        },
        {
          type: 'line',
          data: goalData,
          color: product.mainColor,
          name: strings.TextTarget,
        },
      ],
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          pointPadding: 0.1,
        }
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          const date = new Date(this.x)
          return strings.formatString(tooltipHtml, date.getFullYear() + ' ' + strings.Week + ' ' + Functions.convertDateToWeekNumber(date), this.series.name, this.y)
        }
      },
    }}
  />
)

export const KadensPerDay = ({ data }) => (
  <HighchartsReact
    highcharts={Highcharts}
    options={{
      title: {
        text: strings.TextStepsPerMinute
      },
      yAxis: {
        title: {
          text: strings.TextStepsPerMinute
        }
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          month: '%e. %b',
          year: '%b'
        },
        title: {
          text: strings.TextDate
        }
      },
      series: [
        {
          data,
          color: 'darkBlue',
          name: strings.TextStepsPerMinute
        }
      ],
      legend: {
        enabled: false
      },
      plotOptions: {
        line: {
          lineWidth: 2,
          states: {
            hover: {
              lineWidthPlus: 0
            }
          }
        }
      },
    }}
  />
)

export const CompareTrainingsColumn = ({ data, title }) => (
  <HighchartsReact
    highcharts={Highcharts}
    options={{
      title: {
        text: title
      },
      chart: {
        type: 'column'
      },
      yAxis: {
        title: {
          text: title
        }
      },
      xAxis: {
        type: 'category',
        title: {
          text: strings.TextDate
        }
      },
      series: [
        {
          colorByPoint: true,
          name: title,
          data
        }
      ],
      legend: {
        enabled: false
      }
    }}
  />
)

export const PerMinute = ({ series, title, yTitle }) => (
  <HighchartsReact
    highcharts={Highcharts}
    options={{
      chart: {
        type: 'scatter',
        zoomType: 'xy'
      },
      title: {
        text: title
      },
      xAxis: {
        title: {
          enabled: true,
          text: strings.TextMinute
        },
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true
      },
      yAxis: {
        title: {
          text: yTitle
        }
      },
      legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'top',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        borderWidth: 1
      },
      plotOptions: {
        scatter: {
          marker: {
            radius: 5,
            states: {
              hover: {
                enabled: true,
                lineColor: 'rgb(100,100,100)'
              }
            }
          },
          states: {
            hover: {
              marker: {
                enabled: false
              }
            }
          },
          tooltip: {
            headerFormat: '<b>{series.name}</b><br>',
            pointFormat: '{yTitle}: {point.y}'
          }
        }
      },
      series
    }}
  />
)
