import * as Types from '../actions/Types'

const INITIAL_STATE = {
  isCreating: false,
  errorCreatingStatus: null,
  selectedId: null,
  isUpdating: false,
  errorUpdatingStatus: null,
  isUpdatingGoals: false,
  errorUpdatingGoalsStatus: null,
  createdPatient: null,
  isDeleting: false,
  errorDeletingStatus: null,
  isImporting: false,
  errorImportingStatus: null,
  isChangingPassword: false,
  errorChangingPassword: null,
  isChangingUsername: false,
  errorChangingUsername: null,
}

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action

  switch (type) {
  case Types.CREATE_PATIENT:
    return {
      ...state,
      isCreating: true,
      errorCreatingStatus: null
    }
  case Types.CREATE_PATIENT_SUCCESS:
    return {
      ...state,
      isCreating: false,
      errorCreatingStatus: null,
      createdPatient: payload.patient.id
    }
  case Types.CREATE_PATIENT_ERROR:
    return {
      ...state,
      isCreating: false,
      errorCreatingStatus: payload
    }
  case Types.IMPORT_PATIENT:
    return {
      ...state,
      isImporting: true,
      errorImportingStatus: null
    }
  case Types.IMPORT_PATIENT_SUCCESS:
    return {
      ...state,
      isImporting: false,
      errorImportingStatus: null
    }
  case Types.IMPORT_PATIENT_ERROR:
    return {
      ...state,
      isImporting: false,
      errorImportingStatus: payload
    }
  case Types.UPDATE_PATIENT:
    return {
      ...state,
      isUpdating: true,
      errorUpdatingStatus: null
    }
  case Types.UPDATE_PATIENT_SUCCESS:
    return {
      ...state,
      isUpdating: false,
      errorUpdatingStatus: null
    }
  case Types.UPDATE_PATIENT_ERROR:
    return {
      ...state,
      isUpdating: false,
      errorUpdatingStatus: payload
    }
  case Types.UPDATE_PATIENT_PASSWORD:
    return {
      ...state,
      isChangingPassword: true,
      errorChangingPassword: null
    }
  case Types.UPDATE_PATIENT_PASSWORD_SUCCESS:
    return {
      ...state,
      isChangingPassword: false,
      errorChangingPassword: null
    }
  case Types.UPDATE_PATIENT_PASSWORD_ERROR:
    return {
      ...state,
      isChangingPassword: false,
      errorChangingPassword: payload
    }
  case Types.UPDATE_PATIENT_USERNAME:
    return {
      ...state,
      isChangingUsername: true,
      errorChangingUsername: null
    }
  case Types.UPDATE_PATIENT_USERNAME_SUCCESS:
    return {
      ...state,
      isChangingUsername: false,
      errorChangingUsername: null
    }
  case Types.UPDATE_PATIENT_USERNAME_ERROR:
    return {
      ...state,
      isChangingUsername: false,
      errorChangingUsername: payload
    }
  case Types.DELETE_PATIENTS:
    return {
      ...state,
      isDeleting: true,
      errorDeletingStatus: null
    }
  case Types.DELETE_PATIENTS_SUCCESS:
    return {
      ...state,
      isDeleting: false,
      errorDeletingStatus: null
    }
  case Types.DELETE_PATIENTS_ERROR:
    return {
      ...state,
      isDeleting: false,
      errorDeletingStatus: payload
    }
  case Types.CREATE_GOALS:
    return {
      ...state,
      isUpdatingGoals: true,
      errorUpdatingGoalsStatus: null
    }
  case Types.CREATE_GOALS_SUCCESS:
    return {
      ...state,
      isUpdatingGoals: false,
      errorUpdatingGoalsStatus: null
    }
  case Types.CREATE_GOALS_ERROR:
    return {
      ...state,
      isUpdatingGoals: false,
      errorUpdatingGoalsStatus: payload
    }
  case Types.SELECT_PATIENT:
    return {
      ...state,
      selectedId: payload
    }
  case Types.SIGN_OUT_SUCCESS:
    return {
      ...state,
      ...INITIAL_STATE
    }
  default:
    return state
  }
}
