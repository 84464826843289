import * as Types from './Types'
import * as API from './api/patients'

export function tryFetchPatient(token, userId) {
  return dispatch => {
    dispatch({
      type: Types.GET_PATIENT
    })

    Promise.all([API.getTraining(token, userId), API.getGoals(token, userId)])
      .then(response => {
        dispatch({
          type: Types.GET_PATIENT_SUCCESS,
          payload: {
            trainings: response[0].data,
            goals: response[1].data
          }
        })
      })
      .catch(error => {
        dispatch({
          type: Types.GET_PATIENT_ERROR
        })
      })
  }
}
