import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CssBaseline from '@material-ui/core/CssBaseline'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'

import Header from '../../../containers/Header'
import FullscreenLoader from '../../../common/FullscreenLoader'

import * as configActions from '../../../../actions/Config'
import * as userActions from '../../../../actions/User'
import * as patientsActions from '../../../../actions/Patients'
import * as userStatisticsPageActions from '../../../../actions/UserStatisticsPage'
import * as Constants from '../../../../helpers/Constants'

import {
  HomeListItem,
  UsersListItem,
  AddUserItem,
  SignOutItem,
  InformationItem,
  ImportUserItem,
  ProviderSettingsItem,
  MyResultsItem,
  MyGoalsItem,
  ProvidersItem,
  NewProvidersItem,
  SettingsItem,
  ClinicsItem,
  AddClinicItem
} from '../../../common/ListItems'
import strings from '../../../../helpers/I18NStrings/index'

class SideBar extends React.Component {
  state = {
    height: window.innerHeight,
    width: window.innerWidth,
    searchInput: '',
    open: false
  }

  resizeHandler = this.resizeHandler.bind(this)

  componentDidMount() {
    const { user } = this.props

    if (user.isValidatingToken) {
      this.props.tryValidateToken()
    }

    window.addEventListener('resize', this.resizeHandler)
  }

  componentWillReceiveProps(nextProps) {
    const { history, user } = this.props

    if (!nextProps.user.signedIn && user.signedIn) {
      history.push('/')
    }

    if (
      !nextProps.user.isValidatingToken &&
      user.isValidatingToken &&
      nextProps.user.errorValidatingToken
    ) {
      history.push('/')
    }

    if (
      !nextProps.user.isSettingNewPassword &&
      user.isSettingNewPassword &&
      nextProps.user.forceNewPassword
    ) {
      this.props.showAlert(
        strings.UnexpectedErrorHeader,
        strings.UnexpectedError,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => {} }
      )
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler)
  }

  resizeHandler() {
    this.setState({ height: window.innerHeight, width: window.innerWidth })
  }

  getPatients() {
    const { data } = this.props
    const { searchInput } = this.state
    const patients = []

    Object.keys(data.patients.data).forEach(key => {
      patients.push(data.patients.data[key])
    })

    return patients.filter(
      p =>
        p.id
          .toString()
          .toLowerCase()
          .indexOf(searchInput.toLowerCase()) !== -1
    )
  }

  requestChangePage(page) {
    const { config, history } = this.props
    const currentPage = window.location.pathname

    if (page !== currentPage) {
      if (currentPage === '/patients/create') {
        this.props.showAlert(
          strings.LeavePageHeader,
          strings.LeavePage,
          Constants.TWO_BUTTONS,
          {
            title: strings.ButtonNo,
            onPress: () => {}
          },
          {
            title: strings.ButtonYes,
            onPress: () => history.push(page)
          }
        )
      } else {
        history.push(page)
      }
    }
  }

  requestSignOut() {
    this.props.showAlert(
      strings.SignOutHeader,
      strings.SignOut,
      Constants.TWO_BUTTONS,
      {
        title: strings.ButtonNo,
        onPress: () => {}
      },
      {
        title: strings.ButtonYes,
        onPress: () => this.props.signOut()
      }
    )
  }

  renderHealthcareDrawer() {
    const { user } = this.props
    const currentPage = window.location.pathname
    const preFix =
      user.type === Constants.CLINIC
        ? `${currentPage.split('/')[1]}/${currentPage.split('/')[2]}/`
        : ''
    
    return (
      <List>
        <HomeListItem
          selected={currentPage === `/${preFix}patients`}
          onClick={() => this.requestChangePage(`/${preFix}patients`)}
        />
        <UsersListItem
          selected={
            currentPage.indexOf('/statistics') !== -1 ||
            currentPage.indexOf('/settings') !== -1 ||
            currentPage.indexOf('/goals') !== -1 ||
            currentPage.indexOf('/trainings') !== -1
          }
          onClick={() =>
            this.requestChangePage(`/${preFix}patients/statistics`)
          }
        />
        <AddUserItem
          selected={currentPage === `/${preFix}patients/create`}
          onClick={() => this.requestChangePage(`/${preFix}patients/create`)}
          hidden={user.type === Constants.CLINIC}
        />
        <ImportUserItem
          selected={currentPage === `/${preFix}patients/import`}
          onClick={() => this.requestChangePage(`/${preFix}patients/import`)}
          hidden={user.type === Constants.CLINIC}
        />
        <InformationItem
          selected={currentPage === '/information'}
          onClick={() => this.requestChangePage('/information')}
          hidden={user.type === Constants.CLINIC}
        />
        <ProviderSettingsItem
          selected={currentPage === `/${preFix}providersettings`}
          onClick={() => this.requestChangePage(`/${preFix}providersettings`)}
          hidden={user.type !== Constants.CLINIC}
        />
        <SignOutItem onClick={() => this.requestSignOut()} />
      </List>
    )
  }

  renderContent() {
    const { user } = this.props
    const currentPage = window.location.pathname

    switch (user.type) {
      case Constants.HEALTHCARE_PROVIDER:
        return this.renderHealthcareDrawer()

      case Constants.PATIENT:
        return (
          <List>
            <MyResultsItem
              selected={currentPage === '/results'}
              onClick={() => this.requestChangePage('/results')}
            />
            <MyGoalsItem
              selected={currentPage === '/goals'}
              onClick={() => this.requestChangePage('/goals')}
            />
            <InformationItem
              selected={currentPage === '/information'}
              onClick={() => this.requestChangePage('/information')}
            />
            <SignOutItem onClick={() => this.requestSignOut()} />
          </List>
        )

      case Constants.CLINIC:
        if ((currentPage.indexOf('patients') !== -1) || (currentPage.indexOf('providersettings') !== -1)) {
          return this.renderHealthcareDrawer()
        }

        return (
          <List>
            <ProvidersItem
              selected={currentPage === '/providers'}
              onClick={() => this.requestChangePage('/providers')}
            />
            <NewProvidersItem
              selected={currentPage === '/providers/create'}
              onClick={() => this.requestChangePage('/providers/create')}
            />
            <SettingsItem
              selected={currentPage === '/clinic/settings'}
              onClick={() => this.requestChangePage('/clinic/settings')}
            />
            <InformationItem
              selected={currentPage === '/information'}
              onClick={() => this.requestChangePage('/information')}
            />
            <SignOutItem onClick={() => this.requestSignOut()} />
          </List>
        )

      case Constants.MEDOTEMIC:
        return (
          <List>
            <ClinicsItem
              selected={currentPage === '/clinics'}
              onClick={() => this.requestChangePage('/clinics')}
            />
            <AddClinicItem
              selected={currentPage === '/clinics/create'}
              onClick={() => this.requestChangePage('/clinics/create')}
            />
            <InformationItem
              selected={currentPage === '/information'}
              onClick={() => this.requestChangePage('/information')}
            />
            <SignOutItem onClick={() => this.requestSignOut()} />
          </List>
        )

      default:
        return null
    }
  }

  render() {
    const { config, patients, children, classes, user } = this.props
    const {
      searchInput,
      open,
      height,
      width,
      password,
      repeatPassword
    } = this.state
    const extraWidth = open ? 240 : 72
    const contentWidth = width - extraWidth
    const currentPage = window.location.pathname

    if (user.isValidatingToken) {
      return <FullscreenLoader />
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <div style={{ display: 'flex' }}>
          <Header open={open} onClose={() => this.setState({ open: true })} />

          <Drawer
            variant={'permanent'}
            classes={{
              paper: classNames(
                classes.drawerPaper,
                !open && classes.drawerPaperClose
              )
            }}
            open={open}
            style={{ zIndex: 19999 }}
          >
            <div
              style={{
                height: 64,
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <IconButton onClick={() => this.setState({ open: false })}>
                <ChevronLeftIcon color={'rgba(0, 0, 0, 0.87)'} />
              </IconButton>
            </div>
            {this.renderContent()}
          </Drawer>
          <div style={{ minHeight: height, width: contentWidth }}>
            {children}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const Classes = theme => ({
  root: {
    display: 'flex'
  },
  iconContainer: {
    display: 'flex',
    width: 40,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 240,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9
    }
  }
})

function mapStateToProps({ config, data, user, patients }) {
  return { config, data, user, patients }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...configActions,
      ...userActions,
      ...patientsActions,
      ...userStatisticsPageActions
    },
    dispatch
  )
}

export default compose(
  withStyles(Classes),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(withRouter(SideBar))
