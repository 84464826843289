const Styles = {
  container: {
    backgroundColor: '#f4f4f8',
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}

export default Styles
