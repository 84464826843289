import React from 'react'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import IdleTimer from 'react-idle-timer'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as userActions from '../actions/User'
import * as configActions from '../actions/Config'

// Common pages.
import SignIn from './pages/SignIn'
import Information from './pages/Information'

// Patient pages.
import MyResults from './pages/patient/MyResults'
import MyGoals from './pages/patient/MyGoals'

// Provider pages.
import Patients from './pages/provider/Patients'
import AddUser from './pages/provider/AddUser'
import ImportUser from './pages/provider/ImportUser'
import UserStatistics from './pages/provider/UserStatistics'
import UserSettings from './pages/provider/UserSettings'
import UserGoals from './pages/provider/UserGoals'
import Trainings from './pages/provider/Trainings'
import GoalHistory from './pages/provider/GoalHistory'

// Clinic pages.
import MyProviders from './pages/clinic/MyProviders'
import NewProvider from './pages/clinic/NewProvider'
import Settings from './pages/clinic/Settings'
import ProviderSettings from './pages/clinic/ProviderSettings'

// Medotemic pages.
import Clinics from './pages/medotemic/Clinics'
import NewClinic from './pages/medotemic/NewClinic'

// Utilities.
import Alert from './containers/Alert'
import Drawer from './containers/provider/Drawer'

const logoutTimeoutSec = 1000 * 60 * 30

class Router extends React.Component {
  constructor(props) {
    super(props)
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.logoutTimer = null
  }

  _onAction(e) {
    // console.log('user did something', e)
  }
 
  _onActive(e) {
    // console.log('user is active', e)
    // console.log('time remaining', this.idleTimer.getRemainingTime())
    this._stopTimeout()
  }
 
  _onIdle(e) {
    const { user } = this.props
    // console.log('user is idle', e)
    // console.log('last active', this.idleTimer.getLastActiveTime())
    if (user.token && user.id)
      this._startTimeout()
  }

  _startTimeout() {
    // console.log("starting timeout")
    this.logoutTimer = setTimeout(() => {
      const { user } = this.props
      
      // console.log("logging out user")
      if (user.token && user.id)
        this.props.signOut()
    }, logoutTimeoutSec)
  }

  _stopTimeout() {
    // console.log("trying to stopping timeout")
    if (this.logoutTimer) {
      // console.log("stopping timeout")
      clearTimeout(this.logoutTimer)
      this.logoutTimer = null
    }
  }

  render() {
    return (
      <div>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={logoutTimeoutSec}
        />
        <BrowserRouter>
          <Switch>
            <Route exact path={'/'} component={SignIn} />
            <Drawer>
              <Switch>
                // Patient routes.
                <Route exact path={'/results'} component={MyResults} />
                <Route exact path={'/goals'} component={MyGoals} />
                // Provider routes.
                <Route exact path={'/patients'} component={Patients} />
                <Route
                  exact
                  path={'/patients/statistics'}
                  component={UserStatistics}
                />
                <Route
                  exact
                  path={'/patients/:patientId/statistics'}
                  component={UserStatistics}
                />
                <Route
                  exact
                  path={'/patients/settings'}
                  component={UserSettings}
                />
                <Route
                  exact
                  path={'/patients/:patientId/settings'}
                  component={UserSettings}
                />
                <Route exact path={'/patients/goals'} component={UserGoals} />
                <Route
                  exact
                  path={'/patients/:patientId/goals'}
                  component={UserGoals}
                />
                <Route exact path={'/patients/create'} component={AddUser} />
                <Route exact path={'/patients/import'} component={ImportUser} />
                <Route
                  exact
                  path={'/patients/:patientId/trainings'}
                  component={Trainings}
                />
                <Route
                  exact
                  path={'/patients/:patientId/goallog'}
                  component={GoalHistory}
                />
                // Clinic routes
                <Route exact path={'/providers'} component={MyProviders} />
                <Route
                  exact
                  path={'/providers/create'}
                  component={NewProvider}
                />
                <Route exact path={'/clinic/settings'} component={Settings} />
                <Route
                  exact
                  path={'/providers/:providerId/providersettings'}
                  component={ProviderSettings}
                />
                <Route
                  exact
                  path={'/providers/:providerId/patients'}
                  component={Patients}
                />
                <Route
                  exact
                  path={'/providers/:providerId/patients/statistics'}
                  component={UserStatistics}
                />
                <Route
                  exact
                  path={'/providers/:providerId/patients/:patientId/statistics'}
                  component={UserStatistics}
                />
                <Route
                  exact
                  path={'/providers/:providerId/patients/settings'}
                  component={UserSettings}
                />
                <Route
                  exact
                  path={'/providers/:providerId/patients/:patientId/settings'}
                  component={UserSettings}
                />
                <Route
                  exact
                  path={'/providers/:providerId/patients/goals'}
                  component={UserGoals}
                />
                <Route
                  exact
                  path={'/providers/:providerId/patients/:patientId/goals'}
                  component={UserGoals}
                />
                <Route
                  exact
                  path={'/providers/:providerId/patients/create'}
                  component={AddUser}
                />
                <Route
                  exact
                  path={'/providers/:providerId/patients/import'}
                  component={ImportUser}
                />
                <Route
                  exact
                  path={'/providers/:providerId/patients/:patientId/trainings'}
                  component={Trainings}
                />
                <Route
                  exact
                  path={'/providers/:providerId/patients/:patientId/goallog'}
                  component={GoalHistory}
                />
                // Medotemic routes.
                <Route exact path={'/clinics'} component={Clinics} />
                <Route exact path={'/clinics/create'} component={NewClinic} />
                <Route
                  exact
                  path={'/clinics/:clinicId'}
                  component={NewClinic}
                />
                // Common routes.
                <Route exact path={'/information'} component={Information} />
              </Switch>
            </Drawer>
          </Switch>
        </BrowserRouter>
        <Alert />
      </div>
    )
  }
}

function mapStateToProps({ config, user }) {
  return { config, user }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...userActions,
      ...configActions,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Router)
