import Axios from 'axios'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8080/api/v1/'
    : 'https://portal.medotemic.com/api/v1/'

export function createClinic(token, userId, data) {
  const url = BASE_URL + 'medotemic/' + userId + '/clinics'

  return Axios.post(url, data, getConfig(token))
}

export function getClinics(token, userId) {
  const url = BASE_URL + 'medotemic/' + userId + '/clinics'

  return Axios.get(url, getConfig(token))
}

export function updateClinic(token, userId, clinicId, data) {
  const url = `${BASE_URL}medotemic/${userId}/clinics/${clinicId}`

  return Axios.patch(url, data, getConfig(token))
}

export function updateClinicUsername(token, userId, clinicId, data) {
  const url = `${BASE_URL}medotemic/${userId}/clinics/${clinicId}/updateUsername`

  return Axios.patch(url, data, getConfig(token))
}

export function updateClinicPassword(token, userId, clinicId, data) {
  const url = `${BASE_URL}medotemic/${userId}/clinics/${clinicId}/updatePassword`

  return Axios.patch(url, data, getConfig(token))
}

export function updateLicense(token, userId, clinicId, data) {
  const url = `${BASE_URL}medotemic/${userId}/clinics/${clinicId}/licenses`

  return Axios.patch(url, data, getConfig(token))
}

function getConfig(token) {
  return { headers: { authentication: 'Bearer ' + token } }
}
