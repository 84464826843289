import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import MatButton from '@material-ui/core/Button'
import {
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from '../../../common/Button'
import Goals from '../../../common/Goals'
import Modal from '../../../common/Modal'
import GoBack from '../../../common/GoBack'
import * as Forms from '../../../common/Forms'

import * as Styles from './styles'
import * as patientsActions from '../../../../actions/Patients'
import * as goalsActions from '../../../../actions/Goals'
import * as configActions from '../../../../actions/Config'
import * as clinicPatientsActions from '../../../../actions/clinic/Patients'
import * as Constants from '../../../../helpers/Constants'
import * as Functions from '../../../../helpers/Functions'
import product from '../../../../helpers/Constants/product'
import strings from '../../../../helpers/I18NStrings/index'

const INITIAL_STATE = {
  showEditLimpGoal: false,
  showCreateLimpGoal: false,
  showEditMinuteGoal: false,
  showCreateMinuteGoal: false,
  showEditTrainingsGoal: false,
  showCreateTrainingsGoal: false,
  createStartIndex: '',
  createEndIndex: '',
  createStartDate: '',
  createEndDate: '',
  createMinutes: '',
  createMinutePeriod: Constants.WEEK,
  createTrainings: '',
  createLimpEmptyError: false,
  createLimpIndexError: false,
  createLimpDateError: false,
  createMinutesError: false,
  createTrainingsError: false,
  editStartIndex: '',
  editEndIndex: '',
  editStartDate: '',
  editEndDate: '',
  editLimpIndexError: false,
  editLimpDateError: false,
  editLimpEmptyError: false,
  editMinutes: '',
  editMinutePeriod: '',
  editMinuteError: false,
  editTrainings: '',
  editTrainingsError: false,
  changePatient: false
}

class UserGoals extends React.Component {
  state = INITIAL_STATE

  componentDidMount() {
    const { user, data, match } = this.props

    if (match.params.providerId) {
      this.props.tryGetProviderPatients(
        user.token,
        user.id,
        match.params.providerId
      )
    } else if (!data.patients.fetched) {
      this.props.tryGetPatients(user.token, user.id)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { goalsState } = nextProps
    if (
      !goalsState.isLoading &&
      goalsState.error === null &&
      this.props.goalsState.isLoading
    ) {
      this.setState(INITIAL_STATE)
    }

    if (
      (goalsState.error && !this.props.goalsState.error)
    ) {
      this.props.showAlert(
        strings.UnexpectedErrorHeader,
        strings.UnexpectedError,
        Constants.ONE_BUTTON,
        { title: strings.ButtonOK, onPress: () => { } }
      )
    }
  }

  getActivatedText(activated) {
    return (
      <p style={{ color: activated ? product.mainColor : '#f26363' }}>
        {activated ? strings.TextActivated : strings.TextNotActivated}
      </p>
    )
  }

  requestDeleteGoal(onYes) {
    this.props.showAlert(
      strings.RemoveTargetsHeader,
      strings.RemoveTargets,
      Constants.TWO_BUTTONS,
      {
        title: strings.ButtonNo,
        onPress: () => { }
      },
      {
        title: strings.ButtonYes,
        onPress: () => onYes()
      }
    )
  }

  renderContent() {
    const {
      classes,
      data,
      user,
      history,
      goalsState,
      match
    } = this.props
    const {
      showEditLimpGoal,
      showCreateLimpGoal,
      showEditMinuteGoal,
      showCreateMinuteGoal,
      showEditTrainingsGoal,
      showCreateTrainingsGoal,
      createStartIndex,
      createEndIndex,
      createStartDate,
      createEndDate,
      createMinutes,
      createMinutePeriod,
      createTrainings,
      createLimpEmptyError,
      createLimpIndexError,
      createLimpDateError,
      createMinutesError,
      createTrainingsError,
      editStartIndex,
      editEndIndex,
      editStartDate,
      editEndDate,
      editLimpIndexError,
      editLimpDateError,
      editLimpEmptyError,
      editMinutes,
      editMinutePeriod,
      editMinuteError,
      editTrainings,
      editTrainingsError,
    } = this.state

    if (match.params.patientId) {
      const patient = data.patients.data[match.params.patientId]

      if (patient) {
        const goals = Functions.getActiveGoals(patient.goals)

        return (
          <div>
            <GoBack
              history={history}
              show={match.params.providerId}
              title={strings.TextBackToClinicView}
            />
            <p
              className={'header'}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer'
              }}
              onClick={() => this.setState({ changePatient: true })}
            >
              {strings.TextPatient + ' '}
              <span style={{ color: product.mainColor, marginLeft: 12 }}>
                {match.params.patientId}
              </span>
              <FontAwesomeIcon
                icon={faChevronDown}
                style={{ marginLeft: 12, fontSize: 24 }}
              />
            </p>

            <Button
              className={'success'}
              onClick={() =>
                user.type === Constants.CLINIC
                  ? history.push(
                    `/providers/${match.params.providerId}/patients/${
                    match.params.patientId
                    }/statistics`
                  )
                  : history.push(
                    `/patients/${match.params.patientId}/statistics`
                  )
              }
              header={strings.TextResults}
            />
            <Button
              style={{ marginLeft: 24 }}
              className={'success'}
              onClick={() =>
                history.push(
                  user.type === Constants.CLINIC
                    ? `/providers/${match.params.providerId}/patients/${
                    match.params.patientId
                    }/settings`
                    : `/patients/${match.params.patientId}/settings`
                )
              }
              header={strings.TextProfile}
            />

            <Goals
              goals={goals}
              hint={strings.TextSetUpTargetPatient}
              emptyLimpText={strings.TextNoReducingTargetPatient}
              emptyMinuteText={strings.TextNoMinuteTargetPatient}
              emptyTrainingsText={strings.TextNoWalksTargetPatient}
              options
              onRemoveLimpGoal={() =>
                this.requestDeleteGoal(() =>
                  this.props.tryDeleteLimpGoal(
                    user.token,
                    user.id,
                    match.params.patientId,
                    goals.limpIndexGoal.id
                  )
                )
              }
              onRemoveMinutesGoal={() =>
                this.requestDeleteGoal(() =>
                  this.props.tryDeleteMinuteGoal(
                    user.token,
                    user.id,
                    match.params.patientId,
                    goals.minutesWalkGoal.id
                  )
                )
              }
              onRemoveTrainingsGoal={() =>
                this.requestDeleteGoal(() =>
                  this.props.tryDeleteTrainingsGoal(
                    user.token,
                    user.id,
                    match.params.patientId,
                    goals.trainingsGoal.id
                  )
                )
              }
              onEditLimpGoal={() =>
                this.setState({
                  showEditLimpGoal: true,
                  editStartIndex: goals.limpIndexGoal.startIndex,
                  editEndIndex: goals.limpIndexGoal.endIndex,
                  editStartDate: goals.limpIndexGoal.startDate,
                  editEndDate: goals.limpIndexGoal.endDate
                })
              }
              onEditMinutesGoal={() =>
                this.setState({
                  showEditMinuteGoal: true,
                  editMinutes: goals.minutesWalkGoal.minutes,
                  editMinutePeriod: goals.minutesWalkGoal.period
                })
              }
              onEditTrainingsGoal={() =>
                this.setState({
                  showEditTrainingsGoal: true,
                  editTrainings: goals.trainingsGoal.numberOfTrainings
                })
              }
              onAddLimpGoal={() => this.setState({ showCreateLimpGoal: true })}
              onAddMinutesGoal={() =>
                this.setState({ showCreateMinuteGoal: true })
              }
              onAddTrainingsGoal={() =>
                this.setState({ showCreateTrainingsGoal: true })
              }
              historyHidden={user.type === Constants.CLINIC}
              onHistory={() =>
                user.type === Constants.CLINIC
                  ? history.push(
                    '/providers/' +
                    match.params.providerId +
                    '/patients/' +
                    match.params.patientId +
                    '/goallog'
                  )
                  : history.push(
                    '/patients/' + match.params.patientId + '/goallog'
                  )
              }
            />

            <Modal
              header={strings.TextChangeLimpTargets}
              show={showEditLimpGoal}
              loading={goalsState.isLoading}
              buttons={
                <div>
                  <MatButton
                    className={classes.successButtonText}
                    onClick={() => {
                      if (
                        editStartIndex === '' ||
                        editEndIndex === '' ||
                        editStartDate === '' ||
                        editEndDate === ''
                      ) {
                        this.setState({ editLimpEmptyError: true })
                      } else {
                        const validated = Functions.validateLimpGoal(
                          editStartIndex,
                          editEndIndex,
                          editStartDate,
                          editEndDate
                        )

                        if (validated === Constants.GOAL_OK) {
                          const postData = {
                            startIndex: editStartIndex,
                            endIndex: editEndIndex,
                            startDate: editStartDate,
                            endDate: editEndDate
                          }

                          this.props.tryEditLimpGoal(
                            user.token,
                            user.id,
                            match.params.patientId,
                            goals.limpIndexGoal.id,
                            postData
                          )
                        } else if (
                          validated === Constants.LIMP_INDEX_OUT_OF_BOUNCE
                        ) {
                          this.setState({ editLimpIndexError: true })
                        } else if (
                          validated === Constants.LIMP_INDEX_DATE_OUT_OF_BOUNCE
                        ) {
                          this.setState({ editLimpDateError: true })
                        }
                      }
                    }}
                  >
                    {strings.TextChange}
                  </MatButton>
                  <MatButton
                    onClick={() => this.setState(this.setState(INITIAL_STATE))}
                    className={classes.warningButtonText}
                  >
                    {strings.TextCancel}
                  </MatButton>
                </div>
              }
            >
              <Forms.LimpIndexGoal
                startIndex={editStartIndex}
                onStartIndex={text =>
                  this.setState({
                    editStartIndex: text,
                    editLimpIndexError: false,
                    editLimpEmptyError: false
                  })
                }
                endIndex={editEndIndex}
                onEndIndex={text =>
                  this.setState({
                    editEndIndex: text,
                    editLimpIndexError: false,
                    editLimpEmptyError: false
                  })
                }
                startDate={editStartDate}
                onStartDate={text =>
                  this.setState({
                    editStartDate: text,
                    editLimpDateError: false,
                    editLimpEmptyError: false
                  })
                }
                endDate={editEndDate}
                onEndDate={text =>
                  this.setState({
                    editEndDate: text,
                    editLimpDateError: false,
                    editLimpEmptyError: false
                  })
                }
                errorIndex={editLimpIndexError}
                errorDate={editLimpDateError}
                errorEmpty={editLimpEmptyError}
              />
              <p className={'hint'} style={{ marginTop: 48 }}>
                {strings.TextIndexBigger}
                <br />
                {strings.TextIndexBetween}
                <br />
                {strings.TextStartBfEnd}
              </p>
            </Modal>

            <Modal
              header={strings.TextCreateLimpTargets}
              show={showCreateLimpGoal}
              loading={goalsState.isLoading}
              buttons={
                <div>
                  <MatButton
                    className={classes.successButtonText}
                    onClick={() => {
                      if (
                        createStartIndex === '' ||
                        createEndIndex === '' ||
                        createStartDate === '' ||
                        createEndDate === ''
                      ) {
                        this.setState({ createLimpEmptyError: true })
                      } else {
                        const postData = {
                          startIndex: createStartIndex,
                          endIndex: createEndIndex,
                          startDate: createStartDate,
                          endDate: createEndDate
                        }

                        const validated = Functions.validateLimpGoal(postData)

                        if (validated === Constants.GOAL_OK) {
                          this.props.tryCreateLimpGoal(
                            user.token,
                            user.id,
                            match.params.patientId,
                            postData
                          )
                        } else if (
                          validated === Constants.LIMP_INDEX_OUT_OF_BOUNCE
                        ) {
                          this.setState({ createLimpIndexError: true })
                        } else if (
                          validated === Constants.LIMP_INDEX_DATE_OUT_OF_BOUNCE
                        ) {
                          this.setState({ createLimpDateError: true })
                        }
                      }
                    }}
                  >
                    {strings.ButtonCreate}
                  </MatButton>
                  <MatButton
                    onClick={() => this.setState(this.setState(INITIAL_STATE))}
                    className={classes.warningButtonText}
                  >
                    {strings.TextCancel}
                  </MatButton>
                </div>
              }
            >
              <Forms.LimpIndexGoal
                startIndex={createStartIndex}
                onStartIndex={text =>
                  this.setState({
                    createStartIndex: text,
                    createLimpIndexError: false,
                    createLimpEmptyError: false
                  })
                }
                endIndex={createEndIndex}
                onEndIndex={text =>
                  this.setState({
                    createEndIndex: text,
                    createLimpIndexError: false,
                    createLimpEmptyError: false
                  })
                }
                startDate={createStartDate}
                onStartDate={text =>
                  this.setState({
                    createStartDate: text,
                    createLimpDateError: false,
                    createLimpEmptyError: false
                  })
                }
                endDate={createEndDate}
                onEndDate={text =>
                  this.setState({
                    createEndDate: text,
                    createLimpDateError: false,
                    createLimpEmptyError: false
                  })
                }
                errorIndex={createLimpIndexError}
                errorDate={createLimpDateError}
                errorEmpty={createLimpEmptyError}
              />
              <p className={'hint'} style={{ marginTop: 48 }}>
                {strings.TextIndexBigger}
                <br />
                {strings.TextIndexBetween}
                <br />
                {strings.TextStartBfEnd}
              </p>
            </Modal>

            <Modal
              header={strings.TextChangeMinuteTargets}
              show={showEditMinuteGoal}
              loading={goalsState.isLoading}
              buttons={
                <div>
                  <MatButton
                    className={classes.successButtonText}
                    onClick={() => {
                      if (editMinutes === '' || editMinutes < 0) {
                        this.setState({ editMinuteError: true })
                      } else {
                        const postData = {
                          minutes: editMinutes,
                          period: editMinutePeriod
                        }

                        this.props.tryEditMinuteGoal(
                          user.token,
                          user.id,
                          match.params.patientId,
                          goals.minutesWalkGoal.id,
                          postData
                        )
                      }
                    }}
                  >
                    {strings.TextChange}
                  </MatButton>
                  <MatButton
                    onClick={() => this.setState(this.setState(INITIAL_STATE))}
                    className={classes.warningButtonText}
                  >
                    {strings.TextCancel}
                  </MatButton>
                </div>
              }
            >
              <Forms.MinuteGoal
                minutes={editMinutes}
                onMinutes={text =>
                  this.setState({ editMinutes: text, editMinuteError: false })
                }
                period={editMinutePeriod}
                onPeriod={text => this.setState({ editMinutePeriod: text })}
                error={editMinuteError}
              />
            </Modal>

            <Modal
              header={strings.TextCreateMinuteTargets}
              show={showCreateMinuteGoal}
              loading={goalsState.isLoading}
              buttons={
                <div>
                  <MatButton
                    className={classes.successButtonText}
                    onClick={() => {
                      if (createMinutes === '' || createMinutes < 0) {
                        this.setState({ createMinutesError: true })
                      } else {
                        const postData = {
                          minutes: createMinutes,
                          period: createMinutePeriod
                        }

                        this.props.tryCreateMinuteGoal(
                          user.token,
                          user.id,
                          match.params.patientId,
                          postData
                        )
                      }
                    }}
                  >
                    {strings.ButtonCreate}
                  </MatButton>
                  <MatButton
                    onClick={() => this.setState(this.setState(INITIAL_STATE))}
                    className={classes.warningButtonText}
                  >
                    {strings.TextCancel}
                  </MatButton>
                </div>
              }
            >
              <Forms.MinuteGoal
                minutes={createMinutes}
                onMinutes={text =>
                  this.setState({
                    createMinutes: text,
                    createMinutesError: false
                  })
                }
                period={createMinutePeriod}
                onPeriod={text => this.setState({ createMinutePeriod: text })}
                error={createMinutesError}
              />
            </Modal>

            <Modal
              header={strings.TextChangeWalksTargets}
              show={showEditTrainingsGoal}
              loading={goalsState.isLoading}
              buttons={
                <div>
                  <MatButton
                    className={classes.successButtonText}
                    onClick={() => {
                      if (editTrainings === '' || editTrainings < 0) {
                        this.setState({ editTrainingsError: true })
                      } else {
                        const postData = {
                          numberOfTrainings: editTrainings
                        }

                        this.props.tryEditTrainingsGoal(
                          user.token,
                          user.id,
                          match.params.patientId,
                          goals.trainingsGoal.id,
                          postData
                        )
                      }
                    }}
                  >
                    {strings.TextChange}
                  </MatButton>
                  <MatButton
                    onClick={() => this.setState(this.setState(INITIAL_STATE))}
                    className={classes.warningButtonText}
                  >
                    {strings.TextCancel}
                  </MatButton>
                </div>
              }
            >
              <Forms.TrainingsGoal
                trainings={editTrainings}
                onTrainings={text =>
                  this.setState({ editTrainings: text, editTrainingsError: false })
                }
                error={editTrainingsError}
              />
            </Modal>

            <Modal
              header={strings.TextCreateWalksTargets}
              show={showCreateTrainingsGoal}
              loading={goalsState.isLoading}
              buttons={
                <div>
                  <MatButton
                    className={classes.successButtonText}
                    onClick={() => {
                      if (editTrainings === '' || editTrainings < 0) {
                        this.setState({ editTrainingsError: true })
                      } else {
                        const postData = {
                          numberOfTrainings: editTrainings
                        }

                        this.props.tryCreateTrainingsGoal(
                          user.token,
                          user.id,
                          match.params.patientId,
                          postData
                        )
                      }
                    }}
                  >
                    {strings.ButtonCreate}
                  </MatButton>
                  <MatButton
                    onClick={() => this.setState(this.setState(INITIAL_STATE))}
                    className={classes.warningButtonText}
                  >
                    {strings.TextCancel}
                  </MatButton>
                </div>
              }
            >
              <Forms.TrainingsGoal
                trainings={editTrainings}
                onTrainings={text =>
                  this.setState({
                    editTrainings: text,
                    editTrainingsError: false
                  })
                }
                error={editTrainingsError}
              />
            </Modal>
          </div>
        )
      }
    }
  }

  render() {
    const { changePatient } = this.state
    const { classes, match, data, history, user } = this.props

    if (!data.patients.fetched) {
      return (
        <main style={Styles.default.loaderContainer}>
          <GoBack
            history={history}
            show={match.params.providerId}
            title={strings.TextBackToClinicView}
          />
          <CircularProgress
            style={{ color: product.mainColor }}
            thickness={2}
            size={82}
          />
        </main>
      )
    } else if (Object.keys(data.patients.data).length === 0) {
      return (
        <main style={Styles.default.loaderContainer}>
          <GoBack
            history={history}
            show={match.params.providerId}
            title={strings.TextBackToClinicView}
          />
          <p>{strings.TextNoPatientCreated}</p>
        </main>
      )
    }

    const patients = []

    Object.keys(data.patients.data).forEach(key => {
      patients.push(data.patients.data[key])
    })

    return (
      <main className={classes.content}>
        {this.renderContent()}
        <Modal
          show={
            !match.params.patientId ||
            !data.patients.data[match.params.patientId] ||
            changePatient
          }
          header={strings.TextChoosePatient}
          buttons={
            !match.params.patientId ? null : (
              <MatButton
                onClick={() => this.setState({ changePatient: false })}
                className={classes.successButtonText}
              >
                {strings.TextCancel}
              </MatButton>
            )
          }
        >
          {patients.map(p => {
            const selected = p.id == match.params.patientId

            let textStyles = {}

            if (selected) {
              textStyles = { color: product.mainColor, fontWeight: 'bold' }
            }

            return (
              <div
                key={p.id}
                style={{
                  paddingTop: 6,
                  paddingBottom: 6,
                  borderBottom: '1px solid #dddfe2',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  user.type === Constants.CLINIC
                    ? history.push(
                      `/providers/${match.providerId}/patients/${p.id}/goals`
                    )
                    : history.push(`/patients/${p.id}/goals`)

                  this.setState({ changePatient: false })
                }}
              >
                <p style={textStyles}>{strings.TextPatient + ' ' + p.id}</p>
              </div>
            )
          })}
        </Modal>
      </main>
    )
  }
}

const Classes = theme => ({
  innerContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
    paddingTop: 88
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  successButtonText: {
    color: product.mainColor
  },
  warningButtonText: {
    color: '#f26363'
  },
  root: {
    width: '100%',
    marginTop: 24,
    padding: 24
  }
})

UserGoals.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps({
  config,
  user,
  patients,
  data,
  goalsState
}) {
  return { config, user, patients, data, goalsState }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...configActions,
      ...patientsActions,
      ...goalsActions,
      ...clinicPatientsActions
    },
    dispatch
  )
}

export default compose(
  withStyles(Classes),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserGoals)
