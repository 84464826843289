import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Paper from '../../../common/Paper'
import Button from '../../../common/Button'
import GoBack from '../../../common/GoBack'

import * as clinicActions from '../../../../actions/clinic/Clinic'
import * as configActions from '../../../../actions/Config'
import * as Constants from '../../../../helpers/Constants'
import Inputs from '../../../common/Inputs'
import strings from '../../../../helpers/I18NStrings'
import { checkPassword, checkUsername } from '../../../../helpers/Functions'

class ProviderSettings extends React.Component {
    state = {
        username: '',
        password: '',
        repeatPassword: ''
    }

    componentWillReceiveProps(nextProps) {
        const { providerSettings } = this.props

        if (
            !nextProps.providerSettings.isChangingPassword &&
            providerSettings.isChangingPassword &&
            nextProps.providerSettings.errorChangingLoginStatus === null
        ) {
            this.setState({
                password: '',
                repeatPassword: ''
            })

            this.props.showAlert(
                strings.TextPasswordIsResetTitle,
                strings.TextPasswordIsReset,
                Constants.ONE_BUTTON,
                { title: strings.ButtonOK, onPress: () => { } }
            )
        } else if (!nextProps.providerSettings.isChangingPassword && providerSettings.isChangingPassword) {
            this.props.showAlert(
                strings.UnexpectedErrorHeader,
                strings.UnexpectedError,
                Constants.ONE_BUTTON,
                { title: strings.ButtonOK, onPress: () => { } }
            )
        }

        if (
            !nextProps.providerSettings.isChangingUsername &&
            providerSettings.isChangingUsername &&
            nextProps.providerSettings.errorChangingLoginStatus === null
        ) {
            this.setState({
                username: '',
                password: '',
                repeatPassword: ''
            })

            this.props.showAlert(
                strings.TextUpdatedUsernameTitle,
                strings.TextUpdatedUsername,
                Constants.ONE_BUTTON,
                { title: strings.ButtonOK, onPress: () => { } }
            )
        } else if (!nextProps.providerSettings.isChangingUsername && providerSettings.isChangingUsername) {
            if (nextProps.providerSettings.errorChangingLoginStatus === 409) {
                this.props.showAlert(
                    strings.UsernameInUseHeader,
                    strings.UsernameInUse,
                    Constants.ONE_BUTTON,
                    { title: strings.ButtonOK, onPress: () => { } }
                )
            } else {
                this.props.showAlert(
                    strings.UnexpectedErrorHeader,
                    strings.UnexpectedError,
                    Constants.ONE_BUTTON,
                    { title: strings.ButtonOK, onPress: () => { } }
                )
            }
        }
    }

    tryChangePassword() {
        const { user, match } = this.props
        const { password, repeatPassword } = this.state

        if (!checkPassword(password, repeatPassword, this.props)) return

        const data = { password, providerId: match.params.providerId }

        this.props.tryChangeProviderPassword(user.token, user.id, data)
    }

    tryChangeUsername() {
        const { user, match } = this.props
        const { username } = this.state

        if (!checkUsername(username, this.props)) return

        const data = { username, providerId: match.params.providerId }

        this.props.tryChangeProviderUsername(user.token, user.id, data)
    }

    render() {
        const { username, password, repeatPassword } = this.state
        const { classes, providerSettings, history, match } = this.props

        return (
            <main className={classes.content}>
                <GoBack
                    history={history}
                    show={match.params.providerId}
                    title={strings.TextBackToClinicView}
                />
                <p class={'header'}>{strings.TextCaregiver}</p>
                <div className={classes.providerIdContainer}>
                    <p class={'secondHeader'}>{strings.TextID}</p>
                    <p
                        class={'secondHeader'}
                        style={{ color: '#F26363', marginLeft: 25 }}
                    >
                        {match.params.providerId}
                    </p>
                </div>

                <div>
                    <p class={'secondHeader'}>{strings.TextUpdateUsername}</p>
                    <Paper style={{ alignItems: 'flex-start' }}>
                        <div className={classes.innerContainer}>
                            <p style={{ width: 150 }}>
                                {strings.TextUsername}:
                            <span className={'mandatoryStar'}>*</span>
                            </p>
                            <Inputs
                                placeholder={strings.TextUsername}
                                className={'noMargin'}
                                onChange={event =>
                                    this.setState({ username: event.target.value })
                                }
                                value={username}
                            />
                        </div>
                    </Paper>

                    <div className={classes.buttonContainer}>
                        <Button
                            className={'success'}
                            onClick={() => this.tryChangeUsername()}
                            loading={providerSettings.isChangingLogin}
                            header={strings.TextUpdateUsername}
                        />
                    </div>
                </div>

                <div>
                    <p class={'secondHeader'}>{strings.TextResetPassword}</p>
                    <Paper style={{ alignItems: 'flex-start', marginTop: 24 }}>
                        <div className={classes.innerContainer}>
                            <p style={{ width: 150 }}>
                                {strings.TextPassword}:
                                <span className={'mandatoryStar'}>*</span>
                            </p>
                            <Inputs
                                type={'password'}
                                placeholder={strings.TextPassword}
                                className={'noMargin'}
                                onChange={event =>
                                    this.setState({ password: event.target.value })
                                }
                                value={password}
                            />
                        </div>
                        <div className={classes.innerContainer}>
                            <p style={{ width: 150 }}>
                                {strings.TextRepeatPassword}:
                                <span className={'mandatoryStar'}>*</span>
                            </p>
                            <Inputs
                                type={'password'}
                                placeholder={strings.TextRepeatPassword}
                                className={'noMargin'}
                                onChange={event =>
                                    this.setState({
                                        repeatPassword: event.target.value
                                    })
                                }
                                value={repeatPassword}
                            />
                        </div>
                    </Paper>

                    <div className={classes.buttonContainer}>
                        <Button
                            className={'success'}
                            onClick={() => this.tryChangePassword()}
                            loading={providerSettings.isChangingLogin}
                            header={strings.TextResetPassword}
                        />
                    </div>
                </div>


            </main>
        )
    }
}

const Styles = theme => ({
    innerContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        height: '100vh',
        overflow: 'auto',
        paddingTop: 88
    },
    providerIdContainer: {
        display: 'flex'
    },
    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    }
})

ProviderSettings.propTypes = {
    classes: PropTypes.object.isRequired
}

function mapStateToProps({ config, user, providerSettings }) {
    return { config, user, providerSettings }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ...configActions, ...clinicActions }, dispatch)
}

export default compose(
    withStyles(Styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ProviderSettings)
