import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'

import * as Constants from '../../../helpers/Constants'
import * as configActions from '../../../actions/Config'
import product from '../../../helpers/Constants/product'

class Alert extends Component {
  render() {
    const { config, classes } = this.props
    let dialogActions = (
      <DialogActions>
        <Button
          onClick={() => {
            config.alert.firstButton.onPress()
            this.props.hideAlert()
          }}
          classes={{ label: classes.successButtonText }}
        >
          {config.alert.firstButton.title}
        </Button>
      </DialogActions>
    )

    switch (config.alert.type) {
      case Constants.TWO_BUTTONS:
        dialogActions = (
          <DialogActions>
            <Button
              onClick={() => {
                config.alert.firstButton.onPress()
                this.props.hideAlert()
              }}
              classes={{ label: classes.warningButtonText }}
            >
              {config.alert.firstButton.title}
            </Button>
            <Button
              onClick={() => {
                config.alert.secondButton.onPress()
                this.props.hideAlert()
              }}
              classes={{ label: classes.successButtonText }}
              autoFocus
            >
              {config.alert.secondButton.title}
            </Button>
          </DialogActions>
        )
        break
      case Constants.TWO_BUTTONS_SUCCESS:
        dialogActions = (
          <DialogActions>
            <Button
              onClick={() => {
                config.alert.firstButton.onPress()
                this.props.hideAlert()
              }}
              classes={{ label: classes.successButtonText }}
            >
              {config.alert.firstButton.title}
            </Button>
            <Button
              onClick={() => {
                config.alert.secondButton.onPress()
                this.props.hideAlert()
              }}
              classes={{ label: classes.successButtonText }}
              autoFocus
            >
              {config.alert.secondButton.title}
            </Button>
          </DialogActions>
        )
        break
      case Constants.THREE_BUTTONS:
        dialogActions = (
          <DialogActions>
            <Button
              onClick={() => {
                config.alert.firstButton.onPress()
                this.props.hideAlert()
              }}
              classes={{ label: classes.warningButtonText }}
            >
              {config.alert.firstButton.title}
            </Button>
            <Button
              onClick={() => {
                config.alert.secondButton.onPress()
                this.props.hideAlert()
              }}
              classes={{ label: classes.successButtonText }}
              autoFocus
            >
              {config.alert.secondButton.title}
            </Button>
            <Button
              onClick={() => {
                config.alert.thirdButton.onPress()
                this.props.hideAlert()
              }}
              classes={{ label: classes.successButtonText }}
              autoFocus
            >
              {config.alert.thirdButton.title}
            </Button>
          </DialogActions>
        )
        break
    }

    return (
      <div>
        <Dialog
          open={config.alert.show}
          onClose={() => this.props.hideAlert()}
          aria-labelledby={'alert-dialog-title'}
          aria-describedby={'alert-dialog-description'}
        >
          <DialogTitle id={'alert-dialog-title'}>
            {config.alert.header}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id={'alert-dialog-description'}>
              {config.alert.text}
            </DialogContentText>
          </DialogContent>
          {dialogActions}
        </Dialog>
      </div>
    )
  }
}

const Styles = {
  successButtonText: {
    color: product.mainColor
  },
  warningButtonText: {
    color: '#f26363'
  }
}

function mapStateToProps({ config }) {
  return { config }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...configActions }, dispatch)
}

export default compose(
  withStyles(Styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Alert)
