import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Table from '../../../common/Table'
import GoBack from '../../../common/GoBack'

import * as patientsActions from '../../../../actions/Patients'
import * as configActions from '../../../../actions/Config'
import product from '../../../../helpers/Constants/product'
import strings from '../../../../helpers/I18NStrings/index'

class GoalHistory extends React.Component {
  sortAndParseGoals(goals) {
    return goals
      .sort((o1, o2) => {
        if (o1 && o2) {
          return new Date(o2.createdAt).getTime() - new Date(o1.createdAt)
        }

        return -1
      })
      .map(g => {
        return {
          ...g,
          deletedAt: g.deletedAt ? g.deletedAt : strings.TextActive,
        }
      })
  }

  render() {
    const { classes, match, data, history } = this.props

    if (!data.patients.fetched) {
      history.push('/')

      return null
    }
    
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.content}>
          <GoBack history={history} />
          <p className={'header'}>
            {strings.TextPatient + ' '}
            <span style={{ color: product.mainColor }}>{match.params.patientId}</span>
          </p>
          <p className={'secondHeader'}>{strings.TextTargetLog}</p>

          <p className={'secondHeader'}>{strings.TextReducingTarget}</p>
          {data.patients.data[match.params.patientId].goals.limpIndexGoals
            .length === 0 ? (
              <p className={'warningText'} style={{ marginTop: 0 }}>
                {strings.TextNoActiveTargats}
              </p>
            ) : (
              <Table
                columns={[
                  { id: 'createdAt', label: strings.TextCreated },
                  { id: 'deletedAt', label: strings.TextRemoved },
                  { id: 'startDate', label: strings.TextStartingDate },
                  { id: 'endDate', label: strings.TextEndDate },
                  { id: 'startIndex', label: strings.TextStartingIndex },
                  { id: 'endIndex', label: strings.TextFinalIndex }
                ]}
                rows={this.sortAndParseGoals(
                  data.patients.data[match.params.patientId].goals.limpIndexGoals
                )}
              />
            )}

          <p className={'secondHeader'}>{strings.TextMinuteTarget}</p>
          {data.patients.data[match.params.patientId].goals.minutesWalkGoals
            .length === 0 ? (
              <p className={'warningText'} style={{ marginTop: 0 }}>
                {strings.TextNoActiveTargets}              
              </p>
            ) : (
              <Table
                columns={[
                  { id: 'createdAt', label: strings.TextCreated },
                  { id: 'deletedAt', label: strings.TextRemoved },
                  { id: 'minutes', label: strings.TextMinutes }
                ]}
                rows={this.sortAndParseGoals(
                  data.patients.data[match.params.patientId].goals.minutesWalkGoals
                )}
              />
            )}

          <p className={'secondHeader'}>{strings.TextWalksTarget}</p>
          {data.patients.data[match.params.patientId].goals.numberOfTrainingGoal
            .length === 0 ? (
              <p className={'warningText'} style={{ marginTop: 0 }}>
                {strings.TextNoActiveTargets}
              </p>
            ) : (
              <Table
                columns={[
                  { id: 'createdAt', label: strings.TextCreated },
                  { id: 'deletedAt', label: strings.TextRemoved },
                  { id: 'numberOfTrainings', label: strings.TextNrOfWalks }
                ]}
                rows={this.sortAndParseGoals(
                  data.patients.data[match.params.patientId].goals.numberOfTrainingGoal
                )}
              />
            )}
        </main>
      </React.Fragment>
    )
  }
}

const Classes = theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
    paddingTop: 88
  }
})

GoalHistory.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps({ config, user, patients, data }) {
  return { config, user, patients, data }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...configActions, ...patientsActions }, dispatch)
}

export default compose(
  withStyles(Classes),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(GoalHistory)
