import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import MaterialTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import MaterialPaper from '@material-ui/core/Paper'

import TableHead from '../TableHead'
import strings from '../../../helpers/I18NStrings/index'

class Table extends React.Component {
  render() {
    const {
      classes,
      rowsPerPage, // With pagination
      setPaginationRowsPerPage, // With pagination
      page, // With pagination
      setPaginationPage, // With pagination
      columns,
      rows,
      pagination
    } = this.props

    let data = rows

    if (pagination) {
      const startIndex = page * rowsPerPage

      data = rows.slice(startIndex, rowsPerPage + startIndex)
    }

    return (
      <MaterialPaper className={classes.root}>
        <div className={classes.innerRoot}>
          <MaterialTable
            className={classes.table}
            aria-labelledby={'tableTitle'}
          >
            <TableHead rows={columns} />

            <TableBody>
              {data.map(r => (
                <TableRow
                  key={r.id}
                  className={classes.row}
                  style={{ cursor: 'auto' }}
                >
                  {columns.map(c => (
                    <TableCell>
                      <p style={{ fontSize: 14 }}>
                        {r[c.id] || strings.TextUnavailable}
                      </p>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </MaterialTable>
        </div>

        {pagination ? (
          <div className={classes.root}>
            <TablePagination
              component={'div'}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page'
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page'
              }}
              labelRowsPerPage={strings.TextRowsPerPage}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} ` + strings.TextOf + ` ${count}`
              }
              onChangePage={(event, page) => setPaginationPage(page)}
              onChangeRowsPerPage={event =>
                setPaginationRowsPerPage(event.target.value)
              }
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
            />
          </div>
        ) : null}
      </MaterialPaper>
    )
  }
}

const Classes = theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto'
  },
  emptyContent: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  table: {
    minWidth: 700
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fafafa'
    }
  },
  root: {
    width: '100%',
    marginTop: 24,
    padding: 0
  },
  innerRoot: {
    width: '100%',
    overflowX: 'auto'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

Table.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(Classes)(Table)
