import React from 'react'

import * as Constants from '../../../helpers/Constants'
import Inputs from '../../common/Inputs'
import strings from '../../../helpers/I18NStrings/index'

export const LimpIndexGoal = ({
  startIndex,
  endIndex,
  startDate,
  endDate,
  onStartIndex,
  onEndIndex,
  onStartDate,
  onEndDate,
  errorIndex,
  errorDate,
  errorEmpty,
  style
}) => (
  <div className={{ display: 'flex', flexDirection: 'column' }}>
    <div style={{ display: 'flex', ...style }}>
      <Inputs
        type={'number'}
        placeholder={strings.TextStartingIndex}
        className={
          errorIndex || (errorEmpty && startIndex === '')
            ? 'noMargin input-warning'
            : 'noMargin'
        }
        style={{width: 200, marginLeft: 0 }}
        onChange={event => onStartIndex(event.target.value)}
        value={startIndex}
        min={'0'}
        max={'100'}
      />
      <Inputs
        type={'number'}
        placeholder={strings.TextFinalIndex}
        className={
          errorIndex || (errorEmpty && endIndex === '')
            ? 'noMargin input-warning'
            : 'noMargin'
        }
        style={{width: 200, marginLeft: 24 }}
        onChange={event => onEndIndex(event.target.value)}
        value={endIndex}
        min={'0'}
        max={'100'}
      />
    </div>
    <div style={{ display: 'flex', marginTop: 24, ...style }}>
      <Inputs
        type={'date'}
        placeholder={strings.TextStartingDate}
        className={
          errorDate || (errorEmpty && startDate === '')
            ? 'noMargin input-warning'
            : 'noMargin'
        }
        style={{width: 200, marginLeft: 0 }}
        onChange={event => onStartDate(event.target.value)}
        value={startDate}
      />
      <Inputs
        type={'date'}
        placeholder={strings.TextEndDate}
        className={
          errorDate || (errorEmpty && endDate === '')
            ? 'noMargin input-warning'
            : 'noMargin'
        }
        style={{width: 200, marginLeft: 24 }}
        onChange={event => onEndDate(event.target.value)}
        value={endDate}
      />
    </div>
  </div>
)

export const MinuteGoal = ({
  minutes,
  period,
  onMinutes,
  onPeriod,
  error,
  style
}) => (
  <div style={{ display: 'flex', alignItems: 'center', ...style }}>
    <Inputs
      type={'number'}
      placeholder={strings.TextMinutes}
      style={{ marginLeft: 0 }}
      className={error ? 'noMargin input-warning' : 'noMargin'}
      onChange={event => onMinutes(event.target.value)}
      value={minutes}
      min={'0'}
    />
    <p style={{ marginLeft: 24 }}>{strings.TextMinutesWeek}</p>
  </div>
)

export const TrainingsGoal = ({ trainings, onTrainings, error, style }) => (
  <div style={{ display: 'flex', alignItems: 'center', ...style }}>
    <Inputs
      type={'number'}
      placeholder={strings.TextWalksWeek}
      style={{ marginLeft: 0 }}
      className={error ? 'noMargin input-warning' : 'noMargin'}
      onChange={event => onTrainings(event.target.value)}
      value={trainings}
      min={'0'}
    />
    <p style={{ marginLeft: 24 }}>{strings.TextWalksWeek}</p>
  </div>
)

export const Clinic = props => {
  const { edit, onChange, showName = true, lock } = props
  const inputs = [
    { key: 'name', show: showName, label: strings.TextClinicName, mandatory: true },
    { key: 'username', show: !edit, label: strings.TextUsername, mandatory: true },
    {
      key: 'password',
      show: !edit,
      label: strings.TextPassword,
      mandatory: true,
      type: 'password'
    },
    {
      key: 'repeatPassword',
      show: !edit,
      label: strings.TextRepeatPassword,
      mandatory: true,
      type: 'password'
    },
    {
      key: 'maxPatients',
      show: !edit,
      label: strings.TextMaxPatients,
      mandatory: true,
      type: 'number',
      min: 0
    },
    {
      key: 'price',
      show: !edit,
      label: strings.TextPrice,
      mandatory: true,
      type: 'number',
      min: 0,
      step: '100'
    },
    {
      key: 'expires',
      show: !edit,
      label: strings.TextValidity,
      mandatory: true,
      type: 'date'
    },
    {
      key: 'orgNumber',
      show: true,
      label: strings.TextCompanyNumber,
      mandatory: false
    },
    { key: 'email', show: true, label: strings.TextMail, mandatory: false },
    { key: 'phone', show: true, label: strings.TextNumber, mandatory: false },
    { key: 'address', show: true, label: strings.TextAddress, mandatory: false },
    { key: 'city', show: true, label: strings.TextCity, mandatory: false },
    { key: 'zipCode', show: true, label: strings.TextZipCode, mandatory: false }
  ]

  return inputs
    .filter(i => i.show)
    .map(i => {
      return (
        <div className={props.classes.innerContainer} id={i.key}>
          <p style={{ width: 150 }}>
            {i.label}:
            {i.mandatory ? <span className={'mandatoryStar'}>*</span> : null}
          </p>
          {lock ? (
            <p style={{ marginLeft: 100 }}>{props.inputs[i.key]}</p>
          ) : (
            <input
              placeholder={i.label}
              className={'noMargin'}
              style={{ marginTop: 0, marginLeft: 100 }}
              onChange={event => onChange(i.key, event.target.value)}
              value={props.inputs[i.key]}
              type={i.type}
              min={i.min}
              max={i.max}
              step={i.step}
            />
          )}
        </div>
      )
    })
}

export const License = props => {
  const { edit, onChange } = props
  const inputs = [
    {
      key: 'maxPatients',
      show: true,
      label: strings.TextMaxPatients,
      mandatory: true,
      type: 'number',
      min: 0
    },
    {
      key: 'price',
      show: true,
      label: strings.TextPrice,
      mandatory: true,
      type: 'number',
      min: 0,
      step: '100'
    },
    {
      key: 'expires',
      show: true,
      label: strings.TextValidity,
      mandatory: true,
      type: 'date'
    }
  ]

  return inputs
    .filter(i => i.show)
    .map(i => {
      return (
        <div className={props.classes.innerContainer} id={i.key}>
          <p style={{ width: 150 }}>
            {i.label}:
            {i.mandatory ? <span className={'mandatoryStar'}>*</span> : null}
          </p>
          <input
            placeholder={i.label}
            className={'noMargin'}
            style={{ marginTop: 0, marginLeft: 100 }}
            onChange={event => onChange(i.key, event.target.value)}
            value={props.inputs[i.key]}
            type={i.type}
            min={i.min}
            max={i.max}
            step={i.step}
          />
        </div>
      )
    })
}

export const Username = props => {
  const { edit, onChange } = props
  const inputs = [
    {
      key: 'newUsername',
      show: true,
      label: "Username",
      mandatory: true,
      type: 'text'
    }
  ]

  return inputs
    .filter(i => i.show)
    .map(i => {
      return (
        <div className={props.classes.innerContainer} id={i.key}>
          <p style={{ width: 150 }}>
            {i.label}:
            {i.mandatory ? <span className={'mandatoryStar'}>*</span> : null}
          </p>
          <input
            placeholder={i.label}
            className={'noMargin'}
            style={{ marginTop: 0, marginLeft: 100 }}
            onChange={event => onChange(i.key, event.target.value)}
            value={props.inputs[i.key]}
            type={i.type}
            min={i.min}
            max={i.max}
            step={i.step}
          />
        </div>
      )
    })
}

export const Password = props => {
  const { edit, onChange } = props
  const inputs = [
    {
      key: 'newPassword',
      show: true,
      label: strings.TextPassword,
      mandatory: true,
      type: 'password'
    },
    {
      key: 'repeatNewPassword',
      show: true,
      label: strings.TextRepeatPassword,
      mandatory: true,
      type: 'password'
    }
  ]

  return inputs
    .filter(i => i.show)
    .map(i => {
      return (
        <div className={props.classes.innerContainer} id={i.key}>
          <p style={{ width: 150 }}>
            {i.label}:
            {i.mandatory ? <span className={'mandatoryStar'}>*</span> : null}
          </p>
          <input
            placeholder={i.label}
            className={'noMargin'}
            style={{ marginTop: 0, marginLeft: 100 }}
            onChange={event => onChange(i.key, event.target.value)}
            value={props.inputs[i.key]}
            type={i.type}
            min={i.min}
            max={i.max}
            step={i.step}
          />
        </div>
      )
    })
}
