import clinicLogo from '../../assets/images/MedoClinic.png'
import clinicBg from '../../assets/images/sign_in_background.jpg'

import UserManualClinicApp from '../../assets/documents/user_manual_app_0.6.pdf'
import UserManualClinicWeb from '../../assets/documents/user_manual_webapp_190201.pdf'
import strings from '../I18NStrings/index'

let medoclinic = {
    product: "medoclinic",
    title: "MedoClinic",
    mainColor: "#46A667",
    secondaryColor: "#1C4429",
    thirdColor: '#79d895',
    bigLogo: clinicLogo,
    SignInBackground: clinicBg,
    DisplayGoalPage: true,
    DisplayKadensGraph: true,
    ManualApp: UserManualClinicApp,
    ManualWeb: UserManualClinicWeb,
    showTrainingCheckbox: false,
    useOtherWebHeader: strings.NotAConnectedPatientHeader,
    useOtherWeb: strings.NotAConnectedPatient,
    useOtherWebLink: "https://web.medomotion.se/",
    useOtherWebButton: strings.TextMMWeb
}

let product = medoclinic

document.title = product.title

export default product;