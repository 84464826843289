import React, { Component } from 'react'
import MatieralCheckbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'
import product from '../../../helpers/Constants/product'

class Checkbox extends Component {
  render() {
    const {
      label,
      checked,
      classes,
      style,
      onChange,
      indeterminate = false
    } = this.props

    return (
      <FormControlLabel
        control={
          <MatieralCheckbox
            indeterminate={indeterminate}
            checked={checked}
            onChange={() => onChange()}
            classes={{ root: classes.root, checked: classes.checked }}
          />
        }
        label={label}
        style={style}
        classes={{ label: classes.label }}
      />
    )
  }
}

const Styles = {
  root: {
    color: '#2a2f40',
    '&$checked': {
      color: product.mainColor
    }
  },
  checked: {},
  label: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 20
  }
}

export default withStyles(Styles)(Checkbox)
