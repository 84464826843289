import * as Types from './Types'

export function showTables(show) {
  return dispatch => {
    dispatch({
      type: Types.USER_STATISTICS_PAGE_SHOW_TABLES,
      payload: show
    })
  }
}

export function showLimpGraphs(show) {
  return dispatch => {
    dispatch({
      type: Types.USER_STATISTICS_PAGE_SHOW_LIMP_GRAPHS,
      payload: show
    })
  }
}

export function showNumberOfTrainingsGraphs(show) {
  return dispatch => {
    dispatch({
      type: Types.USER_STATISTICS_PAGE_SHOW_NUMBER_OF_TRAININGS_GRAPHS,
      payload: show
    })
  }
}

export function showStepGraphs(show) {
  return dispatch => {
    dispatch({
      type: Types.USER_STATISTICS_PAGE_SHOW_STEP_GRAPHS,
      payload: show
    })
  }
}

export function showActiveTimeGraphs(show) {
  return dispatch => {
    dispatch({
      type: Types.USER_STATISTICS_PAGE_SHOW_ACTIVE_TIME_GRAPHS,
      payload: show
    })
  }
}

export function showKadensGraphs(show) {
  return dispatch => {
    dispatch({
      type: Types.USER_STATISTICS_PAGE_SHOW_KADENS_GRAPHS,
      payload: show
    })
  }
}

export function setSortOrder(orderBy, order, sortType) {
  return dispatch => {
    dispatch({
      type: Types.USER_STATISTICS_PAGE_SET_SORT_ORDER,
      payload: { orderBy, order, sortType }
    })
  }
}

export function setPaginationPage(page) {
  return dispatch => {
    dispatch({
      type: Types.USER_STATISTICS_PAGE_SET_PAGINATION_PAGE,
      payload: page
    })
  }
}

export function setPaginationRowsPerPage(rows) {
  return dispatch => {
    dispatch({
      type: Types.USER_STATISTICS_PAGE_SET_PAGINATION_ROWS_PER_PAGE,
      payload: rows
    })
  }
}

export function setCompareTrainings(trainings) {
  return dispatch => {
    dispatch({
      type: Types.USER_STATISTICS_SET_COMPARE_TRAININGS,
      payload: trainings
    })
  }
}

export function setTrainingFilter(type) {
  return dispatch => {
    dispatch({
      type: Types.USER_STATISTICS_SET_TRAINING_FILTER,
      payload: type
    })
  }
}

export function setFromFilter(value) {
  return dispatch => {
    dispatch({
      type: Types.USER_STATISTICS_SET_FROM_FILTER,
      payload: value
    })
  }
}

export function setToFilter(value) {
  return dispatch => {
    dispatch({
      type: Types.USER_STATISTICS_SET_TO_FILTER,
      payload: value
    })
  }
}
