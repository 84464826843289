const Styles = {
  container: {
    padding: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  loaderContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  },
  innerContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  filterContainer: {
    padding: 24,
    width: '100%'
  },
  filter: {
    marginLeft: 24,
    fontSize: 16,
    width: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 0
  }
}

export default Styles
